.solutioncardlist {
    margin-bottom: 50px;

    .solutioncard {
        margin: 0 0 35px 0;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.1);

        .solutioncardimage {
            max-height: 270px;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        a, a:visited {
            color: $black;

            .solutioncontent {
                &-name {
                    @include add-caret;
                    font-family: $primary-semibold;
                    font-size: 22px;
                    line-height: 1.4;
                }
            }

            &:hover, &:focus, &:visited {
                color: $black;
                text-decoration: none;

                .solutioncontent {
                    &-name {
                        @include text-link-1;
                        margin-bottom: 6px;
                    }
                }

                .solutionwhitepaper {
                    .desc {
                        text-decoration: underline;
                    }
                }
            }
        }

        .solutioncontent {
            padding: 20px 30px 0 30px;
            border-bottom: 35px solid $white; // to hide overflow
            background-color: $white;
            height: 240px;
            overflow: hidden;

            &-name {
                font-family: $primary-semibold;
                font-size: 22px;
                line-height: 1.4;
            }

            .desc {
                display: block;
                margin-bottom: 15px;
            }
        }

        .solutionwhitepaper {
            padding: 15px 30px 0 30px;
            border-bottom: 20px solid $white; // to hide overflow
            border-top: 1px solid $lighter-grey;
            background-color: $white;
            height: 120px;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                display: block;
                font-family: $primary-semibold;
                font-size: 12px;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                margin-bottom: 10px;
                color: $darkest-grey;
            }

            .desc {
                @include add-caret;
                display: block;
                font-size: 14px;
                line-height: 1.4;
                margin-bottom: 10px;
                color: $darkest-grey;
            }
        }
    }
}

@import "solutioncardlist-mobile";
