.universaltechnicalsupportpanel {
    background-color: $darkest-grey;
    padding-top: 32px;
    padding-bottom: 48px;

    &-innerrow {
        display: flex;

        & + & {
            margin-top: 48px;
        }
    }

    &-item {
        flex-direction: column;
        position: relative;

        & + & {
            margin-left: 128px;
        }

        @for $i from 3 to 4 { // Only support 3 for now
            &.numberColumns-#{$i} {
                flex-basis: calc((100% / #{$i}) - (128px * ((#{$i} - 1) / #{$i})));
            }
        }

        &,
        &-icon-container {
            align-items: center;
            display: flex;
        }

        &-icon-container {
            height: 60px;

            &:not(:only-child) {
                margin-bottom: 18px;
            }
        }

        &-icon {
            max-height: 60px;
        }

        &-title {
            color: $white;
            font-family: $primary-light;
            font-size: 20px;
            letter-spacing: 0.21px;
            line-height: 25px;
            margin-bottom: 0;
            text-align: center;
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &-button {
            align-items: center;
            background: none;
            border: 0;
            border-bottom: 2px solid rgba(255, 255, 255, .5);
            border-radius: 0;
            bottom: 0;
            color: $light-grey;
            cursor: pointer;
            display: flex;
            font-family: $primary-semibold;
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 16px;
            justify-content: center;
            margin-top: auto;
            padding-left: 32px;
            padding-right: 32px;
            padding-bottom: 13px;
            position: relative;
            text-transform: uppercase;
            width: 100%;
            -webkit-appearance: none;

            &::after {
                content: '';
                background-image: url('/Images/Base/dist/tyco_link_arrow_lightgrey.svg');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
                content: '';
                display: inline-block;
                height: 13px;
                position: absolute;
                top: 50%;
                transition: transform .3s ease-in-out;
                transform: rotate(90deg) translateX(-50%);
                right: 4px;
                width: 19px;
            }

            &:hover {
                &::after {
                    transform: rotate(90deg) translateX(-50%) translateX(5px);
                }
            }

            &:focus {
                outline: none;
            }
        }

        .universallinklist {
            background: $white;
            bottom: 0;
            box-shadow: 0 8px 24px 0 rgba(0,0,0,0.1);
            display: none;
            left: 0;
            padding: 24px;
            position: absolute;
            transform: translateY(100%);
            width: 100%;
            z-index: 1;

            .universallinklist-item {
                &:not(:first-child) {
                    margin-top: 16px;
                }
            }

            &-link {
                display: block;
                font-family: $primary-regular;

                &,
                &:hover,
                &:focus {
                    color: $darkest-grey;
                }
            }
        }

        &.dropdownIsOpen {
            .universaltechnicalsupportpanel-item-button {
                &::after {
                    transform: rotate(-90deg) translateX(50%);
                }
            }

            .universallinklist {
                display: block;
            }
        }
    }
}

@import "technicalsupportpanel-mobile";
