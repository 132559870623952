.universalexpandablecallout {
    margin-top: 104px;
    margin-bottom: 104px;

    &-container {
        background-color: $dark-aqua;
        padding: 32px 80px;
    }

    &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;

        + .universalexpandablecallout-list {
            margin-top: 24px;
        }
    }

    &-title,
    &-button {
        color: $white;
    }

    &-title {
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 0;
        width: 55%;
    }

    &-button {
        background: transparent;
        border: 0;
        cursor: pointer;
        height: 14px;
        font-size: 14px;
        font-family: $primary-bold;
        line-height: 14px;
        position: relative;

        &::before {
            background-color: $white;
            bottom: -10px;
            height: 1px;
            left: 6px;
            right: 0;
            position: absolute;
            width: calc(100% - 12px);
        }

        &::after {
            content: '';
            background-image: url('/Images/Base/dist/icon-minus-white.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            display: inline-block;
            height: 11px;
            margin-left: 5px;
            width: 12px;
        }

        &,
        &:focus {
            outline: 0;
        }

        &:hover {
            &::before {
                content: '';
            }
        }
    }

    &-list {
        .universallinklist {
            column-count: 4;
            column-gap: 32px;

            &-item {
                margin: 24px 0;
                break-inside: avoid;
                height: 30px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            &-link {
                @include text-link-1;
                @include add-caret($white);

                &::before {
                    border-bottom-color: $white;
                }

                &:visited,
                &:active,
                &:focus,
                &:hover {
                    color: $white;
                }
            }

            &-link,
            &-linktext {
                color: $white;
            }
        }
    }

    &.linksAreHidden {
        .universalexpandablecallout {
            &-button {
                &::after {
                    content: '';
                    background-image: url('/Images/Base/dist/icon-plus-white.svg');
                }
            }

            &-list {
                display: none;
            }
        }
    }
}

@import "expandablecallout-mobile";
