.universalwebinardetail {
    margin-top: 48px;
    margin-bottom: 40px;

    &-row {
        align-items: flex-start;
    }

    &-details {
        &-stats {
            &,
            &-prerecorded {
                align-items: center;
                display: flex;
            }

            &-prerecorded,
            &-duration {
                color: $darkest-grey;
                font-size: 14px;
                line-height: 28px;
            }

            &-prerecorded {
                &::before {
                    background-image: url(/Images/Base/dist/icon_clock.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                    content: '';
                    display: inline-block;
                    height: 19px;
                    margin-right: 4px;
                    width: 19px;
                }

                + .universalwebinardetail-details-stats-duration {
                    &::before {
                        content: '\007C';
                        margin-left: 8px;
                        margin-right: 4px;
                    }
                }
            }
        }

        &-desc {
            &:not(:only-child) {
                margin-top: 16px;
            }

            &,
            * {
                color: $dark-grey;
                font-size: 16px;
                line-height: 28px;
                font-family: $primary-regular;
                margin-bottom: 0;
            }

            h1 {
                color: $darkest-grey;
                font-size: 32px;
                line-height: 40px;
                margin-top: 40px;
                margin-bottom: 16px;
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li {
                    &::before {
                        background-color: $black;
                        content: '';
                        display: inline-block;
                        height: 6px;
                        margin-right: 16px;
                        position: relative;
                        top: -1px;
                        width: 6px;
                        vertical-align: middle;
                    }
                }
            }

            li {
                line-height: 24px;

                + li {
                    margin-top: 16px;
                }
            }

            a {
                @include link;
            }

            > *:first-child {
                margin-top: 0;
            }

            *:not(h1) + *:not(h1) {
                margin-top: 24px;
            }
        }
    }

    &-placeholder:empty {
        display: none;
    }
}

@import "webinardetail-mobile";
