﻿.breadcrumb-control {

    ol.breadcrumb {
        @include list-reset;
        margin: 20px 0;
        display: flex;
        height: auto; // Override legacy JCI style
        flex-wrap: wrap;
        align-items: center;
        padding: 0;
        background-color: transparent;

        li {
            font-family: $primary-semibold;
            font-size: 12px;
            line-height: 1.6;
            margin-right: 8px;
            color: $blue;

            &.active {
                color: $grey;

                a, a:visited {
                    color: $grey;

                    &:hover, &:focus {
                        color: $grey;
                        text-decoration: none;
                    }
                }

                &:before {
                    content: "|";
                    margin-right: 8px;
                }
            }

            a, a:visited {
                color: $blue;

                &:hover, &:focus {
                    color: $blue;
                    text-decoration: none;
                }
            }
        }
    }
}

@import "breadcrumb-mobile";
