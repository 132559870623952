.universalcompositecontentcard {
    &-title,
    &-desc {
        margin-bottom: 16px;
    }

    &-title,
    &-linklist-title {
        color: $darkest-grey;
    }

    &-desc,
    &-linklist-title {
        &,
        * {
            font-size: 16px;
        }
    }

    &-title {
        font-family: $primary-regular;
        font-size: 32px;
        line-height: 40px;
    }

    &-desc {
        &,
        * {
            color: $dark-grey;
            line-height: 30px;
        }
    }

    &-linklist-title {
        font-family: $primary-bold;
        line-height: 28px;
    }

    &-linklist {
        &:not(:only-child) {
            margin-top: 24px;
        }

        .universallinklist {
            column-count: 2;
            column-gap: 24px;

            &-item {
                &:not(:first-child) {
                    margin-top: 20px;
                }
            }

            &-link {
                @include link;
            }

            &-link,
            &-linktext {
                color: $darkest-grey;
                font-family: $primary-semibold;
            }
        }
    }
}

@import "compositecontentcard-mobile";
