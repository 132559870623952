.universalrichtext5x7 {
    margin-top: 48px;
    margin-bottom: 48px;
    padding-top: 32px;
    position: relative;

    &::before {
        border-top: 2px solid $lighter-grey;
        content: '';
        left: 24px;
        position: absolute;
        top: 0;
        width: calc(100% - 48px);
    }

    &-row {
        &:only-child {
            .universalrichtext5x7-title {
                margin-bottom: 0;
            }
        }
    }

    &-textcol {
        color: $darkest-grey;

        &:not(:only-child) {
            &.col-sm-5 {
                padding-right: 44px;
            }

            &.col-sm-7 {
                padding-left: 44px;
            }
        }

        &:only-child {
            min-width: 100%;
        }
    }

    &-title {
        margin-bottom: 24px;

        &,
        * {
            font-family: $primary-semibold;
            font-size: 18px;
            line-height: 31px;
        }
    }

    &-text {
        p,
        li {
            line-height: 24px;
        }

        p {
            font-size: 14px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            list-style: square;
            margin: 0;
            padding: 0 0 0 18px;
        }

        li {
            font-size: 16px;
            font-family: $primary-semibold;

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    }
}

@import "richtextpanel-mobile";
