/* -----------------------------------------------------------------------------
JCI OVERRIDES
----------------------------------------------------------------------------- */

// Carousels -------------------/
.image-carousel {
    .indicator-dots {
        .carousel-indicators {
            display: flex;

            .thumbnailItem {
                img {
                    max-width: 80px;
                    height: 60px;
                }
            }
        }
    }

    &.container {
        .carousel-control {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            &.carousel-item-right {
                right: 0;
                left: auto;
            }
        }
    }
}

.left-rotator, .right-rotator {
    .carousel-inner {
        .news-title {
            h3 {
                a, a:visited {
                    color: white;

                    &:hover, &:focus {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
    }
}

.content-rotator-right {
    .left-image {
        a, a:visited {
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
    .right-rotator {
        padding-right: 15px;
    }
}

.content-rotator-left {
    .right-image {
        a, a:visited {
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

    .left-rotator {
        padding-left: 15px;
    }
}

.carousel-left, .carousel-right {
    .control-wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        a, a:visited {
            .icon.icon-arrow-down, .icon.icon-arrow-up {
                border-left: 2px solid #51a831;
                border-bottom: 2px solid transparent;
                color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 75px;
                height: 75px;
            }

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}

.carousel-right {
    .control-wrapper {
        a, a:visited {
            .icon.icon-arrow-down, .icon.icon-arrow-up {
                border-left: 2px solid #1b9f95;
            }
        }
    }
}

// Homepage News Rotator -------------------/
.news-section {
    .news.small-rotator-section,
    .insights.med-rotator-section {
        a, a:visited {
            color: #337ab7;

            &:hover, &:focus {
                text-decoration: none;
            }

            &.view-more-insights {
                color: #555555;
            }
        }
    }
}

.news-section {
    .carousel-control.carousel-item-left,
    .carousel-control.carousel-item-right {
        display: inline-flex;
        justify-content: center;
    }
}

// Inside Page Subnav Menus -------------------/
.subnav-menu .subnav-list li .text {
    a, a:visited {
        color: rgb(51, 51, 51);

        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

// Infographic Image Banners -------------------/
.infographic-right .right-infographic-image {
    order: 2;
}

// Leadership Grid -------------------/
.revised-image-callout img {
    width: 100%;
}

// Products Pages -------------------/
.hero-links {
    .hero-link-section {
        a, a:visited {
            color: #93c4f7;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}

.product-family-callout-section {
    .product-family-image {
        .overlay {
            display: flex;
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }
}

.callout-container .image-callout {
    &:hover, &:focus {
        color: #ffffff;
        text-decoration: none;
    }

    a, a:visited {
        display: block;
        color: #666366;

        &:hover, &:focus {
            color: #ffffff;
            text-decoration: none;
        }

        .image-callout-image {
            img {
                height: auto;
                width: 100%;
            }
        }
    }
}

.case-study-info {
    .title {
        a, a:visited {
            color: #ffffff;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}

.case-study-callout.container {
    padding: 0;
}

.btn-wrapper {
    .Orange.Medium {
        a, a:visited {
            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }
}

.content-text {
    a, a:visited {
        &:hover, &:focus {
            text-decoration: none;
        }
    }
}

.related-callout-section {
    a, a:visited {
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .related-callout-section .text, .related-item-title {
        color: #666;
    }
}

// Breadcrumbs -------------------/
.breadcrumb-control .breadcrumb {
    display: flex;
    list-style: none;
    margin: 10px 0;
    padding: 0;
    background-color: transparent;

    li {
        color: #2D72B9;
        font-weight: bold;

        &.active {
            color: #757575;
            font-weight: normal;

            &:before {
                content: "|";
            }
        }

        a, a:visited {
            &:hover, &:focus {
                color: inherit;
                text-decoration: none;
            }
        }
    }
}

.breadcrumb-control .breadcrumb > li + li:before {
    content: "|";
}

.breadcrumb-control ol.breadcrumb li {
    margin-right: 4px !important;
}

.breadcrumb-control ol.breadcrumb li.active:before {
    margin-right: 0px !important;
}

// Article Share Pop-Out -------------------/
.article-share .article-share-popout ul {
    color: #ffffff;

    li {
        a, a:visited {
            color: #ffffff;

            &:hover, &:focus {
                text-decoration: none;
                color: #ffffff;
            }
        }
    }
}

// History Carousel -------------------/
.jspPane {
    top: -10px !important;
}

// Collapse  -------------------/
.collapse {
    display: none;
}

// Follow Us Links (News) -----------/
.follow-control-container {
    a, a:visited {
        color: #555555;
    }

    .follow-wrapper {

        &:hover, &:focus {
            a, a:visited {
                color: #ffffff;
                text-decoration: none;
            }
        }
    }
}

.follow-control-container .follow-control .follow-wrapper ~ .follow-wrapper {
    border-left: none;
}

// Product Detail Callouts --------------/
.product-detail-callout {
    a, a:visited {
        color: #555555;
    }

    &:hover, &:focus {
        a, a:visited {
            color: #ffffff;
            text-decoration: none;
        }

        span, p, ul {
            color: #ffffff;
        }
    }
}

.filters-control .filter-drop-section .filter-section.last {
    padding: 20px 20px 0 20px;
}

.filter-item-section {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

    .filter-item {
        margin-right: 20px;
    }
}

.filter-by-item {
    a, a:visited {
        span {
            color: #555555;
        }
    }

    &:hover, &:focus {
        a, a:visited {
            text-decoration: none;

            span {
                color: #ffffff;
            }
        }
    }
}

// Hidden Utility (Replace) -----------------/
.hidden {
    display: none;
}

// News Results ------------------/
.media-news-search-results {
    .news-item {
        .text {
            a, a:visited {
                color: #555555;

                &:hover, &:focus {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}

// Insights Column
.insights-column.large {
    .insights-sharable {
        .text {
            a, a:visited {
                color: #555555;

                &:hover, &:focus {
                    color: inherit;
                    text-decoration: none;
                }
            }
        }
    }
}

// Location Finder ----------------/
.location-finder #address-search {
    position: initial;
}

.location-finder .btn-share {
    border: none;
}

.location-finder .location-results-list .incorrect-info-message {
    color: #555555;
}

.main-content.location-finder {
    .location-search-header {
        .insights-list {
            display: flex;
            align-items: center;
            background-color: #F0F0F0;

            .label {
                text-transform: initial;
                color: #2E2925;
                margin: 0;
            }
        }
    }
}

.location-finder .location-input {
    padding: 0;
    border: none;

    input {
        background: white;
        border: 1px solid #A9ABAC;
        border-radius: 4px;
        color: #2E2925;
        padding: 0 20px;
        margin-right: 10px;
    }
}

.location-finder span.caret, .location-finder .fa-search, .location-finder .insights-list .dropdown-toggle::after {
    color: #2E2925;
    cursor: pointer;
}

.location-finder .distance-input {
    background: white;
    border: 1px solid #A9ABAC;
    border-radius: 4px;
    color: #2E2925;
}

.location-finder .insights-filters .insights-filter-wrapper:nth-child(3) {
    top: initial;
}

.location-finder {
    .btn-share {
        background-color: transparent;
        text-transform: initial;
        font-weight: bold;
        font-size: 14px;
        color: #2D72B9;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            color: #2D72B9;
        }
    }
}

.location-finder .button.view-more {
    background-color: #2D72B9;
}

.fa-map-marker:before {
    color: #2D72B9;
}

// Mobile View
.main-content.location-finder {
    .row.location-search-results {
        display: flex;
    }
}

// Insights ---------------------/
.insights-card.lg-insights-block, .insights-card.md-insights-block {
    .insights-sharable {
        .insights-top-section {
            .text {

                a, a:visited {
                    color: #555555;
                }
            }
        }
    }
}

// Accent Colors -----------------/
.universal-accent-color {
    display:none;
}

.content-text-padding {
    @media (min-width: 992px) {
        padding-left: 10%;
        padding-right: 10%;
    }
}
