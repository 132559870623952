﻿.categorycascadeselectlist {
    margin: 0px;
}

.defaultform {
    /*margin: 50px 0;*/

    label {
        margin-top: 10px;
    }

    select, input {
        margin-bottom: 20px;
    }

    form > div {
        margin: 30px 0;
    }

    input[type="checkbox"], input[type="radio"] {
        width: auto;
    }

    input[type="submit"] {
        @include primary-button;
        width: auto;
        margin: 30px 0;
        border: none;
    }

    .categorycascadeselectlist {
        margin: 0px;
    }

    p {
        margin-top: 15px;
    }

    /*.field-validation-error:last-of-type {
        margin-top: -10px;
    }*/

    .field-validation-error {
        margin-top: -15px;
        margin-bottom: 20px;
    }
}


