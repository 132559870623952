@include max-screen(lg) {
    .universalinsightquilt {
        &-card {
            &-accent {
                &-title {
                    &,
                    * {
                        font-size: 30px;
                        line-height: 38px;
                    }
                }
            }
        }
    }
}

@include max-screen(md) {
    .universalinsightquilt {
        margin-top: 64px;
        margin-bottom: 64px;

        &-card {
            &-text {
                padding: 16px 24px 24px;
            }

            &-titlelink,
            &-titlelinktext {
                font-size: 20px;
                line-height: 30px;
            }

            &-author {
                display: block;

                &-img {
                    margin-bottom: 8px;
                    margin-right: 0;
                }
            }

            &-accent {
                &-inner {
                    padding: 42px 32px 32px;
                }

                &-title {
                    &,
                    * {
                        font-size: 28px;
                    }
                }
            }
        }

        &-slim {
            .universalinsightquilt-card-accent {
                &-inner {
                    padding: 32px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalinsightquilt {
        flex-direction: column-reverse;

        &-card {
            flex-basis: auto;

            &-tags {
                margin-bottom: 8px;

                &-item {
                    font-size: 10px;
                    letter-spacing: 0.38px;
                }
            }

            &-titlelink,
            &-titlelinktext {
                font-size: 18px;
                line-height: 28px;
            }

            &-accent {
                &-inner {
                    display: block;
                    padding: 24px;
                }

                &-title {
                    &,
                    * {
                        font-size: 24px;
                        line-height: 32px;
                    }
                }

                &-link {
                    margin-top: 32px;
                }
            }
        }

        &-slim {
            .universalinsightquilt-card-accent {
                margin-top: 16px;

                &-inner {
                    padding: 24px;
                }
            }

            .universalinsightquilt-card:not(.universalinsightquilt-card-accent) {
                display: block;
                padding: 0;

                .universalinsightquilt-card-img-container {
                    width: 100%;
                }

                .universalinsightquilt-card-text {
                    padding: 16px 24px 24px;
                }
            }
        }
    }
}
