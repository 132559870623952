@include max-screen(xl) {
    header {
        &.universalheader {
            .desktop {
                .nav-bar {
                    .nav {
                        li {
                            margin: 0 10px;
                            letter-spacing: 1px;
                        }
                    }

                    .search {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@include max-screen(lg) {
    header {
        &.universalheader {
            .desktop {
                .nav-bar {
                    .nav {
                        li {
                            margin: 0 10px;
                            letter-spacing: 0.5px;
                        }
                    }

                    .search {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

@include max-screen(md) {
    header {
        &.universalheader {
            .desktop {
                display: none;
            }

            .mobile {
                display: block;
                width: 100%;
                background-color: $white;
                box-shadow: 0 5px 14px 0 rgba($darkest-grey, 0.08);

                .nav-bar {
                    width: 100%;
                    height: 65px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .logo {
                        position: relative;
                        bottom: 3px; // Visually Center
                        img {
                            width: 90px;
                            height: 100%;
                        }
                    }

                    .how-to-buy {
                        @include primary-button;
                        margin: 10px 0;
                    }

                    .search {
                        margin-left: auto;
                    }

                    .how-to-buy {
                        display: none;
                    }

                    .hamburger {
                        margin-left: 25px;

                        img {
                            width: 30px;
                        }
                    }
                }
            }
        }
    }
    // Navigation Overlay
    .overlay {
        display: block;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        z-index: 250;
        left: 1024px;
        background: $white;
        overflow-y: scroll;
        transition: 500ms;

        .overlay-content {
            position: relative;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: 0 25px;
            display: block;

            nav {
                display: block;

                .overlay-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 60px;
                    border-bottom: 1px solid $lighter-grey;

                    .closeNav {
                        background-color: transparent;
                        border: none;
                        margin-left: auto;

                        .close {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;

                            img {
                                width: 14px;
                            }
                        }
                    }
                }

                ul {
                    @include list-reset;

                    &.nav {
                        font-family: $primary-bold;
                        font-size: 16px;
                        line-height: 1.3;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                        padding: 15px 0;
                        border-bottom: 1px solid $lighter-grey;
                    }

                    &.secondary {
                        font-size: 16px;
                        text-transform: none;
                        letter-spacing: 0;
                        font-family: $primary-regular;
                        padding: 20px 0;
                        border-bottom: 1px solid $lighter-grey;

                        li {
                            padding: 10px 0;
                        }
                    }

                    li {
                        width: 100%;
                        text-align: left;
                        padding: 15px 0;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        transition: all 200ms ease-in-out;

                        a, button {
                            color: $black;
                            display: inline-block;
                            border: none;
                            background-color: transparent;
                            padding: 0;

                            &:hover {
                                text-decoration: none;
                                color: $dark-grey;
                                border-bottom: none;
                                padding-bottom: 0;
                                outline: none;
                            }

                            &:focus {
                                text-decoration: none;
                                color: $dark-grey;
                                border-bottom: none;
                                padding-bottom: 0;
                            }
                        }

                        &.has-dropdown {
                            &:after {
                                @include add-caret-helper;
                                margin-left: 5px;
                            }

                            .dropdown {
                                display: block;
                                height: 100%;
                                width: 100%;
                                position: fixed;
                                z-index: 6;
                                top: 0;
                                left: -1600px;
                                padding: 0 25px;
                                background: $white;
                                overflow-y: scroll;
                                transition: 300ms;

                                .mobile-header {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    height: 60px;
                                    border-bottom: 1px solid $lighter-grey;

                                    .closeSubNav {
                                        display: block;
                                        font-size: 16px;
                                        text-transform: uppercase;
                                        cursor: pointer;
                                        padding: 0;

                                        &:before {
                                            @include add-caret-helper;
                                            transform: rotate(180deg);
                                            margin-right: 10px;
                                        }
                                    }

                                    .closeNav {
                                        background-color: transparent;
                                        border: none;
                                        margin-left: auto;

                                        .close {
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;

                                            img {
                                                width: 14px;
                                            }
                                        }
                                    }
                                }

                                .dropdown-layout {
                                    width: 100%;
                                    right: 0;
                                    background-color: $white;

                                    .container {
                                        padding: 0;

                                        .promo-callout {
                                            display: none;
                                        }
                                    }

                                    .row {
                                        border-top: 1px solid $lighter-grey;
                                        padding-top: 30px;

                                        &:first-child {
                                            border-top: none;
                                        }

                                        &:last-child {
                                            margin-bottom: 50px;
                                        }
                                    }

                                    .dropdown-title {
                                        display: block;
                                        margin-bottom: 20px;
                                        color: $light-grey;
                                    }

                                    ul {
                                        font-family: $primary-regular;
                                        font-size: 16px;
                                        line-height: 1.3;
                                        letter-spacing: 0;
                                        text-transform: none;
                                        font-family: $primary-semibold;
                                        margin-bottom: 40px;

                                        li {
                                            color: $black;

                                            button,
                                            a,
                                            a:visited {
                                                color: $black;
                                            }

                                            a,
                                            a:visited {
                                                padding-right: 20px;
                                            }

                                            &:after {
                                                @include add-caret-helper;
                                                margin-left: 5px;
                                            }
                                        }
                                    }
                                    // Contact Us
                                    .contact {
                                        margin-bottom: 50px;

                                        .text, .tel {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-regular;
                                            letter-spacing: 0;
                                            font-size: 14px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .tel {
                                            font-family: $primary-bold;
                                            font-size: 16px;
                                        }

                                        .cta {
                                            @include primary-button;
                                            margin: 10px 0;
                                        }
                                    }
                                    // Social
                                    .social {
                                        margin-bottom: 60px;

                                        .title {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-semibold;
                                            letter-spacing: 0;
                                            font-size: 18px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .text {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-regular;
                                            letter-spacing: 0;
                                            font-size: 14px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        ul {
                                            @include list-reset;
                                            display: flex;

                                            li {
                                                margin-right: 40px;

                                                a, a:visited {
                                                    &:hover, &:focus {
                                                        opacity: 0.75;
                                                    }
                                                }

                                                &:after {
                                                    content: none;
                                                }
                                            }
                                        }
                                    }
                                    // Find a Location,
                                    .find-a-location {
                                        margin-bottom: 50px;

                                        label {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-semibold;
                                            letter-spacing: 0;
                                            font-size: 18px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .field-wrap {
                                            display: flex;
                                            position: relative;

                                            input {
                                                padding-right: 50px;
                                            }

                                            .search {
                                                border: none;
                                                outline: none;
                                                background-color: transparent;
                                                position: absolute;
                                                right: 10px;
                                                top: 10px;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    // Industries CTA (inherited li classes overrides)
                                    .heading {
                                        display: block;
                                        color: $black;
                                        margin-bottom: 5px;
                                        font-family: $primary-bold;
                                        letter-spacing: 0;
                                        font-size: 18px;
                                        line-height: 1.5;
                                        text-transform: none;
                                    }

                                    .text-link-2 {
                                        color: $blue;
                                        font-size: 16px;
                                        letter-spacing: 0;
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
