﻿@include max-screen(md) {
    .videomodal {
        // Simplified version:
        &-button {
            font-size: 20px;

            &::after {
                height: 46px;
                width: 46px;
            }
        }
        // Two-col version (default):
        &-text {
            margin-left: auto;
            width: 48%;
        }

        &-modal.showModal .universalmedia {
            padding-bottom: 47.8125%; // Normally 56.25, this number is 85% of that b/c of set width below
            width: 85%;
        }
    }
}

@include max-screen(sm) {
    .videomodal {
        // Simplified version:
        &-button {
            margin: 36px auto;
        }
        // Two-col version (default):
        &-text {
            margin-top: 32px;
            width: 100%;

            &-title {
                font-size: 32px;
                line-height: 42px;
            }

            &-description {
                font-size: 16px;
            }
        }

        &-modal.showModal .universalmedia {
            padding-bottom: calc(.5625 * (100% - 48px)); // Normally 56.25, this number is a calculation based on the width set below
            width: calc(100% - 48px);
        }
    }
}
