.universalpagelist,
.legacypagelist {
    &-title,
    &-subtext,
    &-list-item-title-link,
    &-list-item-title-link:visited,
    &-list-item-text,
    &-cta,
    &-cta:visited {
        color: $darkest-grey;
    }

    &-title {
        margin-bottom: 30px;
    }

    &-list {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        &.row {
            margin-bottom: 50px;
        }

        &-item {
            &-img {
                height: auto;
                left: 50%;
                max-width: 700px;
                min-height: 100%;
                min-width: 100%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: auto;

                &-container {
                    height: 0;
                    position: relative;
                    overflow: hidden;
                }
            }

            &-text {
                margin-bottom: 0;
            }
        }
    }
}

.universalpagelistvar2 {
    &-title,
    &-subtext,
    &-list-item-title-link,
    &-list-item-title-link:visited,
    &-list-item-text,
    &-cta,
    &-cta:visited {
        color: $darkest-grey;
    }

    &-title {
        margin-bottom: 40px;
    }

    &-list {
        &-item {
            & + & {
                margin-left: 50px;
            }

            &.col-sm-3,
            &.col-sm-4 {
                margin-left: 0;
            }

            @for $i from 3 to 5 {
                &.numberColumns-#{$i} {
                    flex-basis: calc((100% / #{$i}) - (50px * ((#{$i} - 1) / #{$i})));
                }
            }

            &-img {
                width: 100%;

                &-container {
                    height: 0;
                    position: relative;
                    overflow: hidden;
                }
            }

            &-text {
                margin-bottom: 0;
            }
        }
    }
}

.universalpagelist,
.legacypagelist {
    background: linear-gradient(0deg, rgba(251,251,251,0) 0%, #F5F5F5 100%);
    margin-top: 50px;
    margin-bottom: 104px;

    &-container {
        padding-top: 20px;
    }

    &-title,
    &-subtext {
        max-width: 58%;
    }

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 1.3;
    }

    &-subtext {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 40px;
    }

    &-list {
        &-item {
            & + & {
                margin-left: 24px;
            }
            &.col-sm-3,
            &.col-sm-4 {
                margin-left: 0;
            }

            @for $i from 3 to 5 {
                &.numberColumns-#{$i} {
                    flex-basis: calc((100% / #{$i}) - (24px * ((#{$i} - 1) / #{$i})));
                }
            }

            &-img {
                &-container {
                    margin-bottom: 18px;
                    padding-bottom: 56.29%;
                }
            }

            &-title {
                margin-bottom: 8px;

                &-link {
                    @include text-link-1;
                }

                &,
                &-link {
                    font-family: $primary-semibold;
                    font-size: 18px;
                    line-height: 24px;
                }
            }

            &-text,
            &-cta {
                font-size: 14px;
            }

            &-text {
                line-height: 21px;
            }

            &-cta {
                @include link;
                line-height: 24px;
                margin-top: 16px;
            }
        }
    }
}

.universalpagelistvar2 {
    margin-top: 80px;
    margin-bottom: 80px;

    &-title {
        font-size: 28px;
        font-weight: 300;
        line-height: 36px;
        text-align: center;
    }

    &-list {
        &-item {
            &-img {
                &-container {
                    margin-bottom: 24px;
                    padding-bottom: 61.12%;
                }
            }

            &-title,
            &-title-link,
            &-text {
                font-family: $primary-light;
            }

            &-title {
                margin-bottom: 16px;

                &,
                &-link {
                    font-size: 20px;
                    line-height: 27px;
                }
            }

            &-text {
                font-size: 16px;
                line-height: 22px;
            }

            &-cta {
                @include text-link-2;
                margin-top: 32px;
            }
        }
    }
}

@import "_pagelist-mobile";