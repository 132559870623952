@include max-screen(lg) {
    .universaljumboheader {
        &-title {
            max-width: 60%;
        }

        &-subtitle {
            max-width: 50%;
        }
    }
}

@include max-screen(md) {
    .universaljumboheader {
        padding-top: 120px;

        &-title {
            font-size: 42px;
            line-height: 50px;
        }

        &-subtitle {
            font-size: 18px;
            line-height: 26px;
        }
    }
}

@include max-screen(sm) {
    .universaljumboheader {
        padding-top: 80px;

        &-video {
            display: none;
        }

        &-title {
            max-width: 100%;

            &:not(:only-child) {
                margin-bottom: 16px;
            }
        }

        &-subtitle {
            max-width: 90%;
        }

        &-cta {
            &:not(:only-child) {
                margin-top: 24px;
            }
        }
    }
}
