.universalconvoui-assistant-reset {
    margin-top: 124px;
    margin: 48px 0 0;

    &-btn {
        align-items: center;
        background: $white;
        border: 1px solid $lighter-grey;
        color: $dark-grey;
        display: flex;
        font-family: $primary-regular;
        font-size: 14px;
        line-height: 19px;
        margin-left: auto;
        margin-right: auto;
        padding: 9px 16px 11px;
        text-align: left;
        transition: all .3s ease-in-out;

        &::after {
            background-image: url(../../../../../Images/Base/dist/icon_convo_ui_refresh.svg);
            background-repeat: no-repeat;
            background-position: center;
            content: '';
            flex-shrink: 0;
            height: 24px;
            margin-left: 8px;
            margin-top: 2px;
            width: 24px;
        }

        &:hover {
            background: $lightest-grey;
        }
    }
}
