.homeinpagebanner {
    position: relative;

    &:after {
        background: linear-gradient(to left, $blue 0%, $aqua 100%) left bottom transparent no-repeat;
        bottom: 0;
        content: '';
        height: 8px;
        left: 0;
        position: absolute;
        width: 100%;
    }

    .image-wrap {
        img {
            width: 100%;
        }
    }

    .callout-wrap {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .callout {
            background-color: $white;
            padding: 60px 50px;
            width: 40%;

            p, span {
                display: block;
                font-size: 14px;
                padding: 25px 0;
            }

            &-title {
                font-size: 22px;
                font-family: $primary-semibold;
                line-height: 32px;
            }
        
            &-button {
                @include primary-button;
                line-height: 30px;
        
                &:visited,
                &:hover {
                    color: $white;
                }
            }
        }
    }
}

@import "homeinpagebanner-mobile";