.universalconvoui-assistant {
    &-watermark {
        color: $light-blue-cool;
        font-family: $primary-semibold;
        font-size: 72px;
        line-height: 54px;
        margin-bottom: 0;
        position: fixed;
        top: 240px;
        z-index: -1;
    }

    &-text {
        &,
        &-greeting {
            color: $darkest-grey;
            font-family: $primary-regular;
            font-size: 18px;
            line-height: 26px;
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin-top: 16px;
        }
    }

    &-actions {
        display: flex;
        margin-top: 32px;

        &-btn {
            @extend %outlineButton;
            flex-basis: 50%;
            padding: 9px;
        }
    }

    &-defaultview {
        .universalconvoui-assistant-text-container {
            margin-top: 224px;
        }
    }

    &-returnview {
        .universalconvoui-assistant-text-container {
            margin-top: 99px;
        }
    }
}

.universalconvoui-sidebar:not(.showSidebar) {
    .universalconvoui-assistant {
        &-watermark {
            display: none;
        }
    }
}

@media screen and (max-width: 500px) {
    .universalconvoui-assistant {
        &-watermark {
            font-size: 52px;
            top: 180px;
        }

        &-actions {
            display: block;

            &-btn {
                padding: 12px;
                width: 100%;

                & + & {
                    margin-top: 16px;
                }
            }
        }

        &-defaultview,
        &-returnview {
            .universalconvoui-assistant-text-container {
                margin-top: 120px;
            }
        }
    }
}
