.universalbrandgriddescription {
    margin-top: 104px;
    margin-bottom: 104px;

    .bb {
        border-bottom: 1px solid $lighter-grey;
    }

    .rb {
        border-right: 1px solid $lighter-grey;
    }

    &-grey {
        background-color: $light-blue-cool;
        padding-top: 64px;
        padding-bottom: 64px;
    }

    &-title {
        color: $darkest-grey;
        font-size: 40px;
        font-family: $primary-semibold;
        line-height: 56px;
        margin-bottom: 64px;
        padding-left: 52px;
        width: 80%;
    }

    &-brands {
        margin-right: 0;
        margin-left: 0;
    }

    &-brand {
        align-items: center;
        box-sizing: content-box;
        display: flex;
        flex-direction: column;
        padding: 52px;

        @for $i from 2 to 4 {
            &.itemsPerRow-#{$i} {
                @include content-box-flex-basis($i, 105.8px); // numOfItems in row, total left/right padding for item
            }
        }

        &-link {
            will-change: contents;

            .universalbrandgriddescription-brand-img {
                transition: transform .3s ease-in-out;
            }

            &:hover {
                .universalbrandgriddescription-brand-img {
                    transform: scale(1.15);
                }
            }
        }

        &-img {
            flex-shrink: 0;
            max-height: 70px;
            max-width: 100%;
        }

        &-blurb,
        &-description {
            width: 100%; // IE11 fix
            &:not(:first-child) {
                margin-top: 24px;
            }

            &,
            p {
                color: $darkest-grey;
                font-size: 16px;
                line-height: 30px;
            }
        }

        &-description {
            > *:last-child {
                margin-bottom: 0;
            }

            ul,
            ol {
                margin-bottom: 0;
            }

            ul {
                padding-left: 18px;
            }

            li {
                list-style: square;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

@import "_brandgridwithdescription-mobile";
