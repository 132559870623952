@include max-screen(sm) {
    .externallinkpanel {
        padding: 50px 0;

        .externalLinkPanel-header {
            &-title {
                font-size: 28px;
                line-height: 1.3;
            }
        }

        .externalLinkPanelItem {
            &-item {
                padding-right: 15px;
            }
        }
    }
}
