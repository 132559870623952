.universalimagegallery {
    margin-top: 56px;
    margin-bottom: 56px;

    &-item {
        margin-bottom: 0;

        &.active {
            transition: 0s;
        }
    }

    &-carousel-active-caption {
        margin: 0;
    }

    .carousel-nav {
        align-items: center;
        display: flex;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .carousel-indicators {
        bottom: 0;
        margin: 0 0 0 auto;
        position: relative;

        .carousel-indicator {
            background-color: darken($lightest-grey, 20%);
            height: 2px;
            width: 30px;

            &:not(:first-child) {
                margin-left: 10px;
            }

            &.active {
                background-color: $darkest-grey;
            }
        }
    }

    .carousel-control-prev,
    .carousel-control-next {
        height: 30px;
        opacity: 1;
        position: relative;
        width: 30px;

        &::after {
            background-image: url('/Images/Base/dist/icon-caret.svg');
            background-repeat: no-repeat;
            background-size: 12px 18px;
            background-position: center center;
            bottom: 0;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            position: absolute;
        }
    }

    .carousel-control-prev {
        margin-right: 8px;
        transform: rotate(180deg);
    }
}

.universalvideo {
    height: 0;
    padding-bottom: 56.25%; // 16x9 aspect ratio
    position: relative;
    overflow: hidden;

    iframe,
    video {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
    }
}

.universalmedia-caption,
.universalimagegallery-carousel-active-caption {
    font-size: 14px;
    line-height: 24px;
    font-style: italic;

    &:empty {
        display: none;
    }
}

.universalmedia {
    width: 100%;
    height: 100%;

    &-caption {
        margin-top: 8px;
    }
}

@import "imagegallery-mobile";
