.utilitybrandbar {
    border-bottom: 1px solid $lighter-grey;
    padding: 10px 0;
    position: relative;
    z-index: 5;
    width: 100%;
    background-color: $white;


    .utilitybrandbar-wrap {
        display: flex;
        justify-content: space-between;
        position: relative;

        .utility-wrap {
            margin-left: auto;
            // Sensormatic Utility Brand Bar Components
            .utilitybrandbar__links,
            .utilitybrandbar__countryselectbtn,
            .utilitybrandbar__countryselectpanel {
                display: none;
            }
        }

        .login-wrap {
            margin-left: 20px;

            button, a, a:visited {
                border: none;
                outline: none;
                text-transform: uppercase;
                font-family: $primary-semibold;
                font-size: 13px;
                background-color: $white;
                cursor: pointer;
                color: $darkest-grey;

                &:hover, &:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .brand {
        text-align: left;
    }

    .utility {
        text-align: right;
    }

    .accordionItem {
        display: inline-block;
        z-index: 3;
        position: relative;

        .accordionItemHeading {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: $primary-semibold;
            font-size: 13px;
            background-color: $white;
            border: none;
            outline: none;
            transition: 200ms;
            opacity: 1;

            &.has-dropdown {
                position: relative;

                &:focus, &:hover {
                    outline: none;
                    cursor: pointer;
                }

                &:after {
                    @include add-caret-helper;
                    transform: rotate(90deg);
                    margin-left: 10px;
                }
            }

            &.utility {
                position: relative;
                z-index: 3;

                &:before {
                    content: url('/Images/Base/dist/icon-globe.svg');
                    display: inline-block;
                    margin-right: 5px;
                    position: relative;
                    top: 3px;
                }
            }
        }

        &:not(.close) {
            .accordionItemHeading {
                &.has-dropdown {
                    &:after {
                        transform: rotate(270deg);
                    }
                }
            }

            .accordionItemContent {
                transform: scaleY(1);
                transform-origin: top;
                opacity: 1;
                transition: opacity 500ms ease-in-out;
                position: fixed;
                left: 0;
                top: 45px; // UtilityBrandBar Height
                right: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: block;
                overflow: scroll;
                background-color: $white;

                .brands-content {
                    padding: 60px 0;

                    .title {
                        font-size: 18px;
                        line-height: 18px;
                        font-family: $primary-bold;
                        text-transform: uppercase;
                        letter-spacing: 1.69px;
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        border-bottom: 1px solid $light-grey;
                        position: relative;

                        &::after {
                            background: linear-gradient(90deg, $dark-green 0%, $light-blue 100%);
                            bottom: 0;
                            content: '';
                            display: block;
                            height: 4px;
                            position: absolute;
                            transform: translateY(50%);
                            width: 65px;
                        }
                    }

                    ul {
                        @include list-reset;
                        column-count: 2;
                        margin: 10px 0 60px 0;

                        li {
                            img {
                                padding: 15px 0;
                                width: 120px;
                            }
                        }
                    }
                }

                .utility-content {
                    padding: 60px 0;
                    text-align: left;
                    // Sub-Accordion Item
                    .subAccordionItem {
                        .subAccordionItemHeading {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            font-family: $primary-bold;
                            font-size: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            background-color: $white;
                            padding: 30px 0;
                            width: 100%;
                            border: none;
                            outline: none;
                            border-bottom: 2px solid $light-grey;
                            transition: 200ms;
                            opacity: 1;

                            &.has-dropdown {
                                position: relative;

                                &:focus, &:hover {
                                    outline: none;
                                    cursor: pointer;
                                }

                                &:after {
                                    content: url('../Images/Base/dist/icon-acc-caret-down.svg');
                                    display: inline-block;
                                    margin-left: 10px;
                                }
                            }
                        }
                    }

                    .close {
                        padding: 0;
                        margin: 0;
                        position: relative;

                        .subAccordionItem {
                            opacity: 1;

                            button.subAccordionItemHeading {
                                background-color: transparent;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .subAccordionItemContent {
                            height: 0px;
                            transform: scaleY(0);
                            opacity: 0;
                            display: none;
                        }
                    }

                    .open.subAccordionItem {
                        .subAccordionItemHeading {
                            border-bottom: 2px solid transparent;

                            &.has-dropdown {
                                &:after {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .subAccordionItemContent {
                            transform: scaleY(1);
                            transform-origin: top;
                            opacity: 1;
                            transition: opacity 500ms ease-in-out;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: block;
                            overflow: auto;
                            background-color: $white;

                            .country-list {
                                @include list-reset;
                                column-count: 4;

                                li {
                                    padding-top: 20px;

                                    a, a:visited {
                                        font-family: $primary-bold;
                                        font-size: 14px;
                                        color: $dark-blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.close {
            padding: 0;
            margin: 0;
            position: relative;
            float: none;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            color: inherit;
            text-shadow: none;
            opacity: 1;
            z-index: 3;

            .accordionItem {
                opacity: 1;
                z-index: 3;


                button.accordionItemHeading {
                    background-color: transparent;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .accordionItemContent {
                height: 0px;
                transform: scaleY(0);
                opacity: 0;
                display: none;
            }
        }
    }

    .login {
        border: none;
        outline: none;
        text-transform: uppercase;
        font-family: $primary-semibold;
        font-size: 13px;
        background-color: $white;
        cursor: pointer;
    }
}

.country-search {
    display: none;
}

.utility-brand-bar-logo-container {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
    margin-top: -3px;
}

.utility-brand-bar-logo-wrap {
    display: inline-block;
    margin: 0 auto;
}

.utility-brand-bar-logo {
    height:30px;
}

@import "utilitybrandbar-mobile";