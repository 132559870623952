@include max-screen(md) {
    .featuredinsight {
        .insight-content {
            padding: 40px 0;
        }
    }
}

@include max-screen(sm) {
    .featuredinsight {
        .insight-content {
            .type {
                font-size: 16px;
                line-height: 1.2;
            }

            .tags {
                li {
                    font-size: 11px;
                    line-height: 1.2;
                }
            }

            &-title {
                font-size: 32px;
                line-height: 1.3;
            }

            .date {
                font-size: 16px;
                line-height: 1.2;
            }

            a, a:visited {
                @include text-link-2;
            }
        }
    }
}
