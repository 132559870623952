﻿.universal-headermedium {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    height: 0; // IE11 bug fix
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: 520px;
    width: 100%;

    &-heading {
        font-family: $primary-regular;
        font-size: 56px;
        line-height: 1.1;
        margin-bottom: 0;
        max-width: 60%;
        color: $white;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .desc {
        display: block;
        font-size: 32px;
        line-height: 1.3;
        font-family: $primary-semibold;
        margin-bottom: 0;
        color: $white;
        max-width: 40%;
    }

    &-button {
        display: inline-block;
        margin-top: 30px;
        @include primary-button;

        &:visited {
            color: inherit;
        }

        &:hover,
        &:focus {
            color: $white;
        }
    }
}

@import "universalheadermedium-mobile";
