@include max-screen(md) {
    .universaljumboheader {
        padding-bottom: 120px;
    }
}

@include max-screen(sm) {
    .universaljumboheader {
        padding-bottom: 80px;
    }
}
