@include max-screen(lg) {
    .universalexpandablecallout {
        margin-top: 88px;
        margin-bottom: 88px;
    }
}

@include max-screen(md) {
    .universalexpandablecallout {
        margin-top: 64px;
        margin-bottom: 64px;

        &-container {
            padding: 32px 64px;
        }

        &-title {
            width: 75%;
        }

        &-list {
            .universallinklist {
                column-count: 3;
                column-gap: 24px;
            }
        }
    }
}

@include max-screen(sm) {
    .universalexpandablecallout {
        &-container {
            padding: 32px 24px;
        }

        &-header {
            flex-direction: column;

            + .universalexpandablecallout-list {
                margin-top: 48px;
            }
        }

        &-title {
            line-height: 41px;
            width: 100%;

            + .universalexpandablecallout-button {
                margin-top: 48px;
            }
        }

        &-list {
            .universallinklist {
                column-count: 2;
                &-item {
                    height: auto;
                }
            }
        }
    }
}
