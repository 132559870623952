@include max-screen(lg) {
    .universallandingheader {
        &-title {
            font-size: 56px;
        }

        &-subtitle {
            font-size: 28px;
            line-height: 40px;
        }
    }
}

@include max-screen(md) {
    .universallandingheader {
        margin-bottom: 32px;

        &-title {
            font-size: 50px;
        }

        &-subtitle {
            font-size: 24px;
            line-height: 34px;
        }
    }
}

@include max-screen(sm) {
    .universallandingheader {
        padding: 56px 0;

        &-title {
            font-size: 44px;
            line-height: 58px;

            + .universallandingheader-subtitle {
                margin-top: 8px;
            }
        }

        &-subtitle {
            font-size: 20px;
        }
    }
}