﻿@include max-screen(sm) {
    .campaignfooter {
        &-social {
            justify-content: flex-start;

            &:not(:first-child) {
                margin-top: 16px;
            }
        }

        &-ctatext {
            order: -1;
            text-align: left;
        }
    }
}
