.universalinsightsummary {
    margin-top: 48px;
    margin-bottom: 48px;

    &-title {
        color: $darkest-grey;
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        text-transform: uppercase;

        &:not(:only-child) {
            margin-bottom: 8px;
        }
    }

    &-content {
        &,
        * {
            color: $dark-grey;
            font-size: 16px;
            line-height: 24px;
        }

        ul,
        ol {
            margin-bottom: 0;
        }

        ul {
            list-style-type: none;
            padding-left: 40px;

            li {
                padding-left: 24px;

                &::before {
                    background-color: $black;
                    content: '';
                    display: inline-block;
                    height: 6px;
                    margin-right: -6px;
                    position: relative;
                    right: 24px;
                    top: -1px;
                    width: 6px;
                    vertical-align: middle;
                }
            }
        }

        li + li {
            margin-top: 16px;
        }
    }
}

@import "insightsummary-mobile";
