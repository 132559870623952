.universalconvoui-nav {
    &-btn {
        background: none;
        border: none;
        font-family: $primary-regular;
        font-size: 12px;
        letter-spacing: 0.25px;
        line-height: 16px;
        padding: 0;
        position: relative;

        &::before,
        &::after {
            border-radius: 50%;
            content: '';
            display: block;
            height: 56px;
            width: 56px;
        }

        &::before {
            box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
            margin-bottom: 8px;
            transition: box-shadow .3s ease-in-out;
        }

        &::after {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 70%;
            left: 0;
            position: absolute;
            top: 0;
        }

        &.active {
            color: $dark-blue;
            pointer-events: none;

            &::before {
                background: linear-gradient(136.8deg, #00549E 0%, #3AB291 100%);
            }
        }

        &:not(.active) {
            color: $darkest-grey;
            transition: color .3s ease-in-out;

            &::before {
                background-color: $white;
            }
        }

        & + & {
            margin-left: 44px;
        }

        &:hover {
            &:not(.active) {
                color: $dark-blue;

                &::before {
                    box-shadow: 0 3px 15px 0 rgba(0,0,0,0.2);
                }
            }
        }
    }

    &-assistant {
        &.active {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_chat_inverse.svg');
            }
        }

        &:not(.active) {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_chat.svg');
            }
        }
    }

    &-contact {
        &.active {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_people_inverse.svg');
            }
        }

        &:not(.active) {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_people.svg');
            }
        }
    }

    &-recent {
        &.active {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_recent_inverse.svg');
            }
        }

        &:not(.active) {
            &::after {
                background-image: url('../../../../../Images/Base/dist/icon_convoui_recent.svg');
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .universalconvoui-nav {
        &-btn {
            & + & {
                margin-left: 24px;
            }
        }
    }
}