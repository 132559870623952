@include max-screen(sm) {
    .universalflarelist {
        &-item {
            border-top-width: 1px;
            padding-top: 8px;

            &-link,
            &-linktext {
                font-size: 18px;
            }

            &::before {
                height: 4px;
            }
        }
    }
}
