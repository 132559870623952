.productcardlist {
    margin: 50px 0;

    [class*=' col-']:not(h1):not(h2) {
        padding-left: 25px;
        padding-right: 25px;
    }

    .row {
        margin-top: -35px;
        margin-bottom: -35px;

        > [class*="col-"] {
            display: flex; // Enable stretching
        }
    }

    .productcard {
        margin: 35px 0;
        width: 100%;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.1);

        .productcardimage {
            background-color: $lightest-grey;
            width: 100%;
            height: 230px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                max-width: 220px;
                height: auto;
                max-height: 220px;
            }
        }

        a, a:visited {

            .productcardcontent {
                h2 {
                    @include add-caret;
                    color: $darkest-grey;
                }

                .title, .desc {
                    color: $black;
                }
            }

            &:hover, &:focus {
                text-decoration: none;
                color: $black;

                .productcardcontent {
                    h2 {
                        @include text-link-1;
                        margin-bottom: 6px;
                    }

                    .title, .desc {
                        color: $black;
                    }
                }
            }
        }

        .productcardcontent {
            padding: 20px 30px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // Implement height/hover interaction ONLY if hovering is possible:
            @media (any-hover: hover) {
                overflow: hidden;
                position: relative;
                height: 265px;

                &,
                &::after {
                    transition: height .4s;
                }

                &::after {
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 45%, white 100%);
                    bottom: 0;
                    content: '';
                    height: 60px;
                    left: 0;
                    right: 0;
                    position: absolute;
                }

                &:hover {
                    &::after {
                        height: 0;
                    }
                }
            }

            h2 {
                color: $darkest-grey;
                font-family: $primary-semibold;
                font-size: 22px;
                line-height: 1.3;
            }

            .desc {
                span, p {
                    display: block;
                    margin-bottom: 20px;
                }

                ul {
                    list-style: square;
                    padding: 0 20px;
                    font-family: $primary-regular;
                    font-size: 14px;
                    color: $dark-grey;

                    li {
                        margin-bottom: 10px;
                        line-height: 1.3;
                    }
                }

                *:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .productcarddetails {
            padding: 15px 30px 0 30px;
            border-bottom: 25px solid $white; // to hide overflow
            border-top: 1px solid $lighter-grey;
            min-height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                display: block;
                font-size: 14px;
                margin-bottom: 5px;
                font-family: $primary-bold;
                color: $grey;
            }

            ul {
                @include list-reset;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-bottom: 10px;

                li {
                    margin-right: 30px;

                    a, a:visited {
                        font-family: $primary-bold;
                        font-size: 16px;
                        color: $black;
                    }

                    &:hover, &:focus {
                        text-decoration: underline;
                    }

                    &:after {
                        content: url('/Images/Base/dist/arrow-up-right.svg');
                        display: inline-block;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

@import "productcardlist-mobile";
