﻿@include max-screen(lg) {
    .universalheadersearchoverlay {
        &-listitem {
            &:first-child {
                margin-top: 8px;
            }

            &-date {
                margin-top: 4px;
            }
        }
    }
}

@include max-screen(md) {
    .universalheadersearchoverlay {
        &-searchbar {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        &-col {
            & + & {
                margin-top: 40px;
            }

            &-title {
                letter-spacing: 1.75px;
                line-height: 30px;
            }

            &.col-md-3 {
                .universalheadersearchoverlay-listitem-link {
                    @include link;
                    font-family: $primary-regular;
                }
            }
        }

        &-listitem {
            &-link {
                font-size: 16px;
                line-height: 26px;
            }

            &-date {
                line-height: 24px;
            }
        }
    }
}

@include max-screen(sm) {
    .universalheadersearchoverlay {
        &-container {
            padding: 50px 0 88px;
        }

        &-searchbar {
            margin-left: 0;
            max-width: calc(100% - 38px);

            .CoveoSearchbox .magic-box .magic-box-input > input,
            &-input {
                padding: 0 0 8px 0;
            }
        }
    }
}
