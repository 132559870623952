﻿.contact-info-block-and-form {
    .form-control,
    .btn-cancel,
    .btn-save,
    .btn-edit,
    #divspan_contact_ValidationMessageLabel,
    #divspan_contact_SuccessMessageLabel,
    .current-form-field-value {
        display: none;
    }

    .active {
        display: block;
    }

    .form-group {
        input {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .messages,
    .alert {
        display: none;

        &.active {
            display: block;
        }
    }
}




// PROFILE FORMS =============================================== *
.profile-form {
    background-color: #F5F5F5;
    padding: 40px;

    .col:nth-child(odd) {
        margin-right: 10px;
    }

    .col:nth-child(even) {
        margin-left: 10px;
    }

    .form-row {
        border-top: 1px solid $lighter-grey;
        margin: 0;
        padding: 15px 0;
    }

    .form-header {
        position: relative;
    }

    .header-options {
        position: absolute;
        right: 0;
        top: 0;

        .btn + .btn {
            margin-left: 10px;
        }
    }
    /* Profile Form Buttons */
    .btn-primary,
    .btn-secondary {
        display: none;
        padding: 10px 20px;

        &.active {
            display: inline-block;
        }

        &:hover {
            text-decoration: none;
        }
    }
    /*.btn-primary {
        background: $dark-blue;
        color: #fff;

        &:hover {
            background-color: lighten($dark-blue, 10%);
            color: #fff;
        }
    }*/
    /*.btn-secondary {
        background-color: #FEBD38;

        &:hover {
            background-color: darken(#FEBD38, 10%);
            color: #000;
        }

    }*/
    .btn-primary {
        background: #00549E;
        background-size: 100% 0px;
        border-radius: 0;
        color: white;
        display: inline-block;
        //font-family: $primary-semibold;
        overflow: hidden;
        padding: 12px 24px;
        position: relative;
        border: none;

        &::after {
            background: linear-gradient(to left, #2D72B9 0%, #2CBCB1 100%);
            border-radius: 0;
            bottom: 0;
            content: '';
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
            transition: transform 300ms ease-in-out;
            transform: translateY(100%);
        }

        &:hover,
        &:focus {
            cursor: pointer;
            text-decoration: none;

            &::after {
                transform: translateY(0);
            }
        }
    }

    .btn-secondary {
        background: white;
        background-size: 100% 0px;
        border-radius: 0;
        color: #2D72B9;
        //display: inline-block;
        //font-family: $primary-semibold;
        overflow: hidden;
        padding: 12px 24px;
        position: relative;
        border: none;

        &::after {
            background: linear-gradient(to left, #2D72B9 0%, #2CBCB1 100%);
            border-radius: 0;
            bottom: 0;
            content: '';
            height: 4px;
            left: 0;
            position: absolute;
            right: 0;
            transition: transform 300ms ease-in-out;
            transform: translateY(100%);
        }

        &:hover,
        &:focus {
            cursor: pointer;
            text-decoration: none;
            background: none;
            background-size: initial;
            background-color: #00549E;
            color: white;

            &::after {
                transform: translateY(0);
            }
        }
    }

    .btn-save,
    .btn-cancel,
    input,
    select {
        display: none;
    }
}

/* Profile Forms - When Editable */
.editable-form {

	.btn-cancel,
    .btn-cancel.active,
	.btn-save,
    .btn-save.active {
		display: inline-block !important;
	}
	input,
	select {
		display: block;
	}
	.selected-option,
	.btn-edit,
	span {
		display: none;
	}
	.form-row {
		border-top: none;
		margin: 0 0 25px 0;
		padding: 0;
	}
}




@import "contactinfoblockandform-mobile";
