.universalinsighthighlight {
    margin-top: 56px;
    margin-bottom: 56px;
    padding-left: 30px;
    position: relative;

    &::before {
        background-color: $dark-blue;
        bottom: 0;
        content: '';
        left: 0;
        top: 0;
        position: absolute;
        width: 6px;
    }

    &-title {
        color: $darkest-grey;
        font-family: $primary-semibold;
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 0;
    }
}

@import "insighthighlight-mobile";
