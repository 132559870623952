@include max-screen(md) {
    .universalinsightsummary {
        margin-bottom: 32px;

        &-content {
            ul {
                padding-left: 24px;
            }
        }
    }
}

@include max-screen(sm) {
    .universalinsightsummary {
        &-content {
            ul {
                padding-left: 0;

                li {
                    padding-left: 16px;

                    &::before {
                        right: 16px;
                    }
                }
            }
        }
    }
}
