﻿.site-search {
    &.CoveoSearchInterface {
        .coveo-search-section {
            background-color: $dark-blue;
            padding: 102px 24px 98px;

            &,
            .CoveoSearchbox {
                margin: auto;
                max-width: 100%;
            }

            *:not(.CoveoSearchbox) .CoveoOmniboxResultList {
                display: none; // Fixes Coveo bug
            }

            .CoveoSearchbox {
                width: 810px;

                .magic-box {
                    border-radius: 0;
                    border-width: 0 0 2px 0;
                    border-bottom-color: $white;

                    &-input {
                        background-color: transparent;

                        input {
                            color: $white;
                            font-family: $primary-semibold;
                            font-size: 38px;
                            margin: 0;
                            text-indent: 0;
                            padding: 0 0 9px 0;

                            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                                color: $white;
                            }

                            &::-moz-placeholder { /* Firefox 19+ */
                                color: $white;
                            }

                            &:-ms-input-placeholder { /* IE 10+ */
                                color: $white;
                            }

                            &:-moz-placeholder { /* Firefox 18- */
                                color: $white;
                            }
                        }
                    }

                    &-clear {
                        background-color: transparent;
                        opacity: 0;
                        transition: opacity .3s ease-out;

                        &-svg g {
                            fill: $white;
                        }
                    }

                    &-suggestions {
                        border-bottom: 0;
                        margin-top: 5px;

                        .search-wrap-text-excerpt,
                        .search-wrap-image {
                            display: none;
                        }

                        .coveo-omnibox-selectable {
                            padding: 12px 16px;
                        }

                        .coveo-omnibox-selected {
                            background-color: transparent;
                            cursor: default;
                        }
                    }

                    &-notEmpty {
                        .magic-box-clear {
                            opacity: 1;
                            width: auto;
                        }
                    }
                }

                .CoveoSearchButton {
                    display: none;
                }
            }
        }

        .coveo-main-section {
            .coveo-header {
                margin-bottom: 40px;

                .coveo-tab-section {
                    background-color: transparent;
                    border-bottom: 4px solid $lightest-grey;
                    margin: 0 24px;
                    max-width: calc(100% - 48px);
                    min-height: 0;
                    text-align: left;
                    padding-left: calc(30.85278% - 41px); // Align to the results list column
                    .CoveoTab {
                        height: auto;
                        padding: 24px 32px 10px 32px;

                        &,
                        * {
                            color: $dark-grey;
                            font-family: $primary-bold;
                            font-size: 16px;
                            line-height: 30px;
                            text-transform: capitalize;
                        }

                        &.coveo-selected {
                            border-bottom: 0;
                            position: relative;

                            &,
                            * {
                                color: $darkest-grey;
                            }

                            p {
                                border: 0;
                            }

                            &::after {
                                background: linear-gradient(147.61deg, $aqua 0%, $blue 100%);
                                bottom: 0;
                                content: '';
                                height: 4px;
                                left: 50%;
                                max-width: calc(100% - 64px);
                                position: absolute;
                                transform: translate(-50%, 100%);
                                width: 52px;
                            }
                        }
                    }

                    .coveo-tab-dropdown-header {
                        @extend .CoveoTab;

                        &,
                        * {
                            font-family: $primary-regular;
                            font-weight: normal;
                        }

                        .coveo-more-tabs {
                            margin-left: 8px;

                            &-svg {
                                height: 10px;
                                width: 10px;
                            }
                        }
                    }
                }
            }
        }

        .coveo-facet-column {
            padding: 0 15px 0 24px;

            .coveo-facet-header-filter-by-container {
                display: block !important; // Override Coveo inline styles
                .coveo-facet-header-filter-by { // Coveo bug(?) renders same element twice
                    color: $darkest-grey;
                    font-family: $primary-semibold;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    line-height: 16px;
                    margin-bottom: 12px;
                    text-transform: uppercase;
                }
            }

            .CoveoHierarchicalFacet,
            .CoveoFacet {
                border-color: $lighter-grey;
                border-radius: 0;
                border-width: 1px 0 0 0;
                margin: 0;
                padding-bottom: 20px;

                .coveo-facet-header {
                    background-color: transparent;
                    margin: 15px 0;
                    padding: 0;

                    &-title {
                        color: $darkest-grey;
                        font-size: 13px;
                        font-family: $primary-semibold;
                        letter-spacing: 0.5px;
                        line-height: 30px;
                        text-transform: uppercase;
                    }

                    &-settings-section {
                        display: none;
                    }
                }

                .coveo-facet-values {
                    &,
                    .coveo-facet-value {
                        padding: 0;
                    }

                    .coveo-facet-value {
                        margin-bottom: 16px;

                        &:not(:first-of-type) {
                            margin-top: 16px;
                        }

                        &.coveo-selected {
                            font-weight: inherit;

                            .coveo-facet-value-checkbox {
                                background-image: url('/Images/Base/dist/icon_check_box_blue.svg');
                                background-repeat: no-repeat;
                                background-size: contain;
                                background-position: center center;
                                border-width: 0;
                                margin-right: 5px;
                                width: 27px;
                            }
                        }

                        &.coveo-focused,
                        &:hover {
                            background-color: transparent;
                        }

                        &-exclude,
                        &-checkbox-svg {
                            display: none;
                        }

                        &-label {
                            margin: 0;
                            padding: 0;

                            &-wrapper {
                                align-items: center;
                                display: flex;
                                font-family: $primary-regular;
                                font-size: 14px;
                                letter-spacing: normal;
                                line-height: 20px;
                                text-transform: capitalize;

                                .coveo-facet-value {
                                    &-checkbox,
                                    &-count {
                                        float: none;
                                    }

                                    &-checkbox {
                                        border: 1px solid #9E9E9E;
                                        border-radius: 0;
                                        height: 20px;
                                        margin-right: 12px;
                                        top: 0;
                                        width: 20px;
                                    }

                                    &-count {
                                        order: 2;

                                        &::before {
                                            content: '(';
                                        }

                                        &::after {
                                            content: ')';
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .CoveoHierarchicalFacet {
                .coveo-facet-value.coveo-has-childs:not(.coveo-inactive) {
                    display: flex;
                    justify-content: space-between;

                    .coveo-has-childs-toggle {
                        align-items: center;
                        left: auto;
                        order: 1;
                        position: relative;
                        top: auto;

                        &,
                        span {
                            display: flex;
                        }
                    }
                }
            }
            // SVG DISPLAY NONE
            .coveo-facet-footer {
                background-color: transparent;

                &.coveo-facet-empty {
                    display: none;
                }

                .coveo-facet-less,
                .coveo-facet-more {
                    height: auto;
                    text-align: left;

                    &.coveo-active {
                        font-size: 14px;
                        font-family: $primary-bold;

                        &,
                        &:visited,
                        &:hover,
                        &:focus {
                            color: $blue;
                        }

                        &::after {
                            background: transparent url(/Images/Base/dist/icon-caret-darkblue.svg) no-repeat;
                            content: '';
                            display: inline-block;
                            height: 15px;
                            width: 15px;
                        }
                    }

                    &,
                    &-icon {
                        background-color: transparent;
                    }

                    &-icon {
                        display: none;
                    }
                }

                .coveo-facet-less {
                    &::before {
                        content: 'Show less';
                    }

                    &::after {
                        transform: rotate(270deg) translate(10%, 40%);
                    }
                }

                .coveo-facet-more {
                    &::before {
                        content: 'Show more';
                    }

                    &::after {
                        transform: rotate(90deg) translate(50%, -10%);
                    }
                }
            }
        }

        .coveo-results-column {
            &:not(.coveo-no-results) {
                padding: 0 24px 147px 15px;

                .nosearchresults {
                    display: none;
                }
            }

            &.coveo-no-results {
                max-width: 1200px;
                padding: 0 24px 147px;
                width: 100%;

                .CoveoResultList {
                    margin: 0;
                }

                .nosearchresults {
                    display: flex;
                }
            }

            .nosearchresults {
                padding-top: 48px;
                text-align: left;

                &-col {
                    flex-basis: calc((100% / 3) - 24px);
                    flex-grow: 1;
                    padding-right: 24px;

                    &-header {
                        color: $light-grey;
                        font-family: $primary-bold;
                        font-size: 14px;
                        letter-spacing: 1.75px;
                        line-height: 30px;
                        margin: 0;
                        text-transform: uppercase;

                        &:not(:only-child) {
                            margin-bottom: 24px;
                        }
                    }

                    &-links {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        &-item {
                            @include link-common;
                            font-family: $primary-semibold;
                            font-size: 16px;
                            line-height: 28px;

                            &:not(:last-child) {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }
            }

            .coveo-tab-section.coveo-placeholder-fix,
            .CoveoLogo {
                display: none;
            }

            .coveo-results-header {
                border-bottom: 1px solid $lighter-grey;
                box-shadow: none;
                min-height: 0;
                padding: 0 0 8px;

                .CoveoQuerySummary,
                .CoveoSort {
                    color: $dark-grey;
                    font-family: $primary-semibold;
                    font-size: 14px;
                    line-height: 19px;
                }

                .coveo-summary-section {
                    .CoveoQuerySummary {
                        letter-spacing: 0.54px;

                        .coveo-query-summary-no-results-string {
                            color: $coal-grey;
                            font-family: $primary-regular;
                            font-size: 18px;
                            line-height: 28px;
                            margin-bottom: 0;
                        }

                        .coveo-highlight {
                            font-family: $primary-bold;
                        }

                        .coveo-query-summary-cancel-last,
                        .coveo-query-summary-search-tips-info,
                        .coveo-query-summary-search-tips-info + ul {
                            display: none;
                        }
                    }
                }

                .coveo-result-layout-section {
                    display: none;
                }

                .coveo-sort-section {
                    display: none;

                    .coveo-sort-container {
                        .CoveoSort {
                            border: 0;
                            padding: 0;
                            text-transform: capitalize;
                        }

                        &:not(:last-of-type) {
                            margin-right: 16px;
                        }
                    }
                }
            }

            .CoveoResultList {
                margin: 0 0 24px 0;

                .coveo-result-list-container {
                    display: block;
                    height: auto !important; // Overriding inline Coveo style
                    margin-right: 0;

                    .coveo-card-layout.CoveoResult {
                        border-radius: 0;
                        border-color: #E0E0E0;
                        border-width: 0 0 1px 0;
                        margin: 0;
                        width: 100%;

                        &,
                        .search-wrap {
                            transition: all .3s;
                        }
                        // Custom handlebar search card template:
                        .search-wrap {
                            align-items: flex-start;
                            display: flex;
                            justify-content: space-between;
                            padding-top: 24px;
                            padding-bottom: 24px;
                            width: 100%;

                            &-text {
                                flex-grow: 1;
                            }

                            &-image {
                                flex-shrink: 0;
                                height: auto;
                                margin-left: 68px;
                                width: 140px;

                                &[src=""] {
                                    display: none;
                                }
                            }
                        }

                        .CoveoResultLink {
                            color: $darkest-grey;
                            font-family: $primary-semibold;
                            font-size: 18px;
                            line-height: 28px;
                            margin: 0;
                        }

                        .search-wrap-text-excerpt,
                        .CoveoExcerpt {
                            margin-bottom: 0;

                            &:not(:first-child) {
                                margin-top: 4px;
                            }

                            &,
                            * {
                                color: $darkest-grey;
                                font-family: $primary-regular;
                                font-size: 14px;
                                line-height: 21px;
                            }

                            .coveo-highlight {
                                font-family: $primary-semibold;
                            }
                        }


                        &:hover {
                            box-shadow: 0 8px 32px 0 rgba(171,171,171,0.5);

                            .search-wrap {
                                padding-left: 24px;
                                padding-right: 24px;

                                &-image {
                                    margin-left: 44px;
                                }
                            }
                        }
                    }
                }
            }

            .CoveoPager {
                display: inline;
                margin: 0;

                .coveo-pager-list {
                    align-items: center;
                    display: flex;
                    flex-wrap: wrap;

                    &-item {
                        background-color: transparent;
                        border: 0;
                        color: $light-grey;
                        font-family: $primary-bold;
                        font-size: 16px;
                        line-height: 30px;
                        margin: 0;
                        padding: 0;

                        .coveo-pager-anchor {
                            display: block;
                            min-width: 16px;
                            text-align: center;
                        }

                        &:not(:last-child) {
                            margin-right: 16px;
                        }

                        &.coveo-active .coveo-pager-anchor {
                            color: $darkest-grey;
                            position: relative;

                            &::after {
                                background-color: $darkest-grey;
                                bottom: 0;
                                content: '';
                                height: 2px;
                                left: 0;
                                position: absolute;
                                transform: translateY(125%);
                                width: 100%;
                            }
                        }
                    }

                    .coveo-pager-next-icon-svg,
                    .coveo-pager-previous-icon-svg {
                        color: $darkest-grey;
                        height: 10px;
                        vertical-align: baseline;
                    }
                }
            }
        }

        .coveo-tab-list-container {
            li:hover {
                background-color: transparent;
            }

            .coveo-tab-dropdown {
                &,
                * {
                    border: 0 !important; // Overriding Coveo class / style add
                    color: $dark-grey;
                    font-family: $primary-bold;
                    line-height: 27px;
                    text-transform: capitalize;
                }
            }
        }
        // Custom handlebar search card template; title styles are shared among search sections/components
        .search-wrap-text-title {
            color: $darkest-grey;
            font-family: $primary-semibold;
            font-size: 18px;
            line-height: 28px;
            margin: 0;
        }
    }
}

@import '_sitesearch-mobile';
