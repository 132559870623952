﻿.universalprofilesectionpagesublayout {
    .left-nav {
        background-color: #F5F5F5;
        padding: 40px;
        margin-bottom: 50px;

        .profile-section-navigation-list {
            .profile-section-navigation-list-header {
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    text-transform: initial;
                    display: block;
                    margin-bottom: 10px;
                }
            }

            .profile-section-navigation-item {
                color: #00549E;

                .link {
                    color: #00549E;
                }

                &.active {
                    text-decoration: none;

                    .link {
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .reg-text-header {
        //display: none;
        text-transform: none;
        color: #00539e;
        text-align: left;
        margin: 0 0 10px 20px;
    }

    .content {
        .profile-section-crime-map-iframe-container {
            iframe.profile-section-crime-map-iframe {
                border: none;
            }
        }

        .dashboard-content {
            a.dashboard-button {
                background: #00549E;
                background-size: 100% 0px;
                border-radius: 0;
                color: white;
                display: inline-block;
                //font-family: $primary-semibold;
                overflow: hidden;
                padding: 12px 24px;
                position: relative;
                border: none;
                margin-right: 10px;
                margin-bottom: 30px;

                &::after {
                    background: linear-gradient(to left, #2D72B9 0%, #2CBCB1 100%);
                    border-radius: 0;
                    bottom: 0;
                    content: '';
                    height: 4px;
                    left: 0;
                    position: absolute;
                    right: 0;
                    transition: transform 300ms ease-in-out;
                    transform: translateY(100%);
                }

                &:hover,
                &:focus {
                    cursor: pointer;
                    text-decoration: none;

                    &::after {
                        transform: translateY(0);
                    }
                }
            }
        }

        .managed-services-card {
            margin-bottom: 50px;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                max-height: 230px;
                overflow: hidden;
            }

            h3 {
                font-size: 22px;
                line-height: 1.3;
                margin: 20px 0;
            }

            p {
                a, a:visited {
                    color: #00549E;
                }
            }

            .buttons {
                a, a:visited {
                    background: #00549E;
                    background-size: 100% 0px;
                    border-radius: 0;
                    color: white;
                    display: inline-block;
                    //font-family: $primary-semibold;
                    overflow: hidden;
                    padding: 12px 24px;
                    position: relative;
                    border: none;
                    margin-right: 10px;

                    &::after {
                        background: linear-gradient(to left, #2D72B9 0%, #2CBCB1 100%);
                        border-radius: 0;
                        bottom: 0;
                        content: '';
                        height: 4px;
                        left: 0;
                        position: absolute;
                        right: 0;
                        transition: transform 300ms ease-in-out;
                        transform: translateY(100%);
                    }

                    &:hover,
                    &:focus {
                        cursor: pointer;
                        text-decoration: none;

                        &::after {
                            transform: translateY(0);
                        }
                    }
                }
            }
        }
    }
}

@import "profilesectionpagesublayout-mobile";
