@include max-screen(lg) {
    .universalinsightheader {
        &-title {
            font-size: 50px;
        }
    }
}

@include max-screen(md) {
    .universalinsightheader {
        &-eyebrow {
            font-size: 14px;
            line-height: 28px;
        }

        &-title {
            font-size: 40px;
            line-height: 48px;
        }
    }
}

@include max-screen(sm) {
    .universalinsightheader {
        &-eyebrow {
            font-size: 12px;
            line-height: 24px;
        }

        &-title {
            font-size: 32px;
            line-height: 40px;
        }
    }
}
