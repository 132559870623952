.universallocalpagedetail {
    margin-top: 64px;
    margin-bottom: 64px;

    &-title {
        color: $darkest-grey;
        font-family: $primary-regular;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 32px;
    }

    &-row {
        & + & {
            margin-top: 24px;
        }
    }

    &-col {
        &:last-child {
            min-width: calc(25% + 24px);
        }
    }

    &-address-line,
    &-contactnumbers-line,
    &-hours-item,
    &-counties,
    &-desc,
    &-desc * {
        color: $dark-grey;
        font-size: 16px;
        line-height: 30px;
    }

    &-address-line,
    &-contactnumbers-line,
    &-hours-item,
    &-counties {
        margin-bottom: 0;
    }

    &-address,
    &-contactnumbers {
        display: inline-block;
        margin-bottom: 16px;
        vertical-align: top;
        width: calc(50% - 8px);
    }

    &-address {
        margin-right: 11px;
    }

    &-hours {
        column-count: 2;
        column-gap: 38px;

        &-item {
            &-prefix {
                font-family: $primary-bold;
            }
        }
    }

    &-img {
        width: 100%;
    }

    &-map {
        border: none;
        width: 100%;
        height: 1400px;
        position: absolute;
        top: -558px;
        left: 2px;

        &-container {
            height: 500px;
            margin-bottom: 36px;
            padding-bottom: 56.25%;
            position: relative;
            overflow: hidden;
            width: 100%;
        }
    }
}

@import "localpagedetail-mobile";
