@include max-screen(md) {
    .universalproductbar {
        &-list {
            padding: 16px 0;

            &-item {
                width: 130px;

                &-img {
                    height: 60px;

                    &:not(:only-child) {
                        margin-bottom: 8px;
                    }
                }

                &-title {
                    font-size: 14px;
                    line-height: 22px;
                }
            }
        }
    }
}