@include max-screen(lg) {
    .hometwocolumnflare {
    
    }
}

@include max-screen(md) {
    .hometwocolumnflare {
        .media {
            flex-direction: column-reverse;
            margin-bottom: 50px;

            img {
                margin-left: 0 !important;
                width: 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }
        }
    }
}

@include max-screen(sm) {
    .hometwocolumnflare {
    
    }
}