@include max-screen(sm) {
    .universalhostedby {
        margin-top: 32px;
        margin-bottom: 64px;

        &-host {
            flex-basis: 100%;

            &:nth-child(2) {
                margin-top: 24px;
            }
        }
    }
}
