.hometwocolumnflare {
    margin: 95px 0;

    .border-top {
        border-top: 2px solid $lighter-grey;
        padding-top: 50px;
        position: relative;

        &:before {
            background: linear-gradient(47.74deg, #00549E 0%, #00B7A8 100%);
            content: '';
            display: block;
            height: 4px;
            top: 0;
            transform: translateY(-50%) translateY(-1px);
            transform: translateY(calc(-50% - 1px));
            position: absolute;
            width: 120px;
        }
    }

    .row {
        @include clearfix();
    }

    .media {

        img {
            margin-left: 25px;
            max-width: 290px;
        }
    }

    &-title {
        font-size: 22px;
        font-family: $primary-semibold;
        line-height: 30px;
    }

    &-desc {
        font-size: 14px;
        line-height: 21px;
        margin-top: 16px;
    }

    &-button {
        @include primary-button;
        line-height: 30px;
        margin-top: 16px;

        &:visited,
        &:hover {
            color: $white;
        }
    }
}

@import "hometwocolumnflare-mobile";