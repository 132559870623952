@include max-screen(md) {
    .universalinsightdatacallout1col {
        margin-top: 46px;
        margin-bottom: 46px;
    }
}

@include max-screen(sm) {
    .universalinsightdatacallout1col {
        margin-top: 32px;
        margin-bottom: 32px;

        .universalinsightdatacalloutsmall {
            flex-wrap: nowrap;
            padding-left: 0;
            padding-right: 0;

            &-text {
                &-callout {
                    &:not(:only-child) {
                        margin-right: 16px;
                    }
                }

                &-large {
                    font-size: 115px;
                    margin-top: -20px;
                }

                &-medium {
                    font-size: 45px;
                }

                &-small {
                    margin-top: 0;
                }
            }
        }
    }
}

@media (max-width: 550px) {
    .universalinsightdatacallout1col {
        .universalinsightdatacalloutsmall {
            flex-wrap: wrap;

            &-text {
                &-callout {
                    width: 100%;
                }

                &-large {
                    font-size: 100px;
                }

                &-small {
                    margin-top: 16px;
                    width: calc(100% - 56px);

                    &:not(:only-child) {
                        margin-left: 16px;
                    }
                }
            }

            &-icon {
                margin-top: 16px;
            }
        }
    }
}
