.universaltexttable {
    margin-top: 104px;
    margin-bottom: 104px;

    &-title,
    &-item-title {
        color: $darkest-grey;
    }

    &-title {
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 16px;
        text-transform: uppercase;
    }

    &-item {
        border-top: 1px solid $lighter-grey;
        padding-top: 24px;
        padding-bottom: 42px;

        &-title {
            font-family: $primary-semibold;
            font-size: 18px;
            line-height: 31px;
            margin-bottom: 16px;
        }

        &-col {
            a {
                @include link;
            }

            &,
            * {
                color: $darkest-grey;
                font-size: 14px;
                line-height: 25px;
            }

            p:last-child {
                margin-bottom: 0;
            }

            ul {
                margin: 0;
                padding: 0 0 0 18px;
            }

            li {
                &,
                & a {
                    font-family: $primary-semibold;
                    font-size: 16px;
                    line-height: 24px;
                }

                &:not(:last-of-type) {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

@import "_texttable-mobile.scss";
