.universallinklistpanel {
    background-color: $darkest-grey;
    margin-top: 80px;
    margin-bottom: 80px;

    &-imgcol {
        overflow: hidden;
        position: relative;
    }

    &-img {
        left: 50%;
        min-height: 101%;
        min-width: 101%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    &-textcol {
        flex-grow: 1;
        padding-left: 75px;
        padding-right: 0;

        &:only-child {
            @extend .container;
        }

        &,
        &:only-child {
            padding-top: 54px;
            padding-bottom: 54px;
        }
    }

    &-title,
    &-subtitle {
        color: $white;
        font-family: $primary-light;
        margin-bottom: 0;
    }

    &-title {
        font-size: 32px;
        line-height: 41px;

        &:not(:only-child) {
            margin-bottom: 24px;
        }
    }

    &-subtitle {
        font-size: 20px;
        line-height: 28px;
    }

    .universallinklist {
        column-count: 2;
        column-gap: 60px;

        &:not(:only-child) {
            margin-top: 24px;
        }

        &-link,
        &-linktext {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        &-link {
            @include text-link-1;
        }

        &-linktext {
            color: $light-grey;
            display: inline-block;
            font-family: $primary-regular;
            font-size: 18px;
        }
    }
}

@import "linklistpaneldata-mobile";
