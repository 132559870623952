﻿.infographics-imagemap {
    animation: loadRight .5s .5s normal forwards ease-out;
    flex-grow: 1;
    opacity: 0;
    transform: translateX(350%);

    &,
    .zoom {
        height: 100%;
    }

    .zoom {
        align-items: center;
        display: flex;
        overflow: hidden;
    }

    &,
    .zoomChild {
        width: 100%;
    }

    .infographics-backbutton {
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 1;
    }
}

// Mobile kicks in
@media (max-width: 1024px) {
    .infographics-imagemap {
        width: 100%; // IE11 bug fix
        .infographics-backbutton {
            background-color: rgba($white, .9);
            left: 0;
            padding: 10px 30px;
            text-align: left;
            top: 0;
            width: 100%;
        }
    }
}

// Subcomponents
@import 'child-components/_actionindicator';
@import 'child-components/_contextpopup';
