@include max-screen(lg) {
    .homebanner {
    
    }
}

@include max-screen(md) {
    .homebanner {
    
    }
}

@include max-screen(sm) {
    .homebanner {
        .banner-content {
            h1 {
                font-size: 36px;
                line-height: 1.1;
                margin-bottom: 20px;
            }
        }
    }
}