.textlistrollup {
    margin: 50px 0;

    &-title {
        font-size: 40px;
        line-height: 1.3;
        font-family: $primary-semibold;
        max-width: 60%;
        margin-bottom: 50px;
    }

    a,
    a:visited {
        display: block;
        text-decoration: none;
        color: $black;
    }

    .textbox {
        background-color: $white;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.08);
        padding: 20px 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        min-height: 140px;
        margin-bottom: 20px;

        .headline-wrap { // wrap to resolve link width issue
            h3 {
                @include add-caret;
                font-size: 18px;
                font-family: $primary-bold;
                line-height: 1.3;
                margin-bottom: 5px;
            }
        }

        p {
            text-decoration: none;
            font-family: $primary-light;
            font-size: 14px;
        }
    }
}

@import "textlistrollup-mobile";