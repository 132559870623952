@include max-screen(md) {
    .universalinsightdatacallout2col {
        margin-top: 46px;
        margin-bottom: 46px;
    }
}

@include max-screen(sm) {
    .universalinsightdatacallout2col {
        margin-top: 32px;
        margin-bottom: 32px;

        &.showTopBorder {
            .universalinsightdatacalloutsmall {
                padding-top: 46px;
            }
        }

        &.showBottomBorder {
            .universalinsightdatacalloutsmall {
                padding-bottom: 46px;
            }
        }

        .universalinsightdatacalloutsmall {
            padding: 24px 10%;

            &-text {
                &-large {
                    font-size: 80px;
                    margin-bottom: -6px;
                }

                &-small {
                    &:not(:only-child) {
                        margin-top: 16px;
                    }
                }
            }
        } 
    }
}

@media (max-width: 550px) {
    .universalinsightdatacallout2col {
        &.showTopBorder {
            .universalinsightdatacalloutsmall {
                padding-top: 32px;
            }
        }

        &.showBottomBorder {
            .universalinsightdatacalloutsmall {
                padding-bottom: 32px;
            }
        }

        &-col {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        .universalinsightdatacalloutsmall {
            padding: 24px 0;

            &-text {
                &-large {
                    font-size: 56px;
                    margin-top: -14px;
                    margin-bottom: -4px;
                }

                &-medium {
                    font-size: 24px;
                }

                &-small {
                    font-size: 14px;
                    line-height: 26px;
                }
            }

            &-icon {
                width: 24px;
            }
        }
    }
}
