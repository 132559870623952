@include max-screen(lg) {
    .universalinsightataglance {
        &-text {
            padding-right: 70px;
        }
    }
}

@include max-screen(md) {
    .universalinsightataglance {
        &-text {
            padding-right: 15px;

            &-eyebrow {
                font-size: 14px;
                line-height: 28px;
            }

            &-section {
                & + & {
                    margin-top: 24px;
                }

                &-title {
                    font-size: 28px;
                    line-height: 38px;
                    margin-bottom: 8px;
                }
            }
        }

        &-callout {
            left: 0;
            margin: 32px 15px 0;
            padding: 24px;
            width: calc(100% - 30px);
        }
    }
}

@include max-screen(sm) {
    .universalinsightataglance {
        margin-top: 40px;
    }
}
