@include max-screen(sm) {
    .universalanchorbar {
        &-container {
            align-items: center;
            flex-wrap: wrap;
            position: relative;
        }

        &-header,
        nav,
        &-anchors-mobiletoggle,
        &-anchors-listitem {
            width: 100%;
        }

        &-pagetitle-link,
        &-anchors-mobiletoggle,
        &-anchors-listitem,
        &-anchors-listitem-link {
            line-height: 16px;
        }

        &-header {
            order: -2;
            flex-grow: 1;
            width: auto;
        }

        &-pagetitle-link,
        &-anchors-listitem-link {
            margin-top: 0;
            margin-bottom: 0;

            &::before {
                content: none;
            }
        }

        &-pagetitle {
            margin-bottom: 4px;

            &-link {
                font-size: 14px;
            }

            &::after {
                content: none;
            }
        }

        &-anchors-nav {
            display: none;
            margin-right: 0;
            padding-left: 0;
        }

        &-anchors {
            display: block;
            max-width: 100%;

            &-mobiletoggle {
                align-items: center;
                color: $darkest-grey;
                background-color: $white;
                border: 0;
                cursor: pointer;
                display: flex;
                font-family: $primary-bold;
                padding: 0;

                &:focus {
                    outline: none;
                }

                &::after {
                    background-image: url('../../../../Images/Base/dist/dropdown_arrow.svg');
                    background-repeat: no-repeat;
                    content: '';
                    height: 8px;
                    left: 8px;
                    position: relative;
                    top: 2px;
                    width: 11px;
                }
            }

            &-listitem {
                margin-top: 24px;

                &-link {
                    margin-right: 0;
                }

                &:last-child {
                    margin-bottom: 16px;
                }
            }

            &-overflow {
                display: none;
            }
        }

        &-button {
            margin-left: auto;
            order: -1;
            padding: 8px 16px;
        }
    }

    .anchorBarIsFixed {
        .universalanchorbar {
            &-container {
                box-shadow: 0 5px 14px 0 rgba(0,0,0,0.08);
            }

            &::after {
                content: none;
            }
        }
    }

    .mobileAnchorBarIsOpen {
        .universalanchorbar-anchors-nav {
            display: block;
        }
    }
}
