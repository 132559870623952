.universalinsightquilt {
    display: flex;
    justify-content: space-between;
    margin-top: 104px;
    margin-bottom: 104px;

    &-card {
        box-shadow: 0 15px 32px 0 rgba(0,0,0,0.07);
        flex-basis: calc((100% / 3) - 16px);

        &-img-container {
            position: relative;
        }

        &-img-default {
            background-image: url('/Images/CMS/insights_quilt_default_bg.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            height: 222px;
        }

        &-img {
            max-width: 100%;
            width: 100%;
        }

        &-text {
            padding: 32px;

            a, a:visited {
                color: $darkest-grey;
            }
        }

        &-tags {
            margin-bottom: 10px;

            &-item {
                color: $darkest-grey;
                font-family: $primary-semibold;
                font-size: 12px;
                letter-spacing: 0.46px;
                line-height: 12px;
                text-transform: uppercase;

                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }

        &-titlelink {
            @include text-link-1;
        }

        &-titlelinktext {
            margin-bottom: 0;
        }

        &-titlelink,
        &-titlelinktext {
            font-family: $primary-semibold;
            font-size: 22px;
            line-height: 32px;

            &,
            &:visited,
            &:hover,
            &:focus {
                color: $darkest-grey;
            }
        }

        &-author {
            align-items: center;
            display: flex;

            &:not(:first-child) {
                margin-top: 34px;
            }

            &-img {
                border-radius: 50%;
                height: 64px;
                margin-right: 16px;
                width: 64px !important; // override owl carousel style
            }

            &-name {
                font-family: $primary-bold;
                margin-bottom: 4px;
            }

            &-title {
                margin-bottom: 0;
            }

            &-link {
                @include link;
                color: $darkest-grey;
                font-family: $primary-semibold;
            }

            &-name,
            &-title,
            &-link {
                font-size: 14px;
                letter-spacing: 0.01px;
                line-height: 19px;
            }
        }

        &-accent {
            &-inner {
                align-items: flex-start;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: space-between;
                padding: 60px 40px 40px;
            }

            &-title {
                width: 100%; // IE11 fix
                * {
                    @extend h1; // Despite being an h2 tag, we want h1 styles.
                }

                &,
                * {
                    color: $white;
                    font-size: 32px;
                    font-family: $primary-regular;
                    line-height: 41px;
                    margin-bottom: 0;
                }
            }

            &-link {
                @include text-link-1;
                @include add-caret($white);
                font-family: $primary-bold;
                font-size: 14px;
                line-height: 14px;
                margin-top: 120px;

                &,
                &:visited {
                    color: $white;
                }

                &:hover {
                    color: $white;
                }

                &::before {
                    border-color: $white;
                }
            }
        }

        &-video {
            .universalinsightquilt-card-img-container::after {
                background-image: url('/Images/Base/dist/icon-play-button.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                height: 66px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 66px;
            }
        }
    }

    &-slim {
        .universalinsightquilt-card-accent {
            &-inner {
                padding: 40px;
            }

            &-link {
                margin-top: 30px;
            }
        }

        .universalinsightquilt-card:not(.universalinsightquilt-card-accent) {
            align-items: flex-start;
            display: flex;
            flex-basis: calc(((100% / 3) * 2) - 8px);
            padding: 24px;

            .universalinsightquilt-card-img-container {
                flex-shrink: 0;
                margin-right: 48px;
                width: calc(50% - 36px);
            }

            .universalinsightquilt-card-text {
                padding: 0;
            }
        }
    }

    .owl-carousel {
        left: -24px;
        right: -24px;
        width: calc(100% + 48px);

        .universalinsightquilt-card {
            flex-basis: auto;
        }

        .owl-item {
            margin-right: 0 !important;
        }

        .owl-stage-outer {
            padding-bottom: 16px;
        }

        .owl-stage {
            &[style*="transform: translate3d(0px, 0px, 0px)"] {
                // Carousel at beginning
                .owl-item {
                    padding-right: 24px;
                }
            }

            &:not([style*="transform: translate3d(0px, 0px, 0px)"]) {
                // Carousel not at beginning
                .owl-item {
                    padding-left: 24px;

                    &:not(.active) {
                        margin-left: 24px;
                    }
                }
            }
        }
    }
}

@import "insightquilt-mobile";
