.universalfeatureddata {
    margin-top: 80px;
    margin-bottom: 100px;

    &-container-desktoptitles,
    &-container-items {
    }

    &-container-desktoptitles {
        margin-bottom: 27px;
    }

    &-container-items {
        .universalfeatureddata-item-title,
        .universalfeatureddata-item-list {
            display: none;
        }
    }

    &-container-desktoplists {
        display: none;
    }

    &-item-title,
    &-item,
    &-item-list {
    }

    &-item {
        &-title {
            color: $darkest-grey;
            font-size: 24px;
            font-family: $primary-regular;
            line-height: 28px;
            margin-bottom: 0;
        }

        &-header {
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;
        }

        &-image {
            height: 60px;
            margin-right: 5px;
            width: 60px;
            margin-bottom: 8px;
        }

        &-link,
        &-list-link {
            @include text-link-1;

            &,
            &:visited {
                color: $darkest-grey;
            }
        }

        &-link,
        &-linktext {
            font-size: 22px;
            font-family: $primary-semibold;
            line-height: 29px;
            margin-bottom: 5px;
        }

        &-linktext,
        &-list-linktext,
        &-desc {
            color: $darkest-grey;
            margin-bottom: 0;
        }

        &-desc {
            font-size: 14px;
        }

        &-list {
            margin-bottom: 0;
            padding-left: 0;

            &-item {
                list-style: none;
            }

            &-link,
            &-linktext {
                font-size: 16px;
                font-family: $primary-bold;
                line-height: 24px;
                margin-top: 24px;
            }
        }
    }

    &-toggle {
        color: $dark-blue;
        cursor: pointer;
        line-height: 14px;
        margin-top: 50px;
        text-align: center;

        &-show,
        &-hide {
            @include text-link-2;
            cursor: pointer;
            background: none;
            border-width: 0;

            &:focus {
                outline: 0;
            }

            &::after {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                content: '';
                display: inline-block;
                height: 11px;
                margin-left: 5px;
                width: 12px;
            }
        }

        &-show {
            &::after {
                background-image: url('/Images/Base/dist/icon-plus.svg');
            }
        }

        &-hide {
            display: none;

            &::after {
                background-image: url('/Images/Base/dist/icon-minus.svg');
            }
        }
    }

    &.showAll {
        .universalfeatureddata-container-desktoplists {
            display: block;
        }

        .universalfeatureddata-toggle {
            &-show {
                display: none;
            }

            &-hide {
                display: inline;
            }
        }
    }
}

@import "featureddata-mobile";
