.universalpromobar {
    align-items: center;
    background-color: $lightest-grey;
    display: flex;
    margin-top: 104px;
    margin-bottom: 104px;
    padding: 24px;

    &-img {
        margin-right: 54px;
        max-width: 240px;
    }

    &-title {
        font-family: $primary-light;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 8px;
    }

    &-subtitle {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
    }

    &-link {
        @include text-link-3;
        display: inline-block; // IE11 bug fix
        margin-top: 24px;
    }
}

@import "promobar-mobile";
