@include max-screen(lg) {
    .textlistrollup {
        a, a:visited {
            .textbox {
                min-height: 180px;
            }
        }

        .textbox {
            min-height: 180px;
        }
    }
}

@include max-screen(md) {
    .textlistrollup {
        &-title {
            font-size: 32px;
            max-width: 100%;
        }

        a, a:visited {
            .textbox {
                min-height: 0;
            }
        }

        .textbox {
            min-height: 0;
        }
    }
}
