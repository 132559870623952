@include max-screen(md) {
    .universalbrandgriddescription {
        margin-top: 64px;
        margin-bottom: 64px;

        &-grey {
            background-color: $lightest-grey;
            padding-top: 48px;
            padding-bottom: 48px;
        }

        &-title {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 40px;
            padding-left: 32px;
            width: 85%;
        }

        &-brand {
            padding: 32px;

            @for $i from 2 to 4 {
                &.itemsPerRow-#{$i} {
                    @include content-box-flex-basis($i, 64px); // numOfItems in row, total left/right padding for item
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalbrandgriddescription {
        &-title {
            padding-left: 0;
            width: 100%;
        }

        &-brand {
            padding: 32px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }

            @for $i from 2 to 4 {
                &.itemsPerRow-#{$i} {
                    flex-basis: 100%;

                    &:nth-child(n + 2) {
                        // All but first row
                        border-top: 1px solid $lighter-grey;
                    }

                    &:nth-child(odd),
                    &:nth-child(even) {
                        // Both columns, specifically
                        border-left: 0;
                    }
                }
            }

            &-img {
                max-height: 55px;
            }
        }

        .bb {
            border-bottom: none;
        }

        .rb {
            border-right: none;
        }
    }
}
