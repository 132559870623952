// BIG Callout
.universalkeyactionbig {
    margin-top: 104px;
    margin-bottom: 104px;
    overflow: hidden;
    position: relative;

    &-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding-top: 110px;
        padding-bottom: 110px;
        position: relative;
    }

    &-img {
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &-title {
        color: $black;
        font-family: $primary-light;
        font-size: 32px;
        line-height: 43px;
        margin-bottom: 0;
        width: 50%;

        + .universalkeyactionbig-cta {
            margin-top: 46px;
        }

        + .universalkeyactionbig-link {
            margin-top: 24px;
        }
    }

    &-cta {
        @include primary-button;

        &,
        &:hover,
        &:visited {
            color: $white;
        }
    }

    &-link {
        @include text-link-2;
        font-size: 14px;
        font-family: $primary-bold;

        + .universalkeyactionbig-cta {
            margin-top: 24px;
        }

        &::after {
            content: none;
        }

        &.universalkeyactionbig-link-download {
            align-items: center;
            display: flex;
            flex-direction: row-reverse;

            &::before {
                left: 32px;
                max-width: calc(100% - 32px);
            }

            &::after {
                background-image: url('/Images/Base/dist/icon_download.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
                height: 22px;
                margin: 0 10px 0 0;
                width: 22px;
            }
        }

        &,
        &:visited {
            color: $dark-blue;
        }
    }
}

// SM Callout
.keyactionssm {
    background-color: $lightest-grey;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 80px;
    position: relative;
    margin-top: 50px;
    margin-bottom: 50px;

    .bar {
        position: absolute;
        top: 20%;
        right: 100%;
        bottom: 20%;
        left: 0;
        background-color: $yellow;
        width: 10px;
        height: 60%;
    }

    &-title {
        font-family: $primary-light;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 0;
        width: 50%;
    }

    input {
        flex-shrink: 0;
        width: 100%;
        max-width: 20%;
        margin-left: auto;
        margin-right: 10px;
    }

    .keyactionssm-form-container {
        align-items: flex-start;
        display: flex;
        margin-right: 120px;

        input {
            max-width: 100%;
        }

        a{
            flex-shrink: 0;
        }
    }

    a, a:visited {
        @include primary-button;
    }
}

@import "keyactions-mobile";
