﻿@include max-screen(sm) {
    // Hide OOB Mobile Filters
    .CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header-wrapper a, .CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header-wrapper a, .CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header-wrapper a {
        display: none;
    }

    .CoveoSearchInterface {
        .coveo-search-section {
            padding: 0;
        }

        .event-grid {
            .coveo-main-section {
                .coveo-filter-wrap {
                    width: 100%;
                    .coveo-facet-column {
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}