@include max-screen(xl) {
    .universalexpertcallout {
        padding: 48px 200px 48px 80px;
    }
}

@include max-screen(lg) {
    .universalexpertcallout {
        padding: 48px;

        &-img-container {
            height: 180px;
            margin-right: 48px;
            min-height: 180px;
            min-width: 180px;
            width: 180px;
        }
    }
}

@include max-screen(md) {
    .universalexpertcallout {
        margin-top: 64px;
        margin-bottom: 64px;
        padding: 56px 24px;

        &-img-container {
            margin-right: 36px;
        }

        &-expertinfo {
            line-height: 24px;
        }

        &-title {
            font-size: 24px;
            line-height: 32px;
        }

        &-cta {
            margin-top: 24px;
        }
    }
}

@include max-screen(sm) {
    .universalexpertcallout {
        flex-wrap: wrap;

        &-mobile-header {
            align-items: center;
            display: flex;
            margin-bottom: 24px;
        }

        &-img-container {
            height: 90px;
            margin-right: 24px;
            min-height: 90px;
            min-width: 90px;
            width: 90px;
        }

        &-expertinfo {
            &-desktop {
                display: none;
            }

            &-mobile {
                display: block;
            }
        }

        &-cta {
            display: block;
            text-align: center;
        }
    }
}
