.overlay {
    display: none;
}

// When Dropdown is showing, Brand Bar is open
body.no-scroll {
    overflow: hidden;
    position: initial;
}

header {
    position: relative;
    z-index: 200;
    width: 100%;
    background-color: $white;

    &.headroom {
        transition: all 400ms ease-in-out;
    }

    &.fixed {
        position: fixed;
        top: 0;
    }

    &.headroom--pinned {
        top: 0;
    }

    &.headroom--unpinned {
        top: -145px;
    }

    &.universalheader {
        .mobile {
            display: none;
        }

        .desktop {
            display: block;
            padding: 0;
            position: relative; // To Dropdown
            box-shadow: 0 5px 14px 0 rgba($darkest-grey, 0.08);

            .nav-bar {
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .logo {
                    img {
                        width: 125px;
                        height: 100%;
                    }
                }

                > .nav {
                    @include list-reset;
                    display: flex;
                    justify-content: space-between;
                    margin-left: auto;

                    > li {
                        font-size: 13px;
                        text-transform: uppercase;
                        font-family: $primary-bold;
                        letter-spacing: 2px;
                        color: $darkest-grey;
                        margin: 0 20px;

                        > span.nav-item-text, > a, a:visited { // Navigation Top Level Item
                            display: inline-flex;
                            align-items: center;
                            height: 100px;
                            position: relative; // For underline
                            padding: 0;
                            color: $darkest-grey;

                            &:after {
                                content: "";
                                position: absolute;
                                bottom: 0px;
                                margin: 0 auto;
                                background: none;
                            }

                            &:hover, &:focus {
                                text-decoration: none;
                                background-color: $white;
                                color: $darkest-grey;
                            }
                        }

                        &:hover, &:focus {
                            > span.nav-item-text, > a, a:visited {
                                &:after {
                                    background: linear-gradient(to right, $blue 0%, $aqua 100%) left bottom transparent no-repeat;
                                    background-size: 100% 4px;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    width: 40px;
                                }
                            }
                        }

                        &.has-dropdown {
                            cursor: pointer;

                            &:after {
                                @include add-caret-helper;
                                transform: rotate(90deg);
                                margin-left: 5px;
                            }

                            .dropdown {
                                display: none;
                                position: absolute;
                                top: 100px;
                                left: 0;
                                right: 0;
                                bottom: 0;
                                z-index: 4;
                                width: 100%;
                                height: 100%;
                                background-color: $white;
                                cursor: default;
                                background-color: $white;

                                &.show {
                                    display: block;
                                }

                                .mobile-header {
                                    display: none;
                                }

                                .dropdown-layout {
                                    background-color: $white;
                                    padding: 50px 0;
                                    box-shadow: 0 0 14px 1px rgba($darkest-grey, 0.08);

                                    .row {
                                        border-top: 1px solid $lighter-grey;
                                        padding-top: 40px;

                                        &:first-child {
                                            border-top: none;
                                            padding-top: 0;
                                        }
                                    }

                                    .dropdown-title {
                                        display: block;
                                        color: $light-grey;
                                        font-size: 14px;
                                        margin-bottom: 15px;
                                    }

                                    .dropdown-list {
                                        @include list-reset;
                                        margin-bottom: 35px;

                                        &.one-column {
                                            column-count: 1;
                                            column-gap: 0;
                                        }

                                        &.two-column {
                                            column-count: 2;
                                            column-gap: 50px;
                                        }

                                        &.three-column {
                                            column-count: 3;
                                            column-gap: 50px;
                                        }

                                        li {
                                            -webkit-column-break-inside: avoid;
                                            page-break-inside: avoid;
                                            break-inside: avoid;
                                            text-transform: none;
                                            font-size: 18px;
                                            font-family: $primary-semibold;
                                            letter-spacing: 0;
                                            margin: 0;
                                            padding: 15px 0;

                                            &:after {
                                                content: none;
                                            }

                                            a,
                                            a:visited {
                                                @include text-link-1;
                                                color: $darkest-grey;

                                                &::after {
                                                    position: absolute;
                                                    top: 50%;
                                                    transform: translateY(-50%);
                                                    right: 20px;
                                                }
                                            }
                                        }
                                    }

                                    .promo-callout {
                                        img {
                                            width: 335px;
                                            margin-bottom: 15px;
                                        }

                                        h3 {
                                            font-family: $primary-bold;
                                            font-size: 18px;
                                            color: $darkest-grey;
                                            text-transform: none;
                                            letter-spacing: 0;
                                            width: 80%;
                                        }

                                        .cta {
                                            @include text-link-2;
                                            text-transform: none;
                                            letter-spacing: 0;
                                        }
                                    }
                                    // Contact Us
                                    .contact {
                                        margin-bottom: 50px;

                                        .text, .tel {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-regular;
                                            letter-spacing: 0;
                                            font-size: 14px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .tel {
                                            font-family: $primary-bold;
                                            font-size: 16px;
                                        }

                                        .cta {
                                            @include primary-button;
                                            margin: 10px 0;
                                        }
                                    }
                                    // Social
                                    .social {
                                        margin-bottom: 60px;

                                        .title {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-semibold;
                                            letter-spacing: 0;
                                            font-size: 18px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .text {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-regular;
                                            letter-spacing: 0;
                                            font-size: 14px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        ul {
                                            @include list-reset;
                                            display: flex;

                                            li {
                                                margin-right: 40px;

                                                a, a:visited {
                                                    &:hover, &:focus {
                                                        opacity: 0.75;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    // Find a Location,
                                    .find-a-location {
                                        margin-bottom: 50px;

                                        label {
                                            display: block;
                                            color: $black;
                                            margin-bottom: 5px;
                                            font-family: $primary-semibold;
                                            letter-spacing: 0;
                                            font-size: 18px;
                                            line-height: 1.5;
                                            text-transform: none;
                                        }

                                        .field-wrap {
                                            display: flex;
                                            position: relative;

                                            input {
                                                padding-right: 50px;
                                            }

                                            .search {
                                                border: none;
                                                outline: none;
                                                background-color: transparent;
                                                position: absolute;
                                                right: 10px;
                                                top: 10px;
                                                cursor: pointer;
                                            }
                                        }
                                    }
                                    // Industries CTA (inherited li classes overrides)
                                    .heading {
                                        display: block;
                                        color: $black;
                                        margin-bottom: 5px;
                                        font-family: $primary-bold;
                                        letter-spacing: 0;
                                        font-size: 18px;
                                        line-height: 1.5;
                                        text-transform: none;
                                    }

                                    .text-link-2 {
                                        color: $blue;
                                        font-size: 16px;
                                        letter-spacing: 0;
                                        text-transform: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .search {
                    margin-left: 30px;
                }

                .how-to-buy {
                    display: none;
                }
            }

            .utilitybrandbar + .container {
                .nav-bar > .nav > li.has-dropdown .dropdown {
                    top: 145px;
                }
            }
        }

        .search-button {
            background: none;
            border: 0;
            cursor: pointer;
            transition: transform .2s ease-in-out;
            will-change: contents;

            &,
            &:focus,
            &:active {
                outline: none;
            }

            &:hover {
                transform: scale(1.2);
            }
        }

        a.how-to-buy {
            display: none;
            margin-left: 20px;
        }
    }
}

.dropdown-list-link-shortdesc {
    color: $coal-grey;
    display: block;
    font-family: $primary-light;
    font-size: 14px;
    line-height: 21px;
    margin-top: 5px;
}

@import "universalheader-mobile";
@import "_universalheadersearchoverlay";
