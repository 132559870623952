@include max-screen(md) {
    .universalbrandgridstatic {
        margin-top: 64px;
        margin-bottom: 64px;

        &-title {
            font-size: 24px;
            line-height: 32px;
            width: 85%;

            &:not(:only-child) {
                margin-bottom: 40px;
            }
        }

        &-brand {
            padding: 32px;

            @for $i from 3 to 6 {
                &.itemsPerRow-#{$i} {
                    @include content-box-flex-basis($i, 65.87px); // numOfItems in row, total left/right padding for item
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalbrandgridstatic {
        &-title {
            width: 100%;
        }

        &-brand {
            @for $i from 3 to 6 {
                &.itemsPerRow-#{$i} {
                    @include content-box-flex-basis(2, 67.5px); // numOfItems in row, total left/right padding for item
                    &:nth-child(n) {
                        border-bottom: 1px solid $lighter-grey;
                    }

                    &:nth-child(odd) {
                        // Right column
                        border-right: 1px solid $lighter-grey;
                    }

                    &:nth-child(odd) {
                        // Left column
                        border-left: 0;
                    }
                }
            }

            &-img {
                max-height: 55px;
            }
        }

        .bb {
            border-bottom: none;
        }

        .rb {
            border-right: none;
        }
    }
}
