﻿// Both Campaign Header renderings:
.campaignheader {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 108px;
    padding-bottom: 108px;

    &-title,
    &-subtitle {
        margin-bottom: 0;
        max-width: 100%;
    }

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 56px;
        width: 630px;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &-subtitle {
        font-family: $primary-regular;
        font-size: 20px;
        line-height: 30px;
        width: 555px;
    }

    &-benefitslist {
        list-style: none;
        margin: 26px 0 0 0;
        padding: 0;

        &-item {
            align-items: center;
            color: $darkest-grey;
            display: flex;
            font-family: $primary-semibold;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                content: '';
                background-image: url('../../../../../Images/Base/dist/icon-checkmark_and_circle.svg');
                height: 24px;
                margin-right: 16px;
                min-width: 24px;
                width: 24px;
            }
        }
    }

    &-cta {
        display: flex;

        &:not(:first-child) {
            margin-top: 32px;
        }

        &-input {
            border: 0;
            margin-right: 24px;
            max-width: 100%;
            padding: 18px 21px;
            width: 382px;

            &::-webkit-input-placeholder {
                color: $dark-grey;
                line-height: 18px;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: $dark-grey;
                line-height: 18px;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: $dark-grey;
                line-height: 18px;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $dark-grey;
                line-height: 18px;
            }
        }

        &-button {
            @include campaign-button;
            display: inline-block;
        }
    }
}

.campaignheader-whitetexttheme {
    .campaignheader {
        &-title,
        &-subtitle,
        &-benefitslist-item {
            color: $white;
        }

        &-benefitslist-item::before {
            background-image: url('../../../../../Images/Base/dist/icon-checkmark_and_circle_white.svg');
        }
    }
}

// Campaign Header with Benefits Bar only:
.campaignheader-benefitsbar-wrapper {
    .campaignheader {
        padding-bottom: 238px;
    }

    .benefitsbar {
        margin-top: -110px;
        padding-left: 24px;
        padding-right: 24px;

        &-container::after {
            background-color: $light-grey;
            content: '';
            display: block;
            height: 1px;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            top: 56px;
            width: calc(100% - 84px); // Minus padding from list, left and right, per designs
        }
    }
}

@import '_campaignheader-mobile';
