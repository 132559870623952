.featuredlist {
    padding: 50px 0;

    .container {
        padding: 0 100px;
    }

    &-title {
        text-align: center;
        font-family: $primary-regular;
        font-size: 28px;
        line-height: 1.2;
        margin-bottom: 40px;
    }

    .card {
        .img-wrap {
            background-color: $lightest-grey;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 320px;
            padding: 40px;

            img {
                max-width: 100%;
            }
        }

        .text-wrap {
            padding: 20px;

            &-title {
                font-family: $primary-regular;
                font-size: 20px;
                line-height: 1.3;
                color: $darkest-grey;
                margin-bottom: 10px;
            }
        }
    }
}

@import "featuredlist-mobile";