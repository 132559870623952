.universalbrandgridstatic {
    margin-top: 104px;
    margin-bottom: 104px;
    overflow: hidden;

    .bb {
        border-bottom: 1px solid $lighter-grey;
    }

    .rb {
        border-right: 1px solid $lighter-grey;
    }

    &-title {
        color: $darkest-grey;
        font-size: 40px;
        font-family: $primary-semibold;
        line-height: 56px;
        margin-bottom: 0;
        width: 80%;

        &:not(:only-child) {
            margin-bottom: 64px;
        }
    }

    &-brands {
        margin-right: 0;
        margin-left: 0;
    }

    &-brand {
        box-sizing: content-box;
        padding: 52px;

        @for $i from 3 to 6 {
            &.itemsPerRow-#{$i} {
                @include content-box-flex-basis($i, 105.8px); // numOfItems in row, total left/right padding for item                
            }
        }

        &-link {
            display: inline-block;
            height: 100%;
            width: 100%;
            will-change: contents;

            .universalbrandgridstatic-brand-img {
                transition: transform .3s ease-in-out;
            }

            &:hover {
                .universalbrandgridstatic-brand-img {
                    transform: translate(-50%, -50%) scale(1.15);
                }
            }
        }

        &-img {
            display: block;
            left: 50%;
            max-height: 70px;
            max-width: 100%;
            position: relative;
            top: 50%;
            transform: translate(-50%, -50%);
            transform-origin: center;
        }
    }
}

@import "_brandgridstatic-mobile";
