@include max-screen(lg) {
    .universalpromobar {
        &-img {
            margin-right: 42px;
        }
    }
}

@include max-screen(md) {
    .universalpromobar {
        margin-top: 64px;
        margin-bottom: 64px;

        &-img {
            margin-right: 32px;
        }

        &-title {
            font-size: 28px;
            line-height: 38px;
        }

        &-subtitle {
            font-size: 14px;
        }
    }
}

@include max-screen(sm) {
    .universalpromobar {
        display: block;
        padding: 42px 24px;

        &-img {
            margin-bottom: 16px;
            margin-right: 0;
            max-width: 100%;
        }
    }
}
