.universalanchorbar {
    &-container {
        align-items: center;
        background-color: $white;
        display: flex;
        padding-top: 8px;
        padding-bottom: 8px;
        z-index: 100;
    }

    &-anchors,
    &-anchors-listitem,
    &-anchors-overflow-showmore,
    &-button {
        flex-shrink: 0;
    }

    &-pagetitle-link,
    &-anchors-listitem-link {
        @include text-link-1;
        font-size: 16px;
        line-height: 22px;
        margin-top: 11px;
        margin-bottom: 11px;

        &::after {
            content: none;
        }
    }

    &-pagetitle {
        align-items: center;
        display: flex;
        margin-bottom: 0;

        &-link {
            color: $grey;
            font-family: $primary-regular;
        }

        &::after {
            background-color: $light-grey;
            content: '';
            height: 16px;
            margin-left: 24px;
            width: 1px;
        }
    }

    &-anchors {
        align-items: center;
        display: flex;
        margin-bottom: 0;
        padding-left: 0;

        &-nav {
            align-items: center;
            display: flex;
            flex-grow: 1;
            margin-right: 32px;
            padding-left: 24px;
        }

        &-mobiletoggle {
            display: none;
        }

        &-listitem {
            list-style: none;
            text-align: left;

            &-link,
            &-link:visited {
                color: $darkest-grey;
            }

            &-link {
                font-family: $primary-bold;
                margin-right: 40px;
            }
        }

        &-overflow {
            position: relative;

            &-showmore {
                align-items: center;
                background-color: transparent;
                border: 0;
                cursor: pointer;
                display: none;

                &.overflowButtonIsVisible {
                    display: flex;
                }

                &.overflowLinksAreVisible {
                    &::after {
                        top: 0px;
                        transform: rotate(180deg);
                    }
                }

                &,
                &:focus {
                    outline: 0;
                }

                &::after {
                    background-image: url('../../../../Images/Base/dist/dropdown_arrow.svg');
                    background-repeat: no-repeat;
                    content: '';
                    height: 8px;
                    left: 8px;
                    position: relative;
                    top: 2px;
                    transition: transform .3s ease-in-out, top .3s ease-in-out;
                    width: 11px;
                }
            }

            &-links {
                background: $white;
                left: 0;
                margin-bottom: 0;
                padding: 8px 16px;
                position: absolute;
                top: calc(100% + 32px);
                width: 250px;
                z-index: 1;

                &.showOverflowLinks {
                    display: block;
                }

                &,
                &:empty {
                    display: none;
                }

                .universalanchorbar-anchors-listitem {
                    &:not(:first-child) {
                        margin-top: 16px;
                    }

                    &-link {
                        line-height: 18px;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &-button {
        @include primary-button;
        line-height: 30px;
        margin-left: auto;
        padding: 8px 32px;

        &,
        &:visited,
        &:hover {
            color: $white;
        }
    }
}

.anchorBarIsFixed {
    .universalanchorbar {
        &-container {
            left: 50%;
            position: fixed;
            top: 0;
            transform: translateX(-50%);
            transition: box-shadow .4s;
        }

        &-anchors-overflow-links {
            box-shadow: 0px 6px 14px -1px rgba(0, 0, 0, 0.08);
        }

        &::after {
            background: $white;
            box-shadow: 0 5px 14px 0 rgba(0,0,0,0.08);
            content: '';
            height: inherit;
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }
}

.universalanchorbar-anchored-link {
    display: block;
}

@import "universalanchorbar-mobile";
