.featuredinsight {
    padding: 30px 0;

    .insight-image {
        img {
            width: 100%;
        }
    }

    .insight-content {
        padding: 40px 60px;

        .type {
            font-size: 16px;
            line-height: 1.2;
            font-family: $primary-bold;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: $darkest-grey;
            display: block;
            margin-bottom: 20px;
        }

        .tags {
            @include list-reset;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                font-size: 11px;
                line-height: 1.2;
                font-family: $primary-regular;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                color: $dark-grey;
                margin: 0 10px 20px 0;
            }
        }

        &-title {
            font-size: 32px;
            line-height: 1.3;
            font-family: $primary-regular;
            color: $darkest-grey;
            margin-bottom: 40px;
        }

        .author-byline {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 40px;

            .author-photo {
                margin-right: 15px;

                img {
                    border-radius: 50%;
                }
            }

            .author-info {
                .name {
                    font-size: 14px;
                    font-family: $primary-semibold;
                    display: block;
                }

                .title {
                    font-size: 14px;
                    font-family: $primary-regular;
                    letter-spacing: 0.1px;
                    display: block;
                }
            }
        }

        a, a:visited {
            color: inherit;
            &:hover, &:focus {
                color: inherit;
            }
        }
    }
}

@import "featuredinsight-mobile";