.homebanner {
    position: relative;

    .banner-image {
        img {
            width: 100%;
        }
    }

    .banner-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        h1 {
            font-family: $primary-semibold;
            font-size: 56px;
            line-height: 1.4;
            color: $white;
            margin-bottom: 30px;
            text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.65);
        }

        a, a:visited {
            @include primary-button;
        }
    }
}

@import "homebanner-mobile";