﻿@include max-screen(md) {
    // Campaign Header with Benefits Bar only:
    .campaignheader-benefitsbar-wrapper {
        .campaignheader {
            padding-bottom: 208px;
        }

        .benefitsbar-container::after {
            top: 48px;
            width: calc(100% - 48px); // Minus padding from list, left and right, per designs
        }
    }
}

@include max-screen(sm) {
    // Both Campaign Header renderings:
    .campaignheader {
        padding-top: 72px;
        padding-bottom: 72px;

        &-title {
            font-size: 36px;
            line-height: 50px;
        }

        &-subtitle {
            font-size: 18px;
            line-height: 28px;
        }

        &-cta {
            display: block;

            &-input,
            &-button {
                width: 100%;
            }

            &-input {
                margin-right: 0;
                margin-bottom: 16px;
            }

            &-button {
                font-size: 16px;
                text-align: center;
            }
        }
    }
    // Campaign Header with Benefits Bar only:
    .campaignheader-benefitsbar-wrapper {
        .campaignheader {
            padding-bottom: 144px;
        }

        .benefitsbar {
            margin-top: -72px;

            &-container::after {
                top: 32px;
                width: 100%;
            }
        }
    }
}
