@include max-screen(lg) {
    .genericcallout {
    
    }
}

@include max-screen(md) {
    .genericcallout {
    
    }
}

@include max-screen(sm) {
    .genericcallout {
        padding: 30px 40px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        .text {
            margin-right: 0;

            h1 {
                font-size: 28px;
            }
        }
        a, a:visited {
            @include primary-button;
            width: 100%;
            text-align: center;
            margin: 10px 0;
        }
    }
}