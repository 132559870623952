@include max-screen(sm) {
    .gatedcontent {
        .modal-content {
            .modal-header, .modal-footer {
                padding: 1.5rem 2.5rem;
            }
            .modal-body {
                padding: 0 50px;
            }
        }
    }
}