﻿.universalconvoui {
    &-prompt,
    &-prompt-resting {
        bottom: 24px;
        right: 24px;
        z-index: 1;
    }

    &-prompt {
        box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1);
        padding: 24px;
        width: 304px;

        &-eyebrow,
        &-dialog,
        &-actions button {
            font-family: $primary-regular;
        }

        &-eyebrow {
            color: $grey;
            font-size: 12px;
            line-height: 16px;
        }

        &-dialog {
            color: $black;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;

            &:not(:first-child) {
                margin-top: 5px;
            }
        }

        &-actions {
            display: flex;
            flex-wrap: wrap;

            &-btn {
                @extend %outlineButton;
                padding: 6px;
                width: 120px;
            }

            &:not(:only-child) {
                margin-top: 24px;
            }
        }

        &::before {
            background: linear-gradient(136.8deg, #00549E 0%, #3AB291 100%);
            content: '';
            display: block;
            height: 4px;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &-prompt-resting {
        &-btn {
            background: transparent;
            border: 0;
            color: $white;
            font-family: $primary-regular;
            font-size: 18px;
            line-height: 20px;
            padding: 16px;
            position: relative;

            &::before,
            &::after {
                box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            &::before {
                background: linear-gradient(136.8deg, $dark-blue 0%, $seagreen 100%);
                transition: opacity .4s;
                z-index: -1;
            }

            &::after {
                background: $dark-blue;
                z-index: -2;
            }

            &:hover {
                &::before {
                    opacity: .5;
                }
            }
        }
    }
}
