.universalexpertcallout {
    align-items: center;
    background-color: $light-blue-cool;
    display: flex;
    margin-top: 104px;
    margin-bottom: 104px;
    padding: 48px 325px 48px 80px;

    &-img-container {
        border-radius: 50%;
        height: 240px;
        min-height: 240px;
        margin-right: 90px;
        min-width: 240px;
        overflow: hidden;
        position: relative;
        width: 240px;
    }

    &-img {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
    }

    &-expertinfo {
        color: $darkest-grey;
        font-size: 16px;
        font-family: $primary-bold;
        line-height: 28px;
        margin-bottom: 0;

        &-mobile {
            display: none;
        }
    }

    &-title {
        font-family: $primary-light;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 0;
    }

    &-cta {
        @include primary-button;
        margin-top: 32px;

        &,
        &:focus,
        &:visited,
        &:hover {
            color: $white;
        }
    }
}

@import "expertcallout-mobile";
