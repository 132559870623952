.universalconvoui-sidebar-close {
    margin-bottom: 4px;
    margin-right: -16px;

    &-btn {
        align-items: center;
        background: none;
        border: none;
        color: $darkest-grey;
        cursor: pointer;
        display: flex;
        font-family: $primary-regular;
        font-size: 10px;
        letter-spacing: 0.25px;
        line-height: 14px;
        margin-left: auto;
        text-transform: uppercase;
        padding: 0;
        -webkit-appearance: none;

        &::after {
            background-color: $light-blue-cool;
            background-image: url(../../../../../Images/Base/dist/icon-close.svg);
            background-size: 9px;
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 50%;
            content: '';
            font-size: 12px;
            height: 32px;
            margin-left: 8px;
            transition: background-color .3s ease-in-out;
            width: 32px;
        }

        &:hover {
            &::after {
                background-color: darken($light-blue-cool, 5%);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .universalconvoui-sidebar-close {
        margin-right: -8px;
    }
}