﻿.infographics-sidenav-navitem {
    &-title {
        @include add-caret;
        cursor: pointer;
        font-family: $segoe-semibold;
        font-size: 16px;
        margin: 0;
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;
        width: 100%;

        &,
        &::after {
            transition: .2s color ease-out, .2s background-image ease-out;
        }

        &::after {
            margin-right: 0;
            position: absolute;
            right: 0;
            top: 53%;
            transform: translateY(-50%);
        }
    }

    &.navitem-active {
        .infographics-sidenav-navitem-title {
            &::after {
                transform: rotate(270deg);
            }
        }
    }
    // hover & active title states
    &-title:hover,
    &.navitem-active .infographics-sidenav-navitem-title {
        color: $dark-blue;

        &::after {
            background-image: url(/Images/Base/dist/icon-caret-darkblue.svg);
        }
    }

    &-details {
        > * {
            margin-bottom: 24px;
        }

        &-image-container {
            &.hasVideo {
                cursor: pointer;
                position: relative;

                &::after {
                    background-image: url(../../../../../Images/Base/dist/icon-play-button.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                    content: '';
                    display: block;
                    height: 48px;
                    left: 50%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: transform .3s;
                    width: 48px;
                }

                &:hover {
                    &::after {
                        transform: translate(-50%, -50%) scale(1.2);
                    }
                }
            }
        }

        &-link {
            display: block;
            font-family: $segoe-bold;

            &,
            &:visited {
                color: $dark-blue;
            }
        }

        &-button {
            @include primary-button;
            line-height: 30px;
            padding: 10px 16px;

            &:visited,
            &:focus,
            &:hover {
                color: $white;
            }
        }
    }
}

.infographics-leveltwo {
    .infographics-sidenav-navitem {
        border-top: 1px solid $lighter-grey;

        &:last-child {
            border-bottom: 1px solid $lighter-grey;
        }

        &-title {
            color: $dark-grey;
            line-height: 30px;

            &::after {
                margin-right: 8px;
                top: 45%;
                transform: rotate(90deg) translateX(-50%);
            }
        }
    }
}
