﻿/* -----------------------------------------------------------------------------
Import BASE
----------------------------------------------------------------------------- */
@import "../../../../../../../Foundation/Theming/code/Styles/Globals/variables";
@import "../../../../../../../Foundation/Theming/code/Styles/Globals/mixins";

body {
    font: {
        family: $primary-regular;
        size: 16px;
        weight: inherit;
    }

    color: $darkest-grey;
    overflow-x: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

a, a:visited {
    color: $darkest-grey;
}

// PARAGRAPHS & LISTS =============================================== *

p, ul, ol, table {
    font: {
        family: $primary-regular;
        size: 16px;
    }

    color: $darkest-grey;
}

// FORM =============================================== *

label {
    display: block;
    margin: 5px 0 10px 0;
    font-size: 13px;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: $primary-bold;
    color: $dark-grey;
}

input, input[type="text"], input[type="tel"], input[type="date"], textarea, input[type="password"], select {
    border: 1px solid rgba($color: $light-grey, $alpha: 1);
	background-color: $white;
	font-family: $primary-light;
    padding: 13px 15px;
    border-radius: 0;
    width: 100%;
    color: $darkest-grey;

    font: {
        size: 14px;
        family: $primary-regular;
    }

    &:focus {
        outline: none;
        color: $darkest-grey;
        border: 1px solid rgba($color: $blue, $alpha: 1);
        border-radius: 0;
    }
}

input[type="checkbox"],
input[type="radio"] {
    display: inline-block;
    margin: 0 10px 0 0;
}

// EXPERIENCE EDITOR =============================================== *

.scEmptyPlaceholder {
    width: 100%;
}