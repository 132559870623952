/* -----------------------------------------------------------------------------
BOOTSTRAP OVERRIDES, SETUP
----------------------------------------------------------------------------- */

/* LIBS ===================================================================== */
/* Bootstrap ---------------------------------------------------------------- */
// Overrides
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-breakpoints: (
// Extra small screen / phone
xs: 0,
// Small screen / phone
sm: 768px,
// Medium screen / tablet
md: 1024px,
// Large screen / desktop
lg: 1190px,
// Extra large screen / wide desktop
xl: 1400px
// Match these to $breakpoints variables
);
$container-max-widths: (
// < @576 = 100%
sm: 768px, // < @768 = 768
md: 1024px, // < @1024 = 1024
lg: 1200px, // < @1400 = 1200
xl: 1440px // max
);

/* -----------------------------------------------------------------------------
IMPORT BOOTSTRAP
----------------------------------------------------------------------------- */
// Required
@import "../../../../../node_modules/bootstrap/scss/functions";
@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/mixins";
// Optional
@import "../../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../../node_modules/bootstrap/scss/grid";
@import "../../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../../node_modules/bootstrap/scss/card";
@import "../../../../../node_modules/bootstrap/scss/modal";
@import "../../../../../node_modules/bootstrap/scss/forms";
@import "../../../../../node_modules/bootstrap/scss/carousel";
@import "../../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../../node_modules/bootstrap/scss/media";
// Bootstrap Select
@import "../../../../../node_modules/bootstrap-select/sass/bootstrap-select";