@include max-screen(xl) {
    .solutioncardlist {
        .solutioncard {
            a {
                .solutionwhitepaper {
                    height: 140px;
                }
            }
        }
    }
}

@include max-screen(lg) {
    .solutioncardlist {
        .solutioncard {
            .solutioncardimage {
                max-height: 220px;
            }

            a {
                .solutioncontent {
                    height: 245px;
                }
            }
        }
    }
}

@include max-screen(md) {
    .solutioncardlist {
        .solutioncard {
            .solutioncardimage {
                max-height: 185px;
            }

            a {
                .solutioncontent {
                    height: 190px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .solutioncardlist {
        .solutioncard {
            .solutioncardimage {
                max-height: none;
            }

            a {
                .solutioncontent {
                    height: auto;
                }

                .solutionwhitepaper {
                    height: auto;
                }
            }
        }
    }
}
