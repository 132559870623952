.universaleventbyline {
    margin-top: 42px;
    margin-bottom: 48px;

    &-row {
        align-items: flex-start;
    }

    &-options,
    &-tags,
    &-social {
        align-items: center;
        display: flex;
    }

    &-tags,
    &-social {
        list-style: none;
    }

    &-tags-item,
    &-options-title,
    &-tags-item a, &-tags-item a:visited {
        color: $darkest-grey;
        font-family: $primary-semibold;
        text-transform: uppercase;
    }

    &-tags {
        flex-wrap: wrap;
        margin-bottom: -16px;

        &-item {
            border: 1px solid $darkest-grey;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 12px;
            margin-bottom: 16px;
            padding: 4px 8px;

            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }

    &-options {
        justify-content: flex-end;

        &-title {
            font-size: 14px;
            letter-spacing: 2.15px;
            margin: 0;

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    &-social {
        margin: 0;
        padding: 0;

        &-item {
            line-height: 1;

            &:not(:last-child) {
                margin-right: 24px;
            }

            &-link {
                display: flex;

                &,
                &:visited {
                    transition: opacity .2s ease-in-out;
                }

                &-icon {
                    height: auto;
                    max-width: 18px;
                    max-height: 18px;
                    width: auto;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }
}

@import "eventbyline-mobile";
