.relatedofferings {
    padding: 80px 0;
    h2 {
        font-family: $primary-light;
        font-size: 28px;
        margin-bottom: 20px;
        text-align: center;
    }
    a, a:visited {
        &:hover, &:focus {
            text-decoration: none;
        }
    }
    .related-offering-card {
        img {
            width: 100%;
            margin: 10px 0;
        }
        h3 {
            font-family: $primary-light;
            font-size: 22px;
            margin: 0 15px 5px 15px;
        }
        p {
            font-family: $primary-light;
            font-size: 14px;
            margin: 0 15px 5px 15px;
        }
    }
}

@import "relatedofferings-mobile";