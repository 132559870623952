@include max-screen(lg) {
    .homeinpagebanner {
        .callout-wrap {
            .callout {
                width: 50%;
            }
        }
    }
}

@include max-screen(md) {
    .homeinpagebanner {
        .callout-wrap {
            .callout {
                width: 60%;
            }
        }
    }
}

@include max-screen(sm) {
    .homeinpagebanner {
        .callout-wrap {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .callout {
                padding: 40px 24px;
                width: 100%;
            }
        }
    }
}