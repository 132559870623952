﻿@include max-screen(md) {
    .benefitsbar {
        &-container {
            padding: 48px 0;
        }

        &-list {
            &-item {
                margin-left: 24px;
                margin-right: 24px;

                &-title {
                    font-size: 16px;
                    line-height: 26px;
                }

                &-subtitle {
                    font-size: 14px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .benefitsbar {
        &-container {
            padding: 32px 0;
        }

        &-list {
            display: block;

            &-item {
                max-width: calc(100% - 48px);

                & + & {
                    margin-top: 32px;
                }

                &-icon {
                    &:not(:last-child) {
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }
}