.universalinsightataglance {
    margin-top: 64px;
    margin-bottom: 40px;

    &-row {
        align-items: flex-start;
    }

    &-text {
        flex-grow: 1;
        padding-right: 86px;

        &-eyebrow,
        &-section-title {
            color: $darkest-grey;
        }

        &-eyebrow {
            font-size: 16px;
            font-family: $primary-bold;
            letter-spacing: 2px;
            line-height: 30px;
            margin-bottom: 16px;
            text-transform: uppercase;
        }

        &-section {
            & + & {
                margin-top: 32px;
            }

            &-title {
                font-size: 32px;
                font-family: $primary-regular;
                line-height: 40px;
                margin-bottom: 16px;
            }

            &-details {
                &,
                * {
                    color: $dark-grey;
                    font-size: 16px;
                    line-height: 28px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    &-callout {
        background-color: $white;
        box-shadow: 0 7px 24px 0 rgba(0,0,0,0.08);
        flex-shrink: 0;
        left: -46px;
        min-width: calc(16.66667% + 56px);
        padding: 24px 38px 38px;
        position: relative;

        &::before {
            background: linear-gradient(95.46deg, #2D72B9 0%, #2CBCB1 100%);
            bottom: 0;
            box-shadow: 0 8px 24px 0 rgba(0,0,0,0.1);
            content: '';
            left: 0;
            top: 0;
            position: absolute;
            width: 4px;
        }

        &-title,
        .universallinklist-link,
        .universallinklist-linktext {
            color: $darkest-grey;
        }

        &-title {
            font-size: 18px;
            font-family: $primary-bold;
            line-height: 31px;
            margin-bottom: 13px;
        }

        .universallinklist {
            &-item {
                + .universallinklist-item {
                    margin-top: 4px;
                }
            }

            &-link {
                @include link;
            }

            &-linktext {
                display: inline-block;
            }

            &-link,
            &-linktext {
                font-family: $primary-semibold;
                font-size: 14px;
            }
        }
    }
}

@import "insightataglance-mobile";
