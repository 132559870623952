@include max-screen(md) {
    .pagesection {
        margin-top: 64px;
        margin-bottom: 64px;

        &-title {
            font-size: 32px;
            line-height: 42px;
        }

        .blurb {
            font-size: 18px;
            line-height: 26px;
            max-width: 100%;
        }
    }
}
