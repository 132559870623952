﻿@media (max-width: 820px) {
    .site-search {
        &.CoveoSearchInterface {
            &.coveo-small-facets {
                .coveo-dropdown-header-wrapper {
                    justify-content: flex-start;
                    margin: 16px 16px 0;
                    position: relative;
                    width: calc(100% - 32px);

                    &::after {
                        background-color: $white;
                        bottom: 2px;
                        content: '';
                        left: 0;
                        height: 20px;
                        position: absolute;
                        transform: translateY(100%);
                        width: 100%;
                        //z-index: 100;
                    }

                    .coveo-facet-dropdown-header {
                        border: 0;
                        border-radius: 0;
                        display: block;
                        height: auto;
                        margin: 0;

                        p {
                            align-items: center;
                            color: $darkest-grey;
                            display: flex;
                            font-family: $primary-semibold;
                            font-size: 16px;

                            &::before {
                                content: '';
                                background-image: url('/Images/Base/dist/icon-filter.svg');
                                background-size: contain;
                                background-repeat: no-repeat;
                                width: 24px;
                                height: 22px;
                                margin-right: 8px;
                            }

                            &::after {
                                background: transparent url(/Images/Base/dist/icon-caret.svg);
                                background-repeat: no-repeat;
                                background-size: contain;
                                content: '';
                                height: 14px;
                                margin-top: 4px;
                                margin-left: 8px;
                                transform: rotate(90deg);
                                width: 10px;
                            }
                        }
                    }
                }

                .coveo-main-section {
                    .coveo-header {
                        .coveo-tab-section {
                            padding-left: 0;
                            text-align: center;
                        }
                    }

                    .coveo-results-column {
                        padding: 0 24px 40px;

                        .coveo-results-header {
                            flex-wrap: nowrap;

                            .coveo-summary-section {
                                line-height: 1;
                                margin-bottom: 0;
                            }
                        }

                        .CoveoResultList {
                            .coveo-result-list-container {
                                .coveo-card-layout.CoveoResult {
                                    .search-wrap-image {
                                        margin-left: 48px;
                                    }

                                    &:hover {
                                        .search-wrap-image {
                                            margin-left: 24px;
                                        }
                                    }
                                }
                            }
                        }

                        &.coveo-no-results {
                            .nosearchresults {
                                display: block;
                            }
                        }

                        .nosearchresults {
                            padding-top: 40px;

                            &-col {
                                padding-right: 0;

                                &:not(:last-child) {
                                    margin-bottom: 24px;
                                }

                                &-header {
                                    &:not(:only-child) {
                                        margin-bottom: 8px;
                                    }
                                }

                                &-links {
                                    &-item {
                                        &:not(:last-child) {
                                            margin-bottom: 8px;
                                        }
                                    }
                                }
                            }
                        }

                        .CoveoPager {
                            width: 100%;

                            .coveo-pager-list {
                                justify-content: center;
                            }
                        }
                    }
                }

                .coveo-facet-column {
                    background-color: $white;
                    left: 16px !important; // Override inline Coveo styles
                    margin-top: -15px;
                    width: calc(100% - 32px) !important; // Override inline Coveo styles
                    .coveo-facet-header-filter-by-container {
                        display: none !important; // Override desktop style, which overrides Coveo inline styles
                    }

                    .CoveoHierarchicalFacet,
                    .CoveoFacet {
                        padding: 0 24px 4px;

                        .coveo-facet-header {
                            border-color: $lighter-grey;
                            padding-top: 15px;
                        }
                    }
                }
            }
            &.coveo-small-tabs .coveo-tab-list * {
                height:auto;
            }

            .CoveoOmnibox.magic-box .magic-box-suggestions {
                z-index: 150;
            }
        }
    }
}

@media (max-width: 560px) {
    .site-search {
        // Custom handlebar search card template:
        .CoveoResultList .search-wrap-image {
            display: none;
        }
    }
}
