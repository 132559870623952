﻿.backgroundcolorcontainer {
    padding-top: 96px;
    padding-bottom: 96px;

    > * {
        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &:empty {
        display: none;
    }
}

@import '_backgroundcolorcontainer-mobile';
