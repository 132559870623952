.infopanel {
    background-color: $darkest-grey;
    padding: 50px 0;
    color: $white;

    .cards-wrap {
        display: flex;
        justify-content: center;
        align-items: center;

        .card {
            width: 100%;
            max-width: 600px;
            text-align: center;
            margin: 0 30px;

            img {
                margin-bottom: 10px;
            }

            h2 {
                font-family: $primary-regular;
                font-size: 21px;
                color: $white;
            }

            p {
                color: $light-grey;
            }

            &:first-child {
                margin-left: 100px;
            }

            &:last-child {
                margin-right: 100px;
            }

            &.text-card {
                .tel {
                    font-size: 21px;
                }
            }

            &.search-card {
                .input-wrap {
                    display: flex;
                    justify-content: center;
                    position: relative;

                    input {
                        width: 100%;
                        max-width: 300px;
                        margin: 10px 0;
                    }

                    .submit {
                        background-color: transparent;
                        padding: 0;
                        border: none;
                        position: absolute;
                        top: 20px;
                        right: 12%;
                        cursor: pointer;

                        img {
                            margin: 0;
                        }

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }

            &.cta-card {
                a, a:visited {
                    @include primary-button;
                    padding: 16px 20px;
                    margin: 10px 0;
                }
            }
        }
    }
}

@import "infopanel-mobile";
