@include max-screen(lg) {
    .pressreleasecallout {
    
    }
}

@include max-screen(md) {
    .pressreleasecallout {
        .pressrelease-wrap {
            margin-bottom: 50px;
        }
    }
}

@include max-screen(sm) {
    .pressreleasecallout {
    
    }
}