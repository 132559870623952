.universalfeaturedproductpanel {
    background-color: $darkest-grey;
    padding-top: 24px;
    padding-bottom: 32px;

    &-title {
        color: $white;
        font-family: $primary-light;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
    }
}

@import '_featuredproductpanel-mobile';