@include max-screen(md) {
    .universalwebinardetail {
        &-details {
            &-desc {
                h1 {
                    font-size: 28px;
                    line-height: 36px;
                    margin-top: 24px;
                    margin-bottom: 8px;
                }

                ul {
                    li {
                        &::before {
                            margin-right: 12px;
                        }
                    }
                }

                li {
                    + li {
                        margin-top: 12px;
                    }
                }

                *:not(h1) + *:not(h1) {
                    margin-top: 16px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalwebinardetail {
        &,
        &-placeholder {
            margin-top: 32px;
        }
    }
}
