.universalconvoui-question {
    margin-top: 48px;

    &-questiontext,
    &-instructions {
        color: $darkest-grey;
        font-family: $primary-regular;
    }

    &-questiontext {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 24px;
    }

    &-box {
        background-color: #F7F7F7;
        padding: 20px 24px 26px;
    }

    &-instructions {
        display: block;
        font-size: 9px;
        line-height: 13px;
        margin-bottom: 8px;
    }

    &-answeroptions {
        list-style: none;
        margin: 0;
        padding: 0;

        &-item {
            & + & {
                margin-top: 16px;
            }

            [type="radio"]:checked,
            [type="radio"]:not(:checked) {
                left: -9999px;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                z-index: -100;

                + label {
                    cursor: pointer;
                    font-family: $primary-regular;
                    font-size: 14px;
                    letter-spacing: normal;
                    line-height: 26px;
                    margin: 0;
                    text-transform: none;
                    padding-left: 18px;
                    position: relative;

                    &::before,
                    &::after {
                        border-radius: 50%;
                        content: '';
                        height: 13px;
                        top: 7px;
                        left: 0;
                        position: absolute;
                        transition: all 0.2s ease;
                        width: 13px;
                    }

                    &::before {
                        background: $white;
                        border: 1px solid $grey;
                    }
                }
            }

            [type="radio"]:not(:checked) {
                + label {
                    &:after {
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }

            [type="radio"]:checked {
                + label {
                    color: $darkest-grey;

                    &::before {
                        border-color: $dark-blue;
                    }

                    &:after {
                        background: $dark-blue;
                        opacity: 1;
                        transform: scale(.45);
                    }
                }
            }
        }
    }
}
