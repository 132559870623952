﻿.infographics-documentlink {
    align-items: center;
    display: flex;
    font-family: $segoe-semibold;
    line-height: 28px;

    &-icon {
        margin-right: 8px;
        max-width: 18px;
    }

    &:not(:last-child) {
        margin-bottom: 8px;
    }
}
