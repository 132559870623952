@include max-screen(sm) {
    .universaleventbyline {
        margin-top: 32px;

        &-tags {
            &:not(.noChildren) {
                margin-bottom: 8px;
            }
        }

        &-options {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
    }
}
