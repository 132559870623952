.universalimagetext {
    display: flex;
    margin-top: 96px;
    margin-bottom: 96px;

    &-col {
        flex-basis: 50%;
        max-width: 50%; // Firefox bug fix
    }

    &-img,
    svg,
    &-title,
    &-richtext {
        width: 100%; // IE11 bug fix
    }

    &-title,
    &-richtext p:last-child {
        margin-bottom: 0;
    }

    &-button {
        @include primary-button;
        line-height: 30px;

        &:visited,
        &:hover {
            color: $white;
        }
    }

    &-imageright {
        padding-right: 0;
    }

    &-imageleft {
        padding-left: 0;
    }

    &-var1 {
        &.universalimagetext-imageright {
            .universalimagetext-col-text {
                padding-right: 120px;
            }
        }

        &.universalimagetext-imageleft {
            .universalimagetext-col-text {
                padding-left: 120px;
            }
        }

        .universalimagetext {
            &-col-text {
                align-items: flex-start;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-title {
                color: $darkest-grey;
                font-family: $primary-semibold;
                font-size: 40px;
                line-height: 56px;

                + .universalimagetext-richtext {
                    margin-top: 12px;
                }
            }

            &-richtext {
                &,
                p {
                    color: $dark-grey;
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            &-button {
                padding: 10px 40px;

                &:not(:only-child) {
                    margin-top: 45px;
                }
            }
        }
    }

    &-var2 {
        &.universalimagetext-imageright {
            .universalimagetext-col-text {
                padding-right: 70px;
            }
        }

        &.universalimagetext-imageleft {
            .universalimagetext-col-text {
                padding-left: 70px;
            }
        }

        .universalimagetext {
            &-title {
                color: $black;
                font-family: $primary-light;
                font-size: 32px;
                line-height: 44px;

                + .universalimagetext-richtext {
                    margin-top: 24px;
                }
            }

            &-richtext {
                &,
                p {
                    color: $darkest-grey;
                    font-size: 16px;
                    line-height: 30px;
                }
            }

            &-button {
                padding: 8px 36px;

                &:not(:only-child) {
                    margin-top: 32px;
                }
            }
        }
    }

    &-wide {
        box-shadow: 0 8px 32px 0 rgba(0,0,0,0.1);
        width: calc(100% - 48px);

        &,
        .universalimagetext-col-img {
            min-height: 433px;
        }

        &.universalimagetext-imageright {
            padding-left: 0;
        }

        &.universalimagetext-imageleft {
            padding-right: 0;
        }

        &.universalimagetext-video {
            .universalimagetext-col-img::after {
                background-image: url('/Images/Base/dist/icon-play-button.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                border-radius: 50%;
                box-shadow: 0 7px 24px 0 rgba(77,77,77,0.26);
                content: '';
                height: 70px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 70px;
            }
        }

        .universalimagetext {
            &-col {
                flex-grow: 1;

                &-text {
                    align-items: flex-start;
                    display: flex;
                    flex-basis: 37%;
                    max-width: 37%; // Firefox bug fix
                    flex-direction: column;
                    justify-content: flex-start;
                    padding: 76px 48px 48px;
                }

                &-img {
                    overflow: hidden;
                    position: relative;
                }
            }

            &-eyebrow,
            &-title,
            &-subtext {
                color: $darkest-grey;

                &:last-child {
                    margin-bottom: 100px;
                }
            }

            &-eyebrow,
            &-subtext {
                line-height: 30px;
            }

            &-eyebrow {
                font-size: 16px;
                font-family: $primary-bold;
                letter-spacing: 2px;
                margin-bottom: 18px;
                text-transform: uppercase;
            }

            &-title {
                font-family: $primary-light;
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 16px;
            }

            &-subtext {
                margin-bottom: 0;
                width: 100%; // IE11 bug fix
            }

            &-link {
                @include text-link-3;
            }

            &-link,
            &-link:visited,
            &-linktext {
                color: $dark-blue;
            }

            &-link,
            &-linktext {
                align-items: flex-end;
                display: flex;
                height: 146px;
                line-height: 14px;
                margin-top: auto;
            }

            &-img,
            svg {
                height: auto;
                left: 50%;
                min-width: 100%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &-universalvideo {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: none;
        max-height:368px;
    }
}

@import "imagetext-mobile";
