.universalinsightabouttheauthor {
    margin-top: 56px;
    margin-bottom: 78px;
    width: 75%;

    &-title,
    &-desc {
        color: $darkest-grey;
        font-size: 14px;
        line-height: 24px;
    }

    &-title {
        font-family: $primary-semibold;
        letter-spacing: 2.15px;
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    &-desc {
        margin-bottom: 0;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &-cta {
        @include text-link-3;
        font-size: 16px;
        line-height: 14px;
    }
}

@import "insightabouttheauthor-mobile";