.externallinkpanel {
    padding: 100px 0;

    &.grey {
        background-color: $lightest-grey;
    }

    .externalLinkPanel-header {
        margin-bottom: 30px;

        &-title {
            font-family: $primary-semibold;
            font-size: 40px;
            line-height: 1.3;
        }
    }

    .externalLinkPanelItem {

        &-item {
            padding-top: 20px;
            padding-right: 40px;
            padding-bottom: 20px;
        }

        &-ctatext {
            font-size: 22px;
            line-height: 1.3;
        }

        &-button {
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: none;
                color: $darkest-grey;
            }

            h2 {
                @include text-link-1;
                color: $darkest-grey;

                &:after {
                    content: url("/Images/Base/dist/arrow-up-right.svg");
                    display: inline-block;
                    margin-left: 10px;
                }
            }
        }
    }
}


@import "externallinkpanel-mobile";
