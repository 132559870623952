.universalbrandbar {
    margin-top: 24px;
    margin-bottom: 24px;

    &,
    &-brands,
    .owl-stage {
        align-items: center;
        display: flex;
    }

    &-brands {
        list-style: none;
        margin: 0 36px 0 0;
        padding: 0 24px;
        overflow-x: hidden;
    }

    &-brand {
        margin: 0 10px;

        &-logo {
            margin: 0 auto;
            max-width: 80px;
            max-height: 60px;
            width: auto !important; // Overriding owl carousel img style (width 100%)
        }
    }

    &-btn {
        @include text-link-3;
        background: transparent;
        border: 0;
        cursor: pointer;
        flex-shrink: 0;
    }

    .owl-nav {
        bottom: 0;
        justify-content: space-between;
        left: 0;
        top: 0;
        position: absolute;
        right: 0;
        z-index: -1;

        &,
        > * {
            display: flex;
        }

        .owl-prev,
        .owl-next {
            align-items: center;
            background: $white;

            &,
            &::before {
                will-change: contents;
            }

            &::before {
                background-image: url('/Images/Base/dist/icon-caret.svg');
                background-repeat: no-repeat;
                background-size: 12px 18px;
                background-position: center center;
                content: '';
                display: block;
                height: 24px;
                transition: transform .3s ease-in-out;
                width: 24px;
            }

            &.disabled {
                display: none;
            }
        }

        .owl-prev {
            &::before {
                transform: rotate(180deg);
            }

            &:hover {
                &::before {
                    transform: rotate(180deg) scale(1.1);
                }
            }
        }

        .owl-next {
            margin-left: auto;

            &:hover {
                &::before {
                    transform: scale(1.1);
                }
            }
        }
    }
}

@import "_brandbar-mobile";
