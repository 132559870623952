@include max-screen(md) {
    .universallocalpagedetail {
        margin-top: 56px;
        margin-bottom: 56px;

        &-title {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px;
        }

        &-row {
            flex-wrap: nowrap;
        }
    }
}

@include max-screen(sm) {
    .universallocalpagedetail {
        margin-top: 46px;
        margin-bottom: 46px;

        &-row {
            flex-wrap: wrap;
        }

        &-col {
            & + & {
                margin-top: 16px;
            }
        }

        &-map {

            &-container {
                margin-bottom: 24px;
            }
        }
    }
}


@media screen and (max-width: 460px) {
    .universallocalpagedetail {
        &-address,
        &-contactnumbers {
            display: block;
            width: 100%;
        }

        &-hours {
            column-count: 1;
        }
    }
}