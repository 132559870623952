@include max-screen(lg) {
    .pageoverview {
        .row {
            // 3 col
            .col-md-4 {
                &:nth-child(1) {
                    padding-right: 58px;
                }

                &:nth-child(2) {
                    padding-right: 29px;
                    padding-left: 29px;
                }

                &:nth-child(3) {
                    padding-left: 58px;
                }
            }
            // 4 col
            .col-md-3 {
                &:nth-child(1) {
                    padding-right: 48px;
                }

                &:nth-child(2) {
                    padding-right: 32px;
                    padding-left: 16px;
                }


                &:nth-child(3) {
                    padding-right: 16px;
                    padding-left: 32px;
                }

                &:nth-child(4) {
                    padding-left: 48px;
                }
            }
        }
    }
}

@include max-screen(md) {
    .pageoverview {
        margin-top: 42px;
        margin-bottom: 42px;

        &-title {
            font-size: 28px;
            max-width: none;
        }

        &-text {
            max-width: 100%;
        }

        .row {
            flex-wrap: nowrap;
            // 3 col
            .col-md-4 {
                &:nth-child(1) {
                    padding-right: 32px;
                }

                &:nth-child(2) {
                    padding-right: 16px;
                    padding-left: 16px;
                }

                &:nth-child(3) {
                    padding-left: 32px;
                }
            }
            // 4 col
            .col-md-3 {
                &:nth-child(1) {
                    padding-right: 32px;
                }

                &:nth-child(2) {
                    padding-right: 22px;
                    padding-left: 10px;
                }


                &:nth-child(3) {
                    padding-right: 10px;
                    padding-left: 22px;
                }

                &:nth-child(4) {
                    padding-left: 32px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .pageoverview {
        .row {
            flex-wrap: wrap;

            .col-md-4,
            .col-md-3 {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            > * + * {
                margin-top: 16px;
            }
        }
    }
}
