.pagesection {
    margin-top: 104px;
    margin-bottom: 104px;

    &-title,
    .blurb {
        color: $darkest-grey;
    }

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 53px;
        margin-bottom: 8px;
    }

    .blurb {
        display: block;
        max-width: 60%;
        font-family: $primary-regular;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 0;
    }

    &-placeholder {
        margin-top: 48px;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        &:empty {
            display: none;
        }
    }
}

@import "pagesection-mobile";
