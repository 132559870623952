﻿.CoveoSearchInterface {
    .event-grid {
        .coveo-main-section {
            max-width: none;

            .coveo-filter-wrap {
                position: relative;
                width: 400px;

                button.filters {
                    @include add-caret;
                    position: relative;
                    border: none;
                    background-color: transparent;
                    text-transform: uppercase;
                    font-family: $primary-semibold;
                    font-size: 16px;

                    &:before {
                        content: '';
                        background-image: url('/Images/Base/dist/icon-filter.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 24px;
                        height: 22px;
                        margin-right: 8px;
                        position: relative;
                        top: 5px;
                    }

                    &:after {
                        background-position: 50% 0;
                        transform: rotate(90deg);
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus, &:active {
                        outline: none;
                    }
                }

                .coveo-facet-column {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    top: -50px;
                    right: 0;
                    bottom: 0;
                    left: -45px;
                    background-color: #ffffff;
                    width: 100%;
                    height: 820px;
                    padding: 50px;
                    overflow-y: scroll;
                    box-shadow: 0 8px 32px 0 rgba(171,171,171,0.5);

                    .facet-column-header {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .filters {
                            display: block;
                            text-transform: uppercase;
                            font-family: $primary-semibold;
                            font-size: 16px;

                            &:before {
                                content: '';
                                background-image: url(/Images/Base/dist/icon-filter.svg);
                                background-size: 100% 100%;
                                display: inline-block;
                                width: 24px;
                                height: 22px;
                                margin-right: 8px;
                                position: relative;
                                top: 5px;
                            }
                        }

                        .close {
                            img {
                                width: 20px;
                            }
                        }
                    }

                    .CoveoFacet, .CoveoFacetRange, .CoveoFacetSlider, .CoveoHierarchicalFacet {
                        margin: 20px 0;
                        border: none;
                        border-radius: 0;
                        background-color: #FFFFFF;

                        .coveo-facet-header {
                            padding: 20px 0 0 0;
                            background: #ffffff;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-top: 1px solid $lighter-grey;

                            .coveo-facet-header-title-section {
                                .coveo-facet-header-title {
                                    font-family: $primary-bold;
                                    font-size: 13px;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                    color: $darkest-grey;
                                }
                            }

                            .coveo-facet-settings-more-svg {
                                color: inherit;
                            }
                        }

                        .coveo-facet-values {
                            .coveo-facet-value.coveo-facet-selectable {
                                padding: 0;

                                &:hover {
                                    background-color: transparent;
                                }

                                .coveo-facet-value-label {
                                    display: flex;

                                    .coveo-facet-value-label-wrapper {
                                        .coveo-facet-value-checkbox {
                                            top: 0;
                                        }
                                    }
                                }

                                .coveo-facet-value-caption,
                                .coveo-facet-value-count {
                                    font-family: $primary-regular;
                                    font-size: 14px;
                                    line-height: 1.2;
                                    color: $dark-grey;
                                    text-transform: initial;
                                    letter-spacing: 0;
                                }

                                .coveo-facet-value-count {
                                    &:before {
                                        content: '(';
                                    }

                                    &:after {
                                        content: ')';
                                    }
                                }
                            }

                            .coveo-facet-value {
                                &.coveo-has-childs .coveo-has-childs-toggle {
                                    position: absolute;
                                    top: 0;
                                    left: initial;
                                    right: 0;

                                    .coveo-hierarchical-facet-expand-svg, .CoveoHierarchicalFacet ul.coveo-facet-values .coveo-facet-value.coveo-has-childs .coveo-has-childs-toggle .coveo-hierarchical-facet-collapse-svg {
                                        color: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .coveo-results-column {
                padding: 0;

                .coveo-tab-section {
                    display: none;
                }

                .coveo-results-header {
                    display: none;
                }

                .CoveoResultList {
                    margin: 0;

                    .coveo-card-layout-container {
                        margin: 20px 0 0 0;
                        border-top: 4px solid $lighter-grey;
                        height: auto !important; // Override Inline Height Style
                        .coveo-card-layout {
                            margin: 0;

                            &.CoveoResult {
                                font-family: $primary-regular;
                                font-size: inherit;
                                width: 100%;
                                margin: 0;
                                border: none;
                                border-radius: 0;

                                .event-wrap {
                                    padding: 30px 0;

                                    .tags {
                                        @include list-reset;
                                        display: flex;
                                        flex-wrap: wrap;

                                        li {
                                            border: 1px solid $dark-grey;
                                            font-size: 12px;
                                            letter-spacing: 0.5px;
                                            text-transform: uppercase;
                                            line-height: 12px;
                                            margin-bottom: 16px;
                                            padding: 4px 8px;
                                            margin-right: 10px;

                                            a, a:visited {
                                                color: $darkest-grey;
                                            }

                                            &:empty {
                                                display: none;
                                            }
                                        }
                                    }

                                    .date {
                                        display: block;
                                        font-size: 28px;
                                        font-weight: $light;
                                        line-height: 1.2;
                                        color: $black;
                                        margin: 5px 0 15px 0;
                                    }

                                    h2 {
                                        @include add-caret;
                                        font-size: 22px;
                                        line-height: 1.4;
                                        color: $darkest-grey;

                                        &:after {
                                            position: relative;
                                            bottom: 1px;
                                        }
                                    }

                                    p {
                                        font-size: 16px;
                                        line-height: 1.7;
                                        color: $dark-grey;
                                    }

                                    .location {
                                        font-size: 14px;
                                        color: $dark-grey;
                                        display: block;
                                        margin-bottom: 25px;

                                        &:before {
                                            content: '';
                                            background-image: url('/Images/Base/dist/icon-location-pin.svg');
                                            background-size: 100% 100%;
                                            display: inline-block;
                                            width: 15px;
                                            height: 16px;
                                            margin-right: 5px;
                                            position: relative;
                                            top: 3px;
                                        }

                                        &:empty {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
} 

@import "eventlistframe-mobile";
