.universalproductbar {
    position: relative;

    .owl-stage {
        align-items: flex-start;
        display: flex;

        .owl-item,
        .universalproductbar-list-item {
            width: auto;
        }
    }

    .owl-nav {
        bottom: 0;
        justify-content: space-between;
        left: -24px;
        top: 0;
        position: absolute;
        right: -24px;
        z-index: -1;

        &,
        > * {
            display: flex;
        }

        .owl-prev,
        .owl-next {
            align-items: center;

            &,
            &::before {
                will-change: contents;
            }

            &::before {
                background-image: url('/Images/Base/dist/icon-caret.svg');
                background-repeat: no-repeat;
                background-size: 12px 18px;
                background-position: center center;
                content: '';
                display: block;
                height: 24px;
                transition: transform .3s ease-in-out;
                width: 24px;
            }

            &.disabled {
                opacity: .5;
                pointer-events: none;
            }
        }

        .owl-prev {
            &::before {
                transform: rotate(180deg);
            }

            &:hover {
                &::before {
                    transform: rotate(180deg) scale(1.1);
                }
            }
        }

        .owl-next {
            &:hover {
                &::before {
                    transform: scale(1.1);
                }
            }
        }
    }

    &-list {
        display: flex;
        list-style: none;
        flex-wrap: nowrap;
        justify-content: center;
        margin: 0;
        padding: 24px 0 28px;

        &-item {
            flex-grow: 0;
            padding: 0 8px;
            width: 200px;

            &-link {
                figure {
                    margin: 0;
                }
            }

            &-link,
            &-link:visited,
            &-title {
                color: $darkest-grey;
            }

            &-img {
                display: block;
                height: 80px;
                margin: 0 auto;
                width: auto !important; // override owl library style
                &:not(:only-child) {
                    margin-bottom: 16px;
                }
            }

            &-title {
                font-family: $primary-light;
                font-size: 16px;
                letter-spacing: 0.25px;
                line-height: 22px;
                text-align: center;
            }
        }
    }
}

@import "productbar-mobile";
