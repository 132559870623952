@include max-screen(md) {
    .universalinsightabouttheauthor {
        margin-top: 32px;
        margin-bottom: 32px;
     }
}

@include max-screen(sm) {
    .universalinsightabouttheauthor {
        width: 100%;

        &-cta {
            font-size: 14px;
        }
    }
}
