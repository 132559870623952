.subscriptionlistpanel {
    padding: 100px 0;

    .subscriptionlistpanel-header {
        &-title {
            font-family: $primary-light;
            font-size: 36px;
            line-height: 1.4;
            letter-spacing: 0.3px;
            margin-bottom: 40px;
            width: 70%;
        }

        p {
            font-size: 18px;
            margin-bottom: 30px;
        }
    }

    .subscriptionListPanelItem {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 25px 35px 80px 35px;

        /* Forms - Checkboxes */
        &.form-check {
            position: relative;

            label {
                font-family: "SegoeUIRegular", Arial, sans-serif;
                font-size: 16px;
                line-height: 16px;
                margin: 0;
                padding-left: 30px;
                text-transform: initial;
                letter-spacing: 0;
                position: relative;
                left: -35px;
                z-index: 100;
            }

            input[type="checkbox"] ~ span:before,
            input[type="checkbox"] ~ span:after {
                content: "";
                display: inline-block;
                background-color: #00549E; // Checkmark
                width: 0;
                height: 0.2rem;
                position: absolute;
                transform-origin: 0% 0%;
            }

            input[type="checkbox"] {
                display: none;
            }

            input[type="checkbox"]:checked ~ span {
                border: 2px solid #00549E;
            }

            input[type="checkbox"]:checked ~ span:before {
                height: 0.15rem;
                transition: width 0.1s;
                transition-delay: 0.3s;
                width: 1rem;
            }

            input[type="checkbox"]:checked ~ span:after {
                height: 0.15rem;
                transition: width 0.1s;
                transition-delay: 0.2s;
                width: 0.4rem;
            }

            input[type="checkbox"] + label {
                cursor: pointer;
                margin-bottom: 0;
                padding-left: 28px;
                position: relative;
                z-index: 2;
            }

            input[type="checkbox"] ~ span {
                border: 1px solid #a9abac;
                display: inline-block;
                height: 18px;
                left: 0;
                position: absolute;
                top: 30px;
                transition: all 0.2s;
                width: 18px;
                z-index: 1;
            }

            input[type="checkbox"] ~ span:before {
                left: 5px;
                top: 11px;
                transform: rotate(-55deg);
            }

            input[type="checkbox"] ~ span:after {
                bottom: 6px;
                left: 3px;
                transform: rotate(35deg);
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                font-family: $primary-semibold;
                font-size: 22px;
                line-height: 1.3;
                margin-bottom: 10px;
            }

            .frequency {
                font-size: 16px;
                color: $grey;
                display: block;
                margin-bottom: 10px;
            }

            .description {
                font-size: 16px;
                line-height: 1.5;
                color: $darkest-grey;
                display: block;
                margin-bottom: 10px;
            }
        }
    }

    .subscriptionlistpanel-footer {
        input[type="text"] {
            margin-bottom: 30px;
        }

        p.small {
            font-family: $primary-regular;
            font-size: 14px;
            line-height: 1.3;
            color: $grey;
            margin-bottom: 30px;
            width: 80%;
        }

        input[type="submit"] {
            @include primary-button;
            width: auto;
        }
    }
}

@import "subscriptionlistpanel-mobile";