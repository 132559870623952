.eventdetail {
    margin-bottom: 30px;

    .date {
        font-size: 32px;
        display: block;
        margin-bottom: 10px;
    }

    .location {
        font-size: 14px;
        color: $dark-grey;
        display: block;
        margin-bottom: 25px;

        &:before {
            content: '';
            background-image: url('/Images/Base/dist/icon-location-pin.svg');
            background-size: 100% 100%;
            display: inline-block;
            width: 15px;
            height: 16px;
            margin-right: 5px;
            position: relative;
            top: 3px;
        }
    }

    p {
        font-size: 16px;
        line-height: 1.7;
        color: $dark-grey;
    }

    .cta {
        @include primary-button;
        margin: 20px 0 40px 0;
    }
}

@import "eventdetail-mobile";
