.universaldownload {
    margin-top: 40px;
    margin-bottom: 80px;

    &-col {
        > *:first-child {
            border-top: 3px solid $lighter-grey;
            padding-top: 12px;
        }
    }

    &-title,
    &-list-item-link,
    &-list-item-link:hover,
    &-list-item-link:visited,
    &-list-item-details {
        color: $darkest-grey;
    }

    &-title {
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 0;
        text-transform: uppercase;
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;
        padding-left: 0;

        &:not(:only-child) {
            padding-top: 32px;
        }

        &-item {
            flex-basis: calc((100% / 3) - 8%);

            &:nth-child(3n + 1) {
                // First item in each row
                margin-right: 8%;
            }

            &:nth-child(3n + 2) {
                // Middle item in each row
                margin-left: 4%;
                margin-right: 4%;
            }

            &:nth-child(3n + 3) {
                // Last item in each row
                margin-left: 8%;
            }

            &:nth-child(n + 4) {
                // All but first 3 (first row)
                margin-top: 24px;
            }

            &-link {
                display: flex;
                font-family: $primary-semibold;
                font-size: 16px;
                line-height: 28px;

                &::before {
                    content: '';
                    background-image: url(/Images/Base/dist/icon_document_library.svg);
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    flex-shrink: 0;
                    height: 18px;
                    margin-right: 8px;
                    top: 6px;
                    position: relative;
                    width: 16px;
                }
            }

            &-details {
                padding-left: 24px;

                &-contenttype,
                &-filetype {
                    font-size: 13px;
                    letter-spacing: 0.09px;
                    line-height: 20px;
                }

                &-filetype {
                    &:not(:only-child) {
                        &::before {
                            content: '\007C';
                            margin-left: 4px;
                            margin-right: 4px;
                            position: relative;
                            top: -1px;
                        }
                    }
                }
            }
        }
    }
}

@import "download-mobile";
