.infographics {
    align-items: flex-start;
    display: flex;
    height: 100vh;
    overflow: hidden;
    padding-top: 96px;
    padding-bottom: 96px;

    img {
        width: 100%;
    }

    &-modal {
        animation: fadeIn .3s normal forwards ease-out;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba($light-grey, .8);
        z-index: 1000;

        &::before {
            background-image: url(/Images/Base/dist/icon-close.svg);
            background-size: cover;
            background-repeat: no-repeat;
            content: '';
            cursor: pointer;
            height: 24px;
            right: 24px;
            position: absolute;
            top: 24px;
            width: 24px;
        }

        &-iframe-container {
            padding-bottom: calc(70vw * .5626);
            position: relative;
            height: 0;

            iframe {
                height: 100%;
                position: absolute;
                width: 100%;
            }
        }

        &-iframe-container,
        video {
            width: 70vw;
        }
    }
}

// Mobile kicks in
@media (max-width: 1024px) {
    .infographics {
        height: auto;
        flex-direction: column-reverse;
        padding-top: 0;
        padding-bottom: 0;

        &-modal {
            &-iframe-container {
                padding-bottom: calc((100% - 60px) * .5626);
            }

            &-iframe-container,
            video {
                width: calc(100% - 60px);
            }
        }
    }
}

// Keyframes
@keyframes loadLeft {
    from {
        transform: translateX(-650%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes loadRight {
    from {
        transform: translateX(350%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}


// Components
@import './sass-partials/sidenav/_sidenav';
@import './sass-partials/imagemap/_imagemap';

// Shared
@import './sass-partials/shared/_backbutton';
@import './sass-partials/shared/_documentlink';
