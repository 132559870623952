.upcomingevent {
    padding: 50px 0;

    &-maintitle {
        color: $darkest-grey;
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 1.3;
        margin-bottom: 50px;
    }

    a, a:visited {
        &:hover {
            text-decoration: none;

            .event-wrap-title {
                text-decoration: underline;
            }
        }
    }

    .event-wrap {
        margin-bottom: 50px;

        .tag {
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $darkest-grey;
            font-family: $primary-regular;
            display: block;
            margin-bottom: 20px;
        }

        .date {
            font-family: $primary-light;
            font-size: 28px;
            line-height: 1.2;
            color: $darkest-grey;
            display: block;
            margin-bottom: 20px;
        }

        &-title {
            @include add-caret;
            font-size: 22px;
            line-height: 1.5;
            font-family: $primary-semibold;
            color: $darkest-grey;
            margin-bottom: 10px;
        }

        p {
            font-size: 14px;
        }

        .location {
            font-size: 14px;

            &:before {
                content: '';
                background-image: url('/Images/Base/dist/icon-location-pin.svg');
                background-size: 100% 100%;
                display: inline-block;
                width: 15px;
                height: 16px;
                margin-right: 5px;
                position: relative;
                top: 3px;
            }
        }
    }
}
