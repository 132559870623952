@include max-screen(lg) {
    .casestudylist {
    }
}

@include max-screen(md) {
    .casestudylist {
        .casestudy-box {
            min-height: 0;
        }
    }
}

@include max-screen(sm) {
    .casestudylist {
    }
}
