.universalinsightdatacallout1col {
    margin-top: 56px;
    margin-bottom: 56px;

    &.showTopBorder {
        border-top: 1px solid $lighter-grey;
        padding-top:20px;
    }

    &.showBottomBorder {
        border-bottom: 1px solid $lighter-grey;
        padding-bottom:20px;
    }

    .universalinsightdatacalloutsmall {
        border: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 0;
        padding-bottom: 0;

        &-text {
            &-callout {
                &:not(:only-child) {
                    margin-right: 52px;
                }
            }

            &-large {
                font-size: 150px;
                margin-top: -30px;
            }

            &-medium {
                font-size: 75px;
                letter-spacing: 0.5px;

                &:not(:only-child) {
                    margin-left: 4px;
                }
            }

            &-small {
                &:not(:only-child) {
                    margin-left: 16px;
                }
            }
        }
    }
}

@import "insightdatacallout1column-mobile";
