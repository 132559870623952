﻿@include max-screen(md) {
    // Hide OOB Mobile Filters
    .CoveoSearchInterface.coveo-small-tabs .coveo-dropdown-header-wrapper a, .CoveoSearchInterface.coveo-small-facets .coveo-dropdown-header-wrapper a, .CoveoSearchInterface.coveo-small-recommendation .coveo-dropdown-header-wrapper a {
        display: none;
    }

    .CoveoSearchInterface {
        .insight-grid {
            .coveo-main-section {
                .coveo-filter-wrap {
                    width: 100%;

                    .coveo-facet-column {
                        top: 0;
                        left: 0;
                    }
                }

                .coveo-results-column {
                    .CoveoResultList {
                        .coveo-card-layout-container {
                            &.grid {
                                .grid-sizer {
                                    width: 50%;
                                }
                            }

                            .coveo-card-layout {
                                &.CoveoResult {
                                    &.grid-item {
                                        width: 50%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {

    .CoveoSearchInterface {
        .coveo-search-section {
            padding: 0;
        }

        .insight-grid {
            .coveo-main-section {
                .coveo-filter-wrap {
                    width: 100%;

                    .coveo-facet-column {
                        top: 0;
                        left: 0;
                    }
                }

                .coveo-results-column {
                    .CoveoResultList {
                        .coveo-card-layout-container {
                            &.grid {
                                .grid-sizer {
                                    width: 100%;
                                }
                            }

                            .coveo-card-layout {
                                &.CoveoResult {    
                                    &.grid-item {
                                        width: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
