.universalfooter {
    padding: 60px 0 0 0;
    background: linear-gradient(to left, $blue 0%, $aqua 100%) left top transparent no-repeat;
    background-size: 100% 8px;
    position: relative;
    z-index: 0;
    margin-top: 80px;

    &.no-gradient {
        background: none;
    }

    &.grey {
        background-color: $lightest-grey;
    }

    .cols-wrap {
        padding: 0 70px;
    }

    .col-wrap-border {
        padding: 0 70px;
        border-right: 1px solid $lightest-grey;
    }

    .col-wrap {
        padding: 0 70px;
    }

    .title {
        display: block;
        margin: 5px 0 15px 0;
        font-size: 16px;
        line-height: 1.2;
        text-transform: uppercase;
        font-family: $primary-bold;
        color: $darkest-grey;

        &.lowercase {
            text-transform: lowercase;
        }
    }

    label {
        &.lowercase {
            text-transform: lowercase;
            letter-spacing: 0;
            font-size: 16px;
            color: $darkest-grey;
            margin: 5px 0 15px 0;
        }
    }

    .text {
        display: block;
        color: $black;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 1.5;
    }

    .cta-link {
        @include text-link-2;
        margin: 15px 0;
    }
    // Contact Us
    .contact {
        margin-bottom: 50px;

        .text, .tel {
            display: block;
            color: $black;
            margin-bottom: 5px;
        }

        .tel {
            font-family: $primary-bold;
            font-size: 16px;
        }

        .cta {
            @include primary-button;
            margin: 10px 0;
        }
    }
    // Find a Location, Find a Partner
    .find-a-location, .find-a-partner {
        margin-bottom: 50px;

        .field-wrap {
            display: flex;
            position: relative;

            input {
                padding-right: 50px;
            }

            .search {
                border: none;
                outline: none;
                background-color: transparent;
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
            }
        }
    }

    .find-a-partner {
        .field-wrap {
            input {
                padding-right: 15px;
                padding-left: 50px;
            }

            .search {
                left: 10px;
                right: initial;
            }
        }
    }
    // Subscribe
    .subscribe {
        margin-bottom: 50px;

        p {
            font-size: 14px;
        }

        .field-wrap {
            display: flex;

            input {
                margin-right: 10px;
            }

            .cta {
                @include primary-button;
                min-width: 120px;
            }
        }
    }
    // Social
    .social {
        margin-bottom: 60px;

        .social-icons {
            display: flex;
            ul {
                @include list-reset;
                display: flex;

                li {
                    margin-right: 40px;

                    a, a:visited {
                        &:hover, &:focus {
                            opacity: 0.75;
                        }
                    }
                }
            }
        }
    }
    // Link List
    .link-list {
        &.mobile {
            display: none;
        }

        &.desktop {
            display: block;
        }

        margin-bottom: 40px;

        ul {
            @include list-reset;
            margin-bottom: 20px;

            li {
                margin: 10px 0;

                a, a:visited {
                    font-size: 14px;
                    color: $black;
                    line-height: 1.8;
                    display: block;
                }
            }
        }
    }
    // Footer Nav
    .footer-nav {
        img {
            width: 160px; // set logo width
            margin-bottom: 20px;
        }

        .links {
            @include list-reset;
            margin-bottom: 20px;

            li {
                margin: 5px 0;

                a, a:visited {
                    font-size: 14px;
                    color: $dark-blue;
                    font-family: $primary-semibold;
                    line-height: 1.5;
                    display: block;
                }
            }
        }
    }
    // Logo Column
    .logo-column {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        width: 100%;
        height: 100%;
        padding-bottom: 40px;

        img {
            width: 125px; // set logo width
            margin-bottom: 20px;
        }
    }

    .footer-basement {
        background-color: $lightest-grey;
        padding: 10px 0;

        .content-wrap {
            display: flex;
            justify-content: space-between;

            .legal {
                font-size: 12px;
                line-height: 1.5;
                display: block;
                color: $darkest-grey;
            }

            .links {
                @include list-reset;
                display: flex;

                li {
                    margin-right: 10px;

                    a, a:visited {
                        font-size: 12px;
                        line-height: 1.5;
                        display: block;
                        color: $darkest-grey;

                        &:after {
                            content: '|';
                            margin-left: 10px;
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        a, a:visited {
                            &:after {
                                content: '';
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@import "universalfooter-mobile";
