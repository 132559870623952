@include max-screen(lg) {
    .universalimagetext {
        &-var1 {
            &.universalimagetext-imageright {
                .universalimagetext-col-text {
                    padding-right: 64px;
                }
            }

            &.universalimagetext-imageleft {
                .universalimagetext-col-text {
                    padding-left: 64px;
                }
            }

            .universalimagetext {
                &-title {
                    font-size: 36px;
                    line-height: 46px;
                }

                &-richtext {
                    &,
                    p {
                        font-size: 18px;
                    }
                }

                &-button:not(:only-child) {
                    margin-top: 30px;
                }
            }
        }

        &-var2 {
            &.universalimagetext-imageright {
                .universalimagetext-col-text {
                    padding-right: 56px;
                }
            }

            &.universalimagetext-imageleft {
                .universalimagetext-col-text {
                    padding-left: 56px;
                }
            }
        }
    }
}

@include max-screen(md) {
    .universalimagetext {
        margin-top: 64px;
        margin-bottom: 64px;

        &-var1,
        &-var2 {
            &.universalimagetext-imageright {
                .universalimagetext-col-text {
                    padding-right: 36px;
                }
            }

            &.universalimagetext-imageleft {
                .universalimagetext-col-text {
                    padding-left: 36px;
                }
            }

            .universalimagetext {
                &-button:not(:only-child) {
                    margin-top: 24px;
                }
            }
        }

        &-var1 {
            .universalimagetext {
                &-title {
                    font-size: 32px;
                    line-height: 42px;

                    + .universalimagetext-richtext {
                        margin-top: 8px;
                    }
                }

                &-richtext {
                    &,
                    p {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }

        &-var2 {
            .universalimagetext {
                &-title {
                    font-size: 28px;
                    line-height: 42px;

                    + .universalimagetext-richtext {
                        margin-top: 18px;
                    }
                }

                &-richtext {
                    &,
                    p {
                        line-height: 28px;
                    }
                }
            }
        }

        &-wide {
            &,
            .universalimagetext-col-img {
                min-height: 320px;
            }

            .universalimagetext {
                &-col-text {
                    padding: 48px 36px 36px;
                }

                &-eyebrow,
                &-title,
                &-subtext {
                    &:last-child {
                        margin-bottom: 50px;
                    }
                }

                &-eyebrow {
                    font-size: 14px;
                    line-height: 28px;
                    margin-bottom: 14px;
                }

                &-title {
                    font-size: 28px;
                    line-height: 38px;
                }

                &-subtext {
                    line-height: 28px;
                }

                &-link,
                &-linktext {
                    height: 100px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalimagetext {
        padding-left: 0;
        padding-right: 0;

        &-imageright {
            flex-direction: column-reverse;
        }

        &-imageleft {
            flex-direction: column;
        }

        &-var1,
        &-var2,
        &-wide {
            .universalimagetext-col {
                flex-basis: auto; // IE11 bug fix
                max-width: 100%; // Firefox bug fix
                overflow: hidden; // IE11 bug fix
                .youtube-video-control {
                    margin: 0px;
                }
            }
        }

        &-var1,
        &-var2 {
            &.universalimagetext-imageright,
            &.universalimagetext-imageleft {
                .universalimagetext-col-text {
                    padding-right: 24px; // match .container class
                    padding-left: 24px; // match .container class
                    padding-top: 16px;
                }
            }
        }

        &-var2 {
            .universalimagetext {
                &-title {
                    font-size: 26px;
                    line-height: 40px;

                    + .universalimagetext-richtext {
                        margin-top: 16px;
                    }
                }

                &-richtext {
                    &,
                    p {
                        line-height: 26px;
                    }
                }
            }
        }

        &-wide {
            &,
            .universalimagetext-col-img {
                min-height: 0;
            }

            .universalimagetext {
                &-col-text {
                    padding: 36px 24px;
                }

                &-eyebrow,
                &-title,
                &-subtext {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &-title {
                    font-size: 30px;
                }

                &-subtext {
                    font-size: 16px;
                }

                &-img,
                svg {
                    left: 0;
                    position: relative;
                    top: 0;
                    transform: translate(0, 0);
                    width: 100%;
                }
            }
        }
    }
}
