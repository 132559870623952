.universalinsightdatacalloutsmall {
    border-top: 1px solid $lighter-grey;
    border-bottom: 1px solid $lighter-grey;
    margin-top: 46px;
    margin-bottom: 46px;
    padding-top: 46px;
    padding-bottom: 46px;

    &,
    &-text-callout {
        align-items: center;
        display: flex;
    }

    &-text {
        &-callout,
        &-small {
            margin-bottom: 0;
        }

        &-callout {
            flex-shrink: 0;
            line-height: 1;

            &:not(:only-child) {
                margin-right: 16px;
            }
        }

        &-large,
        &-medium {
            color: $dark-blue;
            font-family: $primary-light;
        }

        &-large {
            font-size: 100px;
            margin-top: -16px;
            margin-bottom: -8px;
        }

        &-medium {
            font-size: 34px;
        }

        &-small {
            color: $darkest-grey;
            font-size: 16px;
            line-height: 28px;

            &:not(:only-child) {
                margin-left: 24px;
            }
        }
    }

    &-icon {
        flex-shrink: 0;
        width: 40px;
    }
}

@import "insightdatasmall-mobile";
