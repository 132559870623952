.universalfeaturedproduct {
    align-items: flex-start;
    display: flex;
    margin-top: 16px;

    &-img-container {
        flex-shrink: 0;
        height: 192px;
        margin-right: 24px;
        position: relative;
        overflow: hidden;
        width: 288px;
    }

    &-img {
        height: auto;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        width: auto;
    }

    &-title,
    &-desc {
        color: $white;
        margin-bottom: 0;
    }

    &-title {
        font-family: $primary-regular;
        font-size: 20px;
        line-height: 25px;

        &:not(:only-child) {
            margin-bottom: 8px;
        }
    }

    &-desc {
        font-family: $primary-light;
        font-size: 16px;
        line-height: 24px;
    }

    &-cta {
        @include primary-button;

        &:not(:only-child) {
            margin-top: 16px;
        }
    }
}

@import "featuredproduct-mobile";