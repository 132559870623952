.universallinklist {
    margin-bottom: 0;
    padding: 0;

    &-item {
        list-style: none;
    }

    &-link,
    &-linktext {
        font-size: 16px;
        font-family: $primary-bold;
        line-height: 24px;
        margin-bottom: 0;
    }
}
