﻿@include max-screen(lg) {
    .universallocationfindercallout {
        &-keyaction {
            &-text {
                font-size: 34px;
                line-height: 46px;
            }
        }
    }
}

@include max-screen(md) {
    .universallocationfindercallout {
        &-keyaction {
            margin-top: 64px;
            margin-bottom: 64px;
            padding: 36px;
        }
    }
}

@include max-screen(sm) {
    .universallocationfindercallout {
        &-partner,
        &-keyaction {
            .location-input-container {
                display: block;

                .location-input-wrapper {
                    .location-input {
                        font-size: 16px;
                    }
                }

                .find-locations-button {
                    margin-left: 0;
                    margin-top: 16px;
                }
            }
        }

        &-partner {
            box-shadow: none;
            padding: 32px 24px;

            &-title {
                margin-bottom: 24px;
            }
        }

        &-keyaction {
            padding: 32px 32px 32px 42px;

            &-row {
                flex-wrap: wrap;
            }

            &-text {
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 24px;
            }
        }
    }
}