.featuredevent {
    padding: 30px 0;

    .event-image {
        img {
            width: 100%;
        }
    }

    .event-content {
        padding: 40px 60px;

        .type {
            font-size: 16px;
            line-height: 1.2;
            font-family: $primary-bold;
            text-transform: uppercase;
            letter-spacing: 1.4px;
            color: $darkest-grey;
            display: block;
            margin-bottom: 20px;
        }

        .tags {
            @include list-reset;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                font-size: 11px;
                line-height: 1.2;
                font-family: $primary-regular;
                text-transform: uppercase;
                letter-spacing: 0.2px;
                color: $dark-grey;
                margin: 0 10px 20px 0;
            }
        }

        &-title {
            font-size: 32px;
            line-height: 1.3;
            font-family: $primary-regular;
            color: $darkest-grey;
            margin-bottom: 40px;
        }

        .date {
            font-family: $primary-bold;
            font-size: 18px;
            line-height: 1.2;
            color: $darkest-grey;
            display: block;
            margin-bottom: 40px;
        }

        a, a:visited {
            color: inherit;

            &:hover, &:focus {
                color: inherit;
            }
        }
    }
}

@import "featuredevent-mobile";
