.universaljumboheader {
    padding-bottom: 146px;

    &-cta {
        @include primary-button;

        &,
        &:focus,
        &:hover,
        &:visited {
            color: $white;
        }

        &:hover,
        &:focus {
            background-color: $dark-blue;
        }
    }
}

@import "jumboheader-mobile";