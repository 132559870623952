@include max-screen(lg) {
    .brands-display {
        .brand-item:not(.close) {
            .brand-itemContent {
                top: 0; // IE11 bug fix
                top: initial;
            }
        }
    }
}

@include max-screen(md) {
    .utility-brand-bar-logo-container {
        display: none;
    }

    .brands-display {
        border-bottom: 0;

        &,
        .container {
            padding: 0;
        }

        .container {
            max-width: 100%;
        }

        .utilitybrandbar-wrap {
            flex-direction: column-reverse;

            .brand-wrap {
                padding: 24px;
            }
        }

        .brand-item {
            &,
            .brand-itemHeading {
                width: 100%;
            }

            .brand-itemHeading {
                font-size: 16px;
                transition: 0s;

                &.has-dropdown {
                    &::after {
                        margin-left: auto;
                    }
                }
            }

            .brand-itemContent {
                .utility-content {
                    display: none;
                }
            }
        }

        .brand-item:not(.close) {
            .brand-itemHeading {
                color: $dark-blue;

                &.utility::before {
                    content: url('/Images/Base/dist/icon-globe-blue.svg');
                }

                &::after {
                    content: url('/Images/Base/dist/icon-caret-darkblue.svg');
                }
            }

            .brand-itemContent {
                position: relative;
                overflow-x: hidden;
                overflow-y: auto;

                .utility-content,
                .brands-content {
                    padding-top: 45px;
                    padding-bottom: 0;
                }

                .utility-content {
                    &-title {
                        font-family: $segoe-bold;
                        font-size: 24px;
                        text-transform: uppercase;
                    }

                    .open.subbrand-item {
                        .subbrand-itemContent {
                            .country-list {
                                column-count: 3;
                            }
                        }
                    }
                }

                .brands-content {
                    .title {
                        font-size: 14px;
                    }

                    ul {
                        column-count: 3;
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .brands-display {
        .brand-item:not(.close) {
            .brand-itemContent {
               .brands-content {
                    ul {
                        column-count: 2;
                    }
                }
            }
        }
    }
}

