.universalinsightheader {
    background-color: $dark-blue;
    margin-bottom: 32px;

    &-container {
        padding-top: 72px;
        padding-bottom: 72px;
    }

    &-eyebrow,
    &-title {
        color: $white;
    }

    &-eyebrow {
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        text-transform: uppercase;

        &:not(:only-child) {
            margin-bottom: 8px;
        }
    }

    &-title {
        font-family: $primary-semibold;
        font-size: 48px;
        line-height: 56px;
        margin-bottom: 0;
    }
}

@import "insightheader-mobile";