@include max-screen(md) {
    .universalheaderleftpanel { 
        padding-top: 0;
        padding-bottom: 0;

        &-header,
        &-placeholder {
            padding: 30px;
        }

        &-container {
            display: block;
        }

        &-header {
            width: 100%;
        }

        &-eyebrow {
            font-size: 12px;
            line-height: 24px;
        }

        &-copy {
            font-size: 32px;
            line-height: 42px;
        }

        &-placeholder {
            margin: 0;
        }
    }
}

@include max-screen(sm) {
    .universalheaderleftpanel {
        margin-top: 64px;
        margin-bottom: 64px;

        &-header,
        &-placeholder {
            padding: 30px 24px;
        }
    }
}
