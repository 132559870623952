﻿.universalglobalalert {
    background-color: $lightest-grey;
    padding-bottom: 24px;
    padding-top: 24px;
    position: relative;
    z-index: 3;

    &::before {
        bottom: 16px;
        content: '';
        background-color: $fireengine-red;
        left: 0;
        position: absolute;
        top: 16px;
        width: 8px;
    }

    &-container {
        position: relative;
    }

    &-message {
        padding-right: 32px;

        * {
            margin-bottom: 0;
        }

        span {
            display: block;
        }

        &,
        * {
            color: $darkest-grey;
            font-size: 14px;
            font-family: $primary-semibold;
            line-height: 26px;
        }

        a {
            @include text-link-3;
            display: inline-block;
            line-height: initial;
        }

        p:last-child > a,
        a:not(:only-child) {
            margin-top: 8px;
        }
    }

    &-close {
        background: none;
        border: 0;
        cursor: pointer;
        line-height: 1;
        padding: 0;
        position: absolute;
        top: 0;
        right: 24px;
        will-change: contents;

        &,
        &:active,
        &:focus {
            outline: none;
        }

        &-icon {
            height: 14px;
            transition: transform .3s ease-in-out;
            width: 14px;
        }

        &:hover {
            .universalglobalalert-close-icon {
                transform: scale(1.2);
            }
        }
    }
}
