﻿.CoveoSearchInterface {
    .insight-grid {
        background-color: $lightest-grey;
        padding: 30px 0 0 0;
        margin-top: 50px;

        .coveo-main-section, .coveo-search-section {
            max-width: none;
            margin: 0;

            .coveo-filter-wrap {
                position: relative;
                width: 400px;

                button.filters {
                    @include add-caret;
                    position: relative;
                    border: none;
                    background-color: transparent;
                    text-transform: uppercase;
                    font-family: $primary-semibold;
                    font-size: 16px;

                    &:before {
                        content: '';
                        background-image: url('/Images/Base/dist/icon-filter.svg');
                        background-size: 100% 100%;
                        display: inline-block;
                        width: 24px;
                        height: 22px;
                        margin-right: 8px;
                        position: relative;
                        top: 5px;
                    }

                    &:after {
                        background-position: 50% 0;
                        transform: rotate(90deg);
                    }

                    &:hover {
                        cursor: pointer;
                    }

                    &:focus, &:active {
                        outline: none;
                    }
                }

                .coveo-facet-column {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    top: -50px;
                    right: 0;
                    bottom: 0;
                    left: -45px;
                    background-color: #ffffff;
                    width: 100%;
                    height: 820px;
                    padding: 50px;
                    overflow-y: scroll;
                    box-shadow: 0 8px 32px 0 rgba(171,171,171,0.5);

                    .facet-column-header {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        .filters {
                            display: block;
                            text-transform: uppercase;
                            font-family: $primary-semibold;
                            font-size: 16px;

                            &:before {
                                content: '';
                                background-image: url(/Images/Base/dist/icon-filter.svg);
                                background-size: 100% 100%;
                                display: inline-block;
                                width: 24px;
                                height: 22px;
                                margin-right: 8px;
                                position: relative;
                                top: 5px;
                            }
                        }

                        .close {
                            img {
                                width: 20px;
                            }
                        }
                    }

                    .CoveoFacet, .CoveoFacetRange, .CoveoFacetSlider, .CoveoHierarchicalFacet {
                        margin: 20px 0;
                        border: none;
                        border-radius: 0;
                        background-color: #FFFFFF;

                        .coveo-facet-header {
                            padding: 20px 0 0 0;
                            background: #ffffff;
                            border-top-left-radius: 0;
                            border-top-right-radius: 0;
                            border-top: 1px solid $lighter-grey;

                            .coveo-facet-header-title-section {
                                .coveo-facet-header-title {
                                    font-family: $primary-bold;
                                    font-size: 13px;
                                    letter-spacing: 0.5px;
                                    text-transform: uppercase;
                                    color: $darkest-grey;
                                }
                            }

                            .coveo-facet-settings-more-svg {
                                color: inherit;
                            }
                        }

                        .coveo-facet-values {
                            .coveo-facet-value.coveo-facet-selectable {
                                padding: 0;

                                &:hover {
                                    background-color: transparent;
                                }

                                .coveo-facet-value-label {
                                    display: flex;

                                    .coveo-facet-value-label-wrapper {
                                        .coveo-facet-value-checkbox {
                                            top: 0;
                                        }
                                    }
                                }

                                .coveo-facet-value-caption,
                                .coveo-facet-value-count {
                                    font-family: $primary-regular;
                                    font-size: 14px;
                                    line-height: 1.2;
                                    color: $dark-grey;
                                    text-transform: initial;
                                    letter-spacing: 0;
                                }

                                .coveo-facet-value-count {
                                    &:before {
                                        content: '(';
                                    }

                                    &:after {
                                        content: ')';
                                    }
                                }
                            }

                            .coveo-facet-value {
                                &.coveo-has-childs .coveo-has-childs-toggle {
                                    position: absolute;
                                    top: 0;
                                    left: initial;
                                    right: 0;

                                    .coveo-hierarchical-facet-expand-svg, .CoveoHierarchicalFacet ul.coveo-facet-values .coveo-facet-value.coveo-has-childs .coveo-has-childs-toggle .coveo-hierarchical-facet-collapse-svg {
                                        color: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .coveo-results-column {
                padding: 0;

                .coveo-tab-section {
                    display: none;
                }

                .coveo-results-header {
                    display: none;
                }

                .CoveoResultList {
                    margin: 0;

                    .coveo-card-layout-container {
                        margin: 20px 0 0 0;
                        border-top: 4px solid $lighter-grey;

                        &.grid {
                            max-width: 100%;
                            margin-bottom: 50px;

                            .grid-sizer {
                                width: 33.333%
                            }
                        }

                        .coveo-card-layout {
                            margin: 0;

                            &.CoveoResult {
                                font-family: $primary-regular;
                                font-size: inherit;
                                width: 100%;
                                margin: 0;
                                border: none;
                                border-radius: 0;


                                &.grid-item {
                                    a, a:visited, a:hover, a:focus {
                                        text-decoration: none;
                                    }

                                    width: 33.333%;
                                    margin-top: 20px;
                                    padding: 0 1%;
                                    box-sizing: border-box;

                                    .insight-tile {
                                        box-shadow: 0 15px 32px 0 rgba(0,0,0,0.07);
                                        display: block;
                                        text-decoration: none;
                                        min-width: 100%;

                                        .tile-image {
                                            width: 100%;

                                            img {
                                                width: 100%;
                                                max-height: 300px;
                                                min-height: 300px;
                                            }
                                        }

                                        .tile-content {
                                            padding: 40px 30px;

                                            .tags {
                                                @include list-reset;
                                                display: flex;
                                                justify-content: flex-start;
                                                flex-wrap: wrap;

                                                li {
                                                    font-size: 12px;
                                                    line-height: 1.1;
                                                    color: $darkest-grey;
                                                    text-transform: uppercase;
                                                    border: 1px solid $light-grey;
                                                    padding: 3px 5px;
                                                    margin: 0 15px 10px 0;

                                                    a, a:visited {
                                                        color: $darkest-grey;
                                                    }

                                                    &:empty {
                                                        display: none;
                                                    }
                                                }
                                            }

                                            h2 {
                                                @include add-caret;
                                                font-size: 22px;
                                                line-height: 1.4;
                                            }
                                        }

                                        &.white {
                                            background-color: $white;

                                            a {
                                                color: $black;
                                            }

                                            .tile-content {
                                                ul li a {
                                                    color: $black;
                                                }
                                            }
                                        }

                                        &.black,
                                        &.blue {
                                            .tile-content {
                                                ul li a, h2 a, .tags li a, .tags li a:visited {
                                                    color: $white;
                                                }

                                                h2 {
                                                    @include add-caret($white);
                                                    font-size: 22px;
                                                    line-height: 1.4;
                                                }
                                            }
                                        }

                                        &.black {
                                            background-color: $black;
                                        }

                                        &.blue {
                                            background-color: $blue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



@import "insightlistframe-mobile";
