.universalconvoui {
    position: relative;
    z-index: 1000;
    // Shared
    %outlineButton {
        background: $white;
        border: 1px solid $dark-blue;
        color: $dark-grey;
        font-size: 14px;
        line-height: 19px;
        transition: .2s ease-out;

        &:hover {
            background: $dark-blue;
            color: $white;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }
    }

    button {
        cursor: pointer;

        &,
        &:focus {
            outline: none;
        }
    }

    &-prompt,
    &-prompt-resting,
    &-sidebar {
        position: fixed;
    }

    &-prompt,
    &-sidebar {
        background-color: $white;
    }

    &-sidebar {
        bottom: 0;
        box-shadow: 0 2px 40px 0 rgba(0,0,0,0.15);
        max-height: 100%;
        max-width: 100%;
        overflow-y: auto;
        padding: 24px 48px 0;
        right: 0;
        transform: translateX(100%) translateX(40px);
        transition: transform .3s ease-out;
        top: 0;
        width: 416px;
        z-index: 1001;

        &-panels {
            margin-top: 48px;
            padding-bottom: 32px; // IE11 fix
        }

        &.showSidebar {
            transform: translateX(0);

            &::-webkit-scrollbar {
                background-color: $white;
                box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
                width: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $dark-blue;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .universalconvoui {
        &-sidebar {
            padding: 24px 24px 32px;
            width: 100%;
        }
    }
}


// Shared components
@import './sass-partials/_close';
@import './sass-partials/_reset';
@import './sass-partials/_nav';
@import './sass-partials/_question.scss';
@import './sass-partials/_contentitem';

// Prompts
@import './sass-partials/_prompt';

// Panels
@import './sass-partials/_assistant';
@import './sass-partials/_contact';
@import './sass-partials/_recent.scss';
