﻿.business-questions-block-and-form {
    margin-top: 50px;

    .inactive,
    .btn-cancel,
    .btn-save,
    #divspan_contact_ValidationMessageLabel,
    #divspan_contact_SuccessMessageLabel {
        display: none;
    }

    .active {
        display: block;
    }

    .selected-option {
        display: block;
        &.active {
            display: none;
        }
    }

    .form-group {
        input {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .messages,
    .alert {
        display: none;

        &.active {
            display: block;
        }
    }
}

@import "businessquestionsblockandform-mobile";