﻿.campaignfooter {
    padding-top: 24px;

    &-row {
        @extend .row;
        align-items: center;
        margin-bottom: 16px;
    }

    &-col {
        @extend .col-sm-6;
    }

    &-logo {
        max-width: 100%;
        width: 150px;
    }

    &-social {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        &-link {
            transition: opacity .3s;

            & + & {
                margin-left: 24px;
            }

            &:hover {
                opacity: .8;
            }
        }
    }

    &-copyright,
    &-ctatext {
        &,
        * {
            color: $coal-grey;
            font-family: $primary-regular;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &-ctatext {
        text-align: right;

        a {
            &,
            &:visited {
                color: $dark-blue;
            }
        }
    }
}

@import '_campaignfooter-mobile';
