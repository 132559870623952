.pageoverview {
    margin-top: 56px;
    margin-bottom: 56px;

    &-title {
        font-family: $primary-regular;
        font-size: 36px;
        line-height: 1.3;
        margin-bottom: 20px;
        max-width: 100%;

        a, a:visited {
            &:hover, &:focus {
                color: $black;
                text-decoration: none;
                opacity: 0.5;
            }
        }
    }

    &-text {
        font-family: $primary-regular;
        font-size: 16px;
        line-height: 2;
        margin-bottom: 0;
        max-width: 100%;

        &:not(:only-child) {
            margin-bottom: 16px;
        }

        a, a:visited {
            @include link;
            padding-bottom: 3px;
        }
    }

    .row {
        + .row {
            margin-top: 16px;
        }
        // 3 col
        .col-md-4 {
            &:nth-child(1) {
                padding-right: 70px;
            }

            &:nth-child(2) {
                padding-right: 34px;
                padding-left: 34px;
            }

            &:nth-child(3) {
                padding-left: 70px;
            }
        }
        // 4 col
        .col-md-3 {
            &:nth-child(1) {
                padding-right: 64px;
            }

            &:nth-child(2) {
                padding-right: 42px;
                padding-left: 22px;
            }


            &:nth-child(3) {
                padding-right: 22px;
                padding-left: 42px;
            }

            &:nth-child(4) {
                padding-left: 64px;
            }
        }
    }

    .page-overview-item {
        margin-top: 20px;
        * {
            color: $darkest-grey;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
        }

        .item-link {
            font-family: $primary-bold;
        }
    }
}

@import "pageoverview-mobile";
