.tabcontrol {
    margin-bottom: 50px;

    .nav-tabs {
        @include list-reset;
        display: flex;
        justify-content: flex-start;
        border-bottom: 4px solid $lightest-grey;

        .nav-item {
            margin: 0 80px 0 0;
            position: relative;

            > a, a:visited {
                font-size: 16px;
                font-family: $primary-bold;
                color: $black;
                padding: 15px 0;
                display: block;
                text-align: center;
                position: relative;
                top: 4px;

                &:hover, &:focus {
                    text-decoration: none;
                }

                &.active {
                    &:after {
                        content: "";
                        background: linear-gradient(to right, $blue 0%, $aqua 100%) left bottom transparent no-repeat;
                        background-size: 100% 4px;
                        position: absolute;
                        top: 0;
                        right: 35%;
                        bottom: 0;
                        left: 35%;
                        width: 30%;
                    }
                }
            }
        }
    }

    .tab-content {
        > .tab-pane {
            display: none;
            width: 100%;
            min-height: 400px;
            padding: 60px 80px;
        }

        > .active {
            display: block;
        }
    }
}

@import "tabcontrol-mobile";