.scroll-down-indicator {
    background: transparent;
    border: 0;
    bottom: 32px;
    cursor: pointer;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    opacity: 0;
    position: fixed;
    z-index: 1;

    &:focus {
        outline: 0;
    }

    & div {
        display: block;
        height: 40px;
        transform: rotate(90deg);
        width: 40px;
    }

    & div object {
        pointer-events: none;
    }

    &,
    & div {
        transition: all .3s ease-out;
    }

    &:hover {
        & div {
            transform: rotate(90deg) scale(1.1);
        }
    }

    &.show {
        pointer-events: all;
        opacity: 1;
    }
}
