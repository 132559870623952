﻿.universalheadersearchoverlay {
    display: none;
    left: 0;
    min-height: 100%;
    right: 0;
    top: 0;
    position: absolute;
    z-index: 150;

    &::before {
        bottom: 0;
        background-color: $white;
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.12);
        content: '';
        position: absolute;
        left: calc((100vw - 100%) / -2);
        top: 0;
        width: 100vw;
    }

    &-container {
        padding: 50px 0 136px;
        position: relative;
    }

    &-close {
        background: none;
        border: 0;
        cursor: pointer;
        right: 0;
        top: 45px;
        padding: 0;
        position: absolute;
        will-change: contents;

        &-icon {
            height: 14px;
            transition: .2s ease-in-out;
            width: 14px;
        }

        &,
        &:focus,
        &:active {
            outline: none;
        }

        &:hover {
            .universalheadersearchoverlay-close-icon {
                transform: scale(1.2);
            }
        }
    }

    &-searchbar {
        margin: auto;
        max-width: calc(100% - 76px);
        position: relative;
        width: 1016px;

        &:not(:last-child) {
            margin-bottom: 68px;
        }

        .CoveoSearchbox {
            .CoveoSearchButton {
                align-items: center;
                border: 0;
                display: flex;
                height: 100%;
                position: absolute;
                right: 0;
                width: auto;

                .coveo-search-button {
                    &::before {
                        background-image: url('/Images/Base/dist/icon-search.svg');
                        content: '';
                        display: block;
                        height: 20px;
                        width: 20px;
                    }

                    &-loading,
                    &-svg {
                        display: none;
                    }
                }
            }

            .magic-box {
                border: 0;

                &,
                .magic-box-input {
                    border-radius: 0;
                }

                &-clear {
                    display: none;
                }

                &-suggestions {
                    border-bottom: 0;
                    margin-top: 5px;
                    // Custom handlebar search card template; title styles are shared among search sections/components
                    .search-wrap {
                        &-text-title {
                            color: $darkest-grey;
                            font-family: $primary-semibold;
                            font-size: 18px;
                            line-height: 28px;
                            margin: 0;
                        }

                        &-text-excerpt,
                        &-image {
                            display: none;
                        }
                    }

                    .coveo-result-list-container.coveo-list-layout-container {
                        height: auto !important; // Override inline Coveo style
                    }

                    .coveo-omnibox-selectable {
                        padding: 12px 16px;
                    }

                    .coveo-omnibox-selected {
                        background-color: transparent;
                        cursor: default;
                    }
                }
            }
        }

        .CoveoSearchbox .magic-box .magic-box-input > input,
        &-input {
            border-style: solid;
            color: $coal-grey;
            font-family: $primary-light;
            font-size: 24px;
            line-height: 33px;
            padding: 0 0 16px 0;
            text-indent: 0;

            &,
            &:focus {
                border-color: $dark-grey;
                border-width: 0 0 3px 0;
            }

            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: $coal-grey;
            }

            &::-moz-placeholder { /* Firefox 19+ */
                color: $coal-grey;
            }

            &:-ms-input-placeholder { /* IE 10+ */
                color: $coal-grey;
            }

            &:-moz-placeholder { /* Firefox 18- */
                color: $coal-grey;
            }
        }

        &-input::-ms-clear {
            display: none;
        }

        .initiate-search {
            background: none;
            border: 0;
            cursor: pointer;
            height: 100%;
            transition: transform .2s ease-in-out;
            will-change: contents;
            padding: 0;
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &-col {
        &-title {
            color: $light-grey;
            font-size: 14px;
            font-family: $primary-bold;
            letter-spacing: 2px;
            line-height: 16px;
            text-transform: uppercase;
        }
    }

    &-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    &-listitem {
        &:first-child {
            margin-top: 28px;
        }

        &:not(:first-child) {
            margin-top: 24px;
        }

        &-link {
            color: $darkest-grey;
            font-size: 14px;
            line-height: 24px;
        }

        &-date {
            display: block;
            font-family: $primary-semibold;
            font-size: 12px;
            margin-top: 8px;
        }
    }

    &.showSearchOverlay {
        display: block;
    }
}

@import "_universalheadersearchoverlay-mobile";
