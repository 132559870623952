@include max-screen(md) {
    .universalcompositecontentcard {
        &-linklist {
            .universallinklist {
                margin-bottom: 30px;
            }
        }
    }
}

@include max-screen(sm) {
    .universalcompositecontentcard {
        &-title {
            margin-bottom: 20px;
        }

        &-desc {
            margin-bottom: 40px;
        }

        &-linklist {
            .universallinklist {
                column-count: 1;
            }
        }

        &-placeholder {
            margin-top: 50px;
        }
    }
}
