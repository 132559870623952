.universalrichtext {
    margin-top: 58px;
    margin-bottom: 58px;

    + * {
        clear: both;
    }

    &-eyebrow,
    &-title {
        color: $darkest-grey;
        margin-bottom: 0;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &-eyebrow {
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        text-transform: uppercase;
    }

    &-title {
        font-size: 24px;
        font-family: $primary-regular;
        line-height: 32px;
    }

    &-text {
        &::after {
            clear: both;
            content: '';
            display: block;
        }

        &,
        * {
            color: $dark-grey;
            font-size: 16px;
            line-height: 28px;
            margin: 0;
        }

        li {
            line-height: 24px;
        }

        * + * {
            margin-top: 30px;
        }

        ol, ul {
            margin-top: 20px;
        }

        li + li {
            margin-top: 16px;
        }

        ul {
            list-style-type: none;
            padding-left: 40px;

            li {
                padding-left: 24px;

                &::before {
                    background-color: $black;
                    content: '';
                    display: inline-block;
                    height: 6px;
                    margin-right: -6px;
                    position: relative;
                    right: 24px;
                    top: -1px;
                    width: 6px;
                    vertical-align: middle;
                }
            }
        }
    }

    &-media-left {
        &,
        &-overflow {
            margin: 0 42px 0 0;

            &,
            &-caption {
                float: left;
            }

            &-caption {
                margin: 12px 42px 0 0;
            }
        }

        &-overflow {
            &,
            &-caption {
                margin-left: -100px;
            }
        }
    }

    &-media-right {
        &,
        &-overflow {
            margin: 0 0 0 42px;

            &,
            &-caption {
                float: right;
            }

            &-caption {
                margin: 12px 0 0 42px;
            }
        }

        &-overflow {
            &,
            &-caption {
                margin-right: -100px;
            }
        }
    }

    &-media-left,
    &-media-right {
        &,
        &-overflow {
            box-shadow: 0 8px 18px 0 rgba(0,0,0,0.12);
            height: auto !important; // important to override inline styles from rich text editor
            &,
            &-caption {
                clear: both;
            }

            &-caption {
                color: $darkest-grey;
                font-size: 14px;
                font-style: italic;
                line-height: 24px;
            }
        }

        &-overflow {
            &,
            &-caption {
                max-width: 488px;
                min-width: 488px;
                width: 488px;
            }
        }

        &,
        &-caption {
            max-width: 388px;
            min-width: 388px;
            width: 388px;
        }
    }
}

@import "insightrichtext-mobile";
