@include max-screen(lg) {
    .relatedofferings {
    
    }
}

@include max-screen(md) {
    .relatedofferings {
        padding: 50px 0;
        .related-offering-card {
            margin-bottom: 30px;
        }
    }
}

@include max-screen(sm) {
    .relatedofferings {
    
    }
}