@include max-screen(md) {
    .universalkeyactionbig {
        margin-top: 64px;
        margin-bottom: 64px;

        &-container {
            padding-top: 88px;
            padding-bottom: 88px;
        }

        &-title {
            width: 65%;
        }
    }
    // SM Callout
    .keyactionssm {
        background-color: $lightest-grey;
        flex-direction: column;
        padding: 40px;
        align-items: flex-start;

        .bar {
            position: absolute;
            top: 10%;
            right: 100%;
            bottom: 50%;
            left: 0;
            width: 8px;
            height: 40%;
        }

        &-title {
            font-size: 32px;
            line-height: 42px;
            margin-bottom: 40px;
            width: 100%;
        }

        input {
            max-width: 100%;
            margin: 0 10px 20px auto;
        }

        .keyactionssm-form-container {
            display: block;

            a {
                width: 100%;
            }
        }

        a, a:visited {
            @include primary-button;
            line-height: 1.2;
            text-align: center;
        }
    }
}

@include max-screen(sm) {
    .universalkeyactionbig {
        background-color: darken($white, 2%);

        &-container {
            padding-bottom: 55px;
            padding-top: 24px;

            &:first-child {
                padding-top: 55px;
            }
        }

        &-img {
            position: relative;
        }

        &-title {
            font-size: 24px;
            line-height: 32px;
            width: 100%;

            + .universalkeyactionbig-cta,
            + .universalkeyactionbig-ctatext {
                margin-top: 24px;
                width: 100%;
                text-align: center;
            }
        }
    }
}
