.universaliconlist {
    @extend %itemlist;

    &-item {
        &-icon {
            display: block;
            height: 40px;
            margin-bottom: 16px;
            width: 40px;
        }

        &-link,
        &-link:visited,
        &-link:hover,
        &-link:focus,
        &-linktext,
        &-desc {
            color: $darkest-grey;
        }

        &-link,
        &-linktext {
            font-size: 18px;
            font-family: $primary-semibold;
            line-height: 31px;
        }

        &-desc {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 0;

            &:not(:only-child) {
                margin-top: 16px;
            }
        }
    }

    &-eyebrow {
        color: $darkest-grey;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        text-transform: uppercase;
    }
}

// Mobile file not required here