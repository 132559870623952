.universalinsightexplorerelatedtopics {
    margin-top: 56px;
    margin-bottom: 56px;

    &-title,
    &-tags-item,
    &-tags-item a, &-tags-item a:visited {
        color: $darkest-grey;
        font-family: $primary-semibold;
        text-transform: uppercase;
    }

    &-title {
        font-size: 14px;
        letter-spacing: 2.15px;
        line-height: 25px;
        margin-bottom: 8px;
    }

    &-tags {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        margin: 0 0 -16px 0;
        padding: 0;

        &-item {
            border: 1px solid $darkest-grey;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 12px;
            margin-bottom: 16px;
            padding: 4px 8px;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
}

@import "insightexplorerelatedtopics-mobile";
