.casestudylist {
    margin: 50px 0;

    a, a:visited {
        &:hover, &:focus {
            text-decoration: none;
        }
    }

    .casestudy-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 30px;
        min-height: 270px;
        height: 100%;
        box-shadow: 0 8px 24px 0 rgba(171,171,171,0.2);

        img {
            margin-bottom: 20px;
            width: 100%;
            //max-width: 170px;
        }

        .tag {
            display: block;
            font-family: $primary-semibold;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 1.2;
            color: $darkest-grey;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        h2 {
            @include add-caret;
            font-family: $primary-semibold;
            font-size: 22px;
            line-height: 1.4;
            color: $black;
            margin-bottom: 10px;
        }
    }
}

@import "casestudylist-mobile";
