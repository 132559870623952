@include max-screen(lg) {
    .universal-headermedium {
        .desc {
            max-width: 60%;
        }
    }
}

@include max-screen(md) {
    .universal-headermedium {
        min-height: 400px;

        &-heading {
            max-width: 100%;
        }

        .desc {
            max-width: 100%;
        }
    }
}

@include max-screen(sm) {
    .universal-headermedium {
        min-height: 248px;

        &-heading {
            font-size: 32px;
        }

        .desc {
            font-size: 16px;
        }

        &-button {
            margin-top: 24px;
        }
    }
}
