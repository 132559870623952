@include max-screen(xl) {
    .universallinklistpanel {
        &-textcol {
            padding-left: 65px;

            &,
            &:only-child {
                padding-top: 44px;
                padding-bottom: 44px;
            }
        }
    }
}

@include max-screen(lg) {
    .universallinklistpanel {
        &-textcol {
            padding-left: 55px;

            &,
            &:only-child {
                padding-top: 40px;
                padding-bottom: 40px;
            }
        }

        &-title {
            font-size: 30px;
            line-height: 38px;
        }

        &-subtitle {
            font-size: 18px;
            line-height: 26px;
        }

        .universallinklist {
            column-gap: 50px;
        }
    }
}

@include max-screen(md) {
    .universallinklistpanel {
        margin-top: 64px;
        margin-bottom: 64px;

        &-textcol {
            padding-left: 32px;
            padding-right: 32px;

            &,
            &:only-child {
                padding-top: 36px;
                padding-bottom: 36px;
            }
        }

        &-title {
            font-size: 28px;
            line-height: 36px;

            &:not(:only-child) {
                margin-bottom: 16px;
            }
        }

        &-subtitle {
            font-size: 16px;
            line-height: 24px;
        }

        .universallinklist {
            column-gap: 30px;

            &:not(:only-child) {
                margin-top: 12px;
            }

            &-linktext {
                font-size: 16px;
            }
        }
    }
}

@include max-screen(sm) {
    .universallinklistpanel {
        &-imgcol {
            padding: 0;
        }

        &-img {
            position: static;
            transform: none;
            width: 100%;
        }

        &-textcol {
            padding-left: 24px;
            padding-right: 24px;

            &,
            &:only-child {
                padding-top: 32px;
                padding-bottom: 32px;
            }
        }

        &-title {
            font-size: 26px;
            line-height: 34px;
        }

        .universallinklist {
            column-count: 1;
            column-gap: 0;
        }
    }
}
