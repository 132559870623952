﻿.infographics-backbutton {
    animation: fadeIn .3s normal forwards ease-out;
    background: none;
    border: 0;
    color: #343741;
    cursor: pointer;
    font-family: $segoe-bold;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 25px;
    margin-bottom: 33px;
    text-transform: uppercase;
    padding: 0;

    &,
    &::before {
        transition: .2s ease-out;
    }

    &::before {
        background: transparent url(/Images/Base/dist/icon-caret.svg) no-repeat 100% 50%;
        content: '';
        display: inline-block;
        height: 16px;
        position: relative;
        transform: rotate(180deg);
        top: 3px;
        width: 16px;
    }

    &:hover {
        color: $dark-blue;

        &::before {
            background-image: url(/Images/Base/dist/icon-caret-darkblue.svg);
        }
    }
}