﻿.universallocationfindercallout {
    &-partner,
    &-keyaction {
        .location-input-container {
            align-items: center;
            background: transparent;
            display: flex;
            padding: 0;

            .location-input-wrapper {
                position: relative;
                width: 100%;

                &::before {
                    background-image: url('/Images/Base/dist/icon-location-pin.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center center;
                    content: '';
                    display: inline-block;
                    height: 23px;
                    left: 24px;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 17px;
                }

                .location-input {
                    border: 1px solid $grey;
                    color: $darkest-grey;
                    font-size: 18px;
                    line-height: 28px;
                    height: 3.1rem; // IE11 bugfix
                    padding: 10px 24px 10px 48px;

                    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                        color: $darkest-grey;
                    }

                    &::-moz-placeholder { /* Firefox 19+ */
                        color: $darkest-grey;
                        opacity: 1
                    }

                    &:-ms-input-placeholder { /* IE 10+ */
                        color: $darkest-grey;
                    }

                    &:-moz-placeholder { /* Firefox 18- */
                        color: $darkest-grey;
                        opacity: 1
                    }
                }
            }

            .find-locations-button {
                @include primary-button;
                flex-shrink: 0;
                margin-left: 24px;
            }
        }
    }

    &-partner {
        box-shadow: 0 8px 24px 0 rgba(0,0,0,0.1);
        padding: 40px 48px 56px;
        background-color: $white;

        &-title {
            color: $darkest-grey;
            font-family: $primary-semibold;
            font-size: 22px;
            line-height: 31px;
            margin-bottom: 66px;

            &:empty {
                display: none;
            }
        }

        .location-input {
            font-family: $primary-bold;
        }
    }

    &-keyaction {
        background-color: $light-blue-cool;
        margin-top: 104px;
        margin-bottom: 104px;
        padding: 65px 85px;
        position: relative;

        &::before {
            background-color: $yellow;
            content: '';
            height: 65%;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            width: 10px;
        }

        &-row {
            flex-wrap: nowrap;
        }

        &-text {
            color: $darkest-grey;
            font-family: $primary-light;
            font-size: 40px;
            line-height: 56px;
            margin-bottom: 0;
        }

        &-form {
            width: 100%;
        }

        .location-input {
            font-family: $primary-semibold;
        }
    }
}

@import '_locationfindercallout-mobile';
