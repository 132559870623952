@include max-screen(lg) {
    .universalpagelist,
    .universalpagelistvar2,
    .legacypagelist {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    .universalpagelist,
    .legacypagelist {
        &-container {
            padding-top: 64px;
        }

        &-title,
        &-subtext {
            max-width: 64%;
        }

        &-title {
            font-size: 36px;
            line-height: 46px;
        }

        &-list {
            &-item {
                & + & {
                    margin-left: 36px;
                }

                @for $i from 3 to 5 {
                    &.numberColumns-#{$i} {
                        flex-basis: calc((100% / #{$i}) - (36px * ((#{$i} - 1) / #{$i})));
                    }
                }
            }
        }
    }
}

@include max-screen(md) {
    .universalpagelist,
    .universalpagelistvar2,
    .legacypagelist {
        &-list {
            &:not(:first-child) {
                margin: 24px 0 0 0;
            }
        }
    }

    .universalpagelist,
    .legacypagelist {
        &-title,
        &-subtext {
            max-width: 75%;
        }

        &-title {
            font-size: 32px;
            line-height: 42px;
        }

        &-subtext {
            font-size: 14px;
            line-height: 21px;
        }

        &-list {
            &-item {
                & + & {
                    margin-left: 20px;
                }

                @for $i from 3 to 5 {
                    &.numberColumns-#{$i} {
                        flex-basis: calc((100% / #{$i}) - (20px * ((#{$i} - 1) / #{$i})));
                    }
                }
            }
        }
    }

    .universalpagelistvar2 {
        &-list {
            &-item {
                &-title {
                    margin-bottom: 8px;

                    &,
                    &-link {
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                &-text {
                    font-size: 14px;
                    line-height: 20px;
                }

                &-cta {
                    margin-top: 16px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalpagelist,
    .universalpagelistvar2,
    .legacypagelist {
        &-title {
            font-size: 26px;
            line-height: 34px;
        }

        &-list {
            display: block;

            &-item {
                &:not(:first-child) {
                    margin-top: 32px;
                }

                & + & {
                    margin-left: 0;
                }


                &-img {
                    &-container {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    .universalpagelist,
    .legacypagelist {
        &-container {
            padding-top: 42px;
        }

        &-title,
        &-subtext {
            max-width: 100%;
        }

        &-subtext {
            &:not(:last-child) {
                margin-bottom: 32px;
            }
        }

        &-list {
            &-item {
                &-title {
                    margin-bottom: 4px;
                }

                &-cta {
                    margin-top: 12px;
                }
            }
        }
    }

    .universalpagelistvar2 {
        &-title {
            text-align: left;
        }
    }
}
