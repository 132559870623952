﻿@include max-screen(lg) {
    .profilesectionpagesublayout {
    }
}

@include max-screen(md) {
    .profilesectionpagesublayout {
    }
}

@include max-screen(sm) {
    .universalprofilesectionpagesublayout {
        .left-nav {
            padding: 40px 20px;
         }   
        .content {

            .managed-services-card {

                img {
                    height: auto;
                    max-height: none;
                }

            }
        }
    }
}
