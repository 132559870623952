@include max-screen(md) {
    .universalfooter .link-list ul li a[href^="#"] {
        display: none;
    }

    .universalfooter {
        .cols-wrap {
            padding: 0;
        }

        .col-wrap-border {
            padding: 0;
            border-right: none;
        }

        .col-wrap {
            padding: 0;
        }
        // Find a Location
        .find-a-location {
            .field-wrap {
                input {
                    padding-right: 50px;
                }
            }
        }
        // Social
        .social {
            margin-bottom: 0;
            padding-bottom: 40px;
            border-bottom: 1px solid $light-grey;
        }
        // Link List
        .link-list {
            &.desktop {
                display: none;
            }

            &.mobile {
                display: block;
                margin-bottom: 0;

                .accordionItem {
                    .accordionItemHeading {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-family: $primary-bold;
                        font-size: 16px;
                        text-transform: uppercase;
                        background-color: $white;
                        padding: 25px 0;
                        width: 100%;
                        border: none;
                        outline: none;
                        border-bottom: 1px solid $light-grey;
                        transition: 200ms;
                        opacity: 1;

                        &.has-dropdown {
                            position: relative;

                            &:focus, &:hover {
                                outline: none;
                                cursor: pointer;
                            }

                            &:after {
                                content: url('../Images/Base/dist/icon-acc-caret-down.svg');
                                display: inline-block;
                                margin-left: 10px;
                            }
                        }
                    }

                    &:not(.close) {
                        .accordionItemHeading {
                            border-bottom: 1px solid transparent;

                            &.has-dropdown {
                                &:after {
                                    transform: rotate(180deg);
                                }
                            }
                        }

                        .accordionItemContent {
                            transform: scaleY(1);
                            transform-origin: top;
                            opacity: 1;
                            transition: opacity 500ms ease-in-out;
                            position: relative;
                            width: 100%;
                            height: 100%;
                            display: block;
                            overflow: auto;
                            background-color: $white;
                        }
                    }

                    &.close {
                        padding: 0;
                        margin: 0;
                        position: relative;
                        float: none;
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                        color: inherit;
                        text-shadow: none;
                        opacity: 1;

                        .accordionItem {
                            opacity: 1;

                            button.accordionItemHeading {
                                background-color: transparent;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }

                        .accordionItemContent {
                            height: 0px;
                            transform: scaleY(0);
                            opacity: 0;
                            display: none;
                        }
                    }
                }
            }
        }

        .footer-quick-link, .software-updates, .technical-docs {
            margin-bottom: 40px;
        }
        // Logo Column
        .logo-column {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            padding-top: 30px;
            padding-bottom: 0;

            img {
                width: 125px; // set logo width
                margin-bottom: 20px;
            }
        }

        .footer-basement {
            margin-top: 40px;

            .content-wrap {
                flex-direction: column;
            }
        }
    }
}

@include max-screen(sm) {
    .universalfooter {
        // Social
        .social {
            .social-icons {
                display: block;
                ul {
                    @include list-reset;
                    display: flex;
                    justify-content: space-between;
                    padding-bottom: 10px;

                    li {
                        margin-right: 30px;
                    }
                }
            }
        }
    }
}
