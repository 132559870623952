﻿.universal-headerlarge {
    display: flex;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    &::before {
        background: linear-gradient(284.42deg, rgba(0,0,0,0) 0%, rgba(28,24,22,0.25) 18.8%, #72635B 100%);
        content: '';
        height: 100%;
        opacity: .5;
        position: absolute;
        width: 100%;
    }

    &-container {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 520px;
        padding-top: 88px;
        padding-bottom: 88px;
        z-index: 1;
    }

    &-heading {
        font-size: 56px;
        line-height: 70px;
        margin: 0;
        max-width: 712px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .desc {
        display: block;
        font-size: 32px;
        line-height: 1.3;
        font-family: $primary-semibold;
        margin-bottom: 0;
        color: $white;
        max-width: 40%;
    }

    &-button {
        @include primary-button;
        margin-top: 32px;
        padding: 15px 40px;

        &:visited {
            color: inherit;
        }
    }
}

@import "universalheaderlarge-mobile";
