.universalvideoanchorbutton {
    background-color: darken($light-blue-cool, 1%);
    margin-top: 56px;
    margin-bottom: 56px;
    padding: 22px 26px;

    &-anchor {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-family: $primary-bold;
        line-height: 22px;

        &,
        &:visited {
            color: $dark-blue;
        }

        &::before {
            background-image: url(/Images/Base/dist/icon-play-button.svg);
            background-size: cover;
            content: '';
            flex-shrink: 0;
            height: 40px;
            margin-right: 16px;
            width: 40px;
        }
    }
}

@import "videoanchorbutton-mobile";
