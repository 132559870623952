.pressreleasecallout {
    padding: 50px 0;

    h1 {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 1.2;
        color: $darkest-grey;
        margin-bottom: 20px;
    }

    h3 {
        font-family: $primary-regular;
        font-size: 20px;
        line-height: 1.3;
        color: $grey;
        margin-bottom: 50px;
    }

    .border-top {
        border-top: 2px solid #C7C9C8;
        padding-top: 50px;
        position: relative;

        &:before {
            background: linear-gradient(47.74deg, #00549E 0%, #00B7A8 100%);
            content: '';
            display: block;
            height: 4px;
            top: 0;
            transform: translateY(-50%) translateY(-1px);
            transform: translateY(calc(-50% - 1px));
            position: absolute;
            //width: calc(100% / 4);
            width: 120px;
        }
    }

    .pressrelease-wrap {
        margin-bottom: 30px;

        .date {
            font-family: $primary-semibold;
            font-size: 13px;
            line-height: 1.2;
            color: $darkest-grey;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            display: block;
            margin-bottom: 10px;
        }

        h2 {
            font-family: $primary-regular;
            font-size: 22px;
            line-height: 1.6;
            color: $darkest-grey;
        }

        a, a:visited {
            color: $darkest-grey;
            &:focus, &:hover {
                text-decoration: none;
            }
        }
    }
}

@import "pressreleasecallout-mobile";