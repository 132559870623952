.universalinsightbyline {
    margin-top: 32px;
    margin-bottom: 64px;

    &-row {
        align-items: flex-start;
    }

    &-options,
    &-tags,
    &-social {
        align-items: center;
        display: flex;
    }

    &-tags,
    &-social {
        list-style: none;
        padding: 0;
    }

    &-date,
    &-author,
    &-tags-item,
    &-tags-item a, &-tags-item a:visited,
    &-options-title {
        color: $darkest-grey;
        font-family: $primary-semibold;
        text-transform: uppercase;
    }

    &-date,
    &-author {
        font-size: 14px;
        letter-spacing: 1.4px;
        line-height: 26px;
    }

    &-date {
        + .universalinsightbyline-author {
            &::before {
                content: '|';
                padding: 0 8px;
            }
        }
    }

    &-author {
        span:not(:last-child) {
            &::after {
                content: '\00a0';
            }
        }
    }

    &-tags {
        flex-wrap: wrap;
        margin: 24px 0 -16px;
        width: 100%;

        &:only-child {
            margin-top: 0;
        }

        &-item {
            border: 1px solid $darkest-grey;
            font-size: 12px;
            letter-spacing: 0.5px;
            line-height: 12px;
            margin-bottom: 16px;
            padding: 4px 8px;

            &:not(:last-child) {
                margin-right: 24px;
            }
        }
    }

    &-options {
        justify-content: flex-end;

        &-title {
            font-size: 14px;
            letter-spacing: 2.15px;
            line-height: 25px;
            margin: 0;

            &:not(:last-child) {
                margin-right: 32px;
            }
        }
    }

    &-social {
        margin: 0;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &-item {
            line-height: 1;

            &:not(:last-child) {
                margin-right: 24px;
            }

            &-link {
                display: flex;

                &,
                &:visited {
                    transition: opacity .2s ease-in-out;
                }

                &-icon {
                    height: auto;
                    max-width: 18px;
                    max-height: 18px;
                    width: auto;
                }

                &:hover {
                    opacity: 0.75;
                }
            }
        }
    }

    &-download {
        @include primary-button;

        &:visited,
        &:hover {
            color: $white;
        }
    }
}

.universalinsightbottomshare{
    margin-bottom: unset;
}

.universalinsightbottomshare-options {
    justify-content: unset;
    -ms-flex-pack: unset;
}

@import "insightbyline-mobile";
