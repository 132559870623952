@include max-screen(md) {
    .homeimagegrid {
        margin: 50px 0;
        padding: 50px 0;

        &-title {
            font-family: $primary-semibold;
            font-size: 28px;
            line-height: 1.1;
            margin-bottom: 15px;
        }

        p {
            font-family: $primary-regular;
            font-size: 16px;
            line-height: 1.4;
            color: $dark-grey;
            margin-bottom: 40px;
        }

        .row {
            margin-bottom: 0;
        }

        .homeimagegridcard {
            height: auto;

            .image {
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                position: relative;
            }

            &.overlay {
                display: block;
                position: relative;
                top: initial;
                right: initial;
                bottom: initial;
                left: initial;

                .image {
                    min-height: 0;

                    img {
                        position: initial;
                        width: 100%;
                        height: 100%;
                        min-height: 0;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    position: relative;

                    &.light {
                        .text-wrap .text-wrap-title, .text-wrap p {
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .homeimagegrid {
        .homeimagegridcard {
            .text, &.overlay .text {
                .text-wrap {
                    background-color: transparent;
                    padding: 30px 0;     
                }   
            }
        }
    }
}