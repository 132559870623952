﻿.videomodal {
    &-backgroundcolor {
        background-color: $lightest-grey;
    }

    &-button,
    &-image-container {
        &::after {
            background-image: url(/Images/Base/dist/icon-play-button-blue-outline.svg);
            background-size: cover;
            content: '';
            height: 53px;
            width: 53px;
        }
    }
    // Simplified version:
    &-button {
        align-items: center;
        background: none;
        border: 0;
        color: $dark-blue;
        cursor: pointer;
        display: flex;
        font-family: $primary-semibold;
        font-size: 24px;
        line-height: 33px;
        margin: 36px auto 60px;
        padding: 0;

        &::after {
            flex-shrink: 0;
            margin-left: 24px;
        }
    }
    // Two-col version (default):
    &-row {
        align-items: center;
        padding-top: 56px;
        padding-bottom: 56px;
    }

    &-image-container {
        @extend .col-sm-6;

        &-img {
            box-shadow: 2px 2px 11px 0 rgba(95,99,105,0.42);
            cursor: pointer;
            width: 100%;
        }

        &::after {
            left: 50%;
            pointer-events: none;
            position: absolute;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-text {
        @extend .col-md-5;
        @extend .offset-md-1;

        &-title,
        &-description {
            margin-bottom: 0;
        }

        &-title {
            color: $darkest-grey;
            font-family: $primary-semibold;
            font-size: 36px;
            line-height: 48px;

            &:not(:last-child) {
                margin-bottom: 18px;
            }
        }

        &-description {
            color: $dark-grey;
            font-family: $primary-regular;
            line-height: 24px;
        }
    }

    &-modal {
        background: rgba($dark-grey, .9);
        left: 0;
        position: fixed;
        top: 0;
        transition: opacity .3s;
        z-index: 1000;

        &,
        .universalmedia {
            opacity: 0;
        }

        .universalmedia {
            transition: opacity .6s .6s;
        }

        .universalmedia-caption {
            display: none;
        }

        &.showModal {
            bottom: 0;
            right: 0;

            &::before {
                background-image: url(../../../../../Images/Base/dist/icon-close-white.svg);
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                content: '';
                cursor: pointer;
                display: block;
                height: 24px;
                right: 24px;
                position: absolute;
                top: 24px;
                width: 24px;
            }

            &,
            .universalmedia {
                opacity: 1;
            }

            .universalmedia {
                height: 0;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                padding-bottom: 42.1875%; // Normally 56.25, this number is 75% of that b/c of set width below
                width: 75%;
            }
        }
    }
}

@import '_videomodal-mobile';
