.universalheaderleftpanel {
    margin-top: 96px;
    margin-bottom: 96px;
    padding-top: 35px;
    padding-bottom: 35px;

    &-container {
        display: flex;
    }

    &-header {
        background: linear-gradient(136.49deg, $blue 0%, $cerulean 100%);
        flex-shrink: 0;
        min-height: 200px;
        padding: 48px;
        width: 400px;
    }

    &-eyebrow,
    &-copy {
        color: $white;
    }

    &-eyebrow {
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        margin-bottom: 12px;
        text-transform: uppercase;
    }

    &-copy {
        font-size: 40px;
        font-family: $primary-semibold;
        line-height: 54px;
        margin-bottom: 0;
    }

    &-placeholder {
        align-items: center;
        box-shadow: 0 0 36px 0 rgba(0,0,0,0.1);
        display: flex;
        margin: -35px 0;
        padding: 64px;
        width: 100%;

        &:empty {
            display: none;
        }
    }
}

@import "headerleftpanel-mobile";
