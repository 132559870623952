@include max-screen(lg) {
    .infopanel {
    
    }
}

@include max-screen(md) {
    .infopanel {
        .cards-wrap {
            flex-direction: column;
            .card {
                margin-bottom: 50px;
                &:first-child {
                    margin-left: 30px;
                }
                &:last-child {
                    margin-right: 30px;
                    margin-bottom: 0;
                }
                &.search-card {
                    .input-wrap {
                        input {
                            max-width: none;
                        }
                        .submit {
                            right: 3%;
                        }
                    }   
                }
            }
        }
    }
}

@include max-screen(sm) {
    .infopanel {
    
    }
}