@include max-screen(lg) {
    .utilitybrandbar {
        .accordionItem:not(.close) {
            .accordionItemContent {
                top: 0; // IE11 bug fix
                top: initial;
            }
        }
    }
}

@include max-screen(md) {
        .utility-brand-bar-logo-container {
            display:none;
        }
        
        .utilitybrandbar {
        border-bottom: 0;

        &,
        .container {
            padding: 0;
        }

        .container {
            max-width: 100%;
        }

        .utilitybrandbar-wrap {
            flex-direction: column-reverse;

            .brand-wrap,
            .utility-wrap,
            .login-wrap {
                padding: 15px 0;
                border-bottom: 1px solid $lighter-grey;

                button {
                    padding: 0;
                }
            }

            .login-wrap,
            .utility-wrap {
                margin-left: 0;

                .login {
                    font-size: 16px;
                }
            }
        }

        .accordionItem {
            &,
            .accordionItemHeading {
                width: 100%;
            }

            .accordionItemHeading {
                font-size: 16px;
                transition: 0s;

                &.has-dropdown {
                    &::after {
                        margin-left: auto;
                    }
                }
            }

            .accordionItemContent {
                .utility-content {
                    display: none;
                }
            }
        }

        .accordionItem:not(.close) {
            .accordionItemHeading {
                color: $dark-blue;

                &.utility::before {
                    content: url('/Images/Base/dist/icon-globe-blue.svg');
                }

                &::after {
                    content: url('/Images/Base/dist/icon-caret-darkblue.svg');
                }
            }

            .accordionItemContent {
                position: relative;
                overflow-x: hidden;
                overflow-y: auto;

                .utility-content,
                .brands-content {
                    padding-top: 45px;
                    padding-bottom: 0;
                }

                .utility-content {
                    &-title {
                        font-family: $segoe-bold;
                        font-size: 24px;
                        text-transform: uppercase;
                    }

                    .open.subAccordionItem {
                        .subAccordionItemContent {
                            .country-list {
                                column-count: 3;
                            }
                        }
                    }
                }

                .brands-content {
                    .title {
                        font-size: 14px;
                    }

                    ul {
                        column-count: 3;
                        margin: 0 0 15px;
                    }
                }
            }
        }
    }

    .country-search {
        display: block;
        overflow: hidden;

        &-input {
            box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
            margin-top: 40px;
            margin-bottom: 20px;

            &,
            &:focus {
                border: 1px solid $grey;
                border-radius: 8px;
            }

            &::-webkit-search-cancel-button,
            &::-ms-clear {
                background-image: url(../../../../../Images/Base/dist/icon-close-grey.svg);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                cursor: pointer;
                height: 12px;
                width: 12px;
            }
        }

        &-results-list {
            list-style: none;
            margin: 0;
            padding: 0;

            &:empty {
                display: none;
            }

            &:not(:last-child) {
                margin-bottom: 18px;
            }

            a {
                color: $grey;
                display: block;
                padding: 12px 0;

                &:hover {
                    text-decoration: none;

                    &,
                    .country-search-term {
                        color: $dark-blue;
                    }
                }
            }
        }

        &-term {
            color: $darkest-grey;
            font-family: $segoe-bold;
        }

        &-showall {
            @include text-link-3;
            background: none;
            border: 0;
            cursor: pointer;

            &-modal {
                background: $white;
                bottom: 0;
                left: 0;
                padding: 0 24px;
                position: fixed;
                right: 0;
                top: 0;
                overflow-y: scroll;
                z-index: 1000;

                &-close {
                    background-color: transparent;
                    background-image: url(../../../../../Images/Base/dist/icon-close-grey.svg);
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    border: 0;
                    cursor: pointer;
                    height: 16px;
                    position: fixed;
                    right: 40px;
                    top: 24px;
                    width: 16px;
                    z-index: 10;
                }

                .accordionItem {
                    .accordionItemHeading,
                    .country-search {
                        display: none;
                    }

                    .accordionItemContent {
                        .utility-content {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    .utilitybrandbar {
        .accordionItem:not(.close) {
            .accordionItemContent {
                .utility-content {
                    .open.subAccordionItem {
                        .subAccordionItemContent {
                            .country-list {
                                column-count: 2;
                            }
                        }
                    }
                }

                .brands-content {
                    ul {
                        column-count: 2;
                    }
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .utilitybrandbar {
        .accordionItem:not(.close) {
            .accordionItemContent {
                .utility-content {
                    .open.subAccordionItem {
                        .subAccordionItemContent {
                            .country-list {
                                column-count: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}
