.gatedcontent {
    .modal-600 {
        max-width: 600px;
    }

    .modal-content {
        border-radius: 0;

        .modal-header {
            padding: 1.75rem 3rem;
            border-bottom: none;

            .close {
                border: none;
                background-color: transparent;

                img {
                    width: 15px;
                }
            }
        }

        .modal-body {
            padding: 0 100px;

            p {
                color: $dark-grey;
                font-size: 14px;
                line-height: 1.3;

                &.small {
                    font-size: 10px;
                    line-height: 1.4;
                    margin-bottom: 30px;
                    margin-top: 30px;
                }
            }

            .tag {
                font-size: 13px;
                font-family: $primary-bold;
                text-transform: uppercase;
                letter-spacing: 1.6px;
                color: $darkest-grey;
                display: block;
                margin: 20px 0 5px 0;
            }

            &-title {
                font-size: 24px;
                line-height: 1.3;
                font-family: $primary-semibold;
                color: $darkest-grey;
                margin-bottom: 20px;
            }

            .field-wrap {
                margin-bottom: 30px;
            }


            label {
                font-size: 14px;
                font-family: $primary-semibold;
                text-transform: none;
                letter-spacing: 0;
            }

            .cta {
                @include primary-button;
                display: block;
                text-align: center;
                margin-bottom: 30px;
            }

            .back {
                @include text-link-2;
                display: block;
                text-align: center;
            }
        }

        .modal-footer {
            padding: 1.75rem 3rem;
            border-top: none;
        }
    }
}

@import "gatedcontent-mobile";