.genericcallout {
    background-color: $white;
    box-shadow: 0 8px 24px 0 rgba(0, 0, 0, 0.1);
    padding: 40px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .text {
        margin-right: 30px;
        width: 100%;

        h1 {
            display: block;
            font-size: 32px;
            line-height: 1.2;
            font-family: $primary-regular;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 20px;
        }
    }

    a {
        flex-shrink: 0;

        &,
        &:visited {
            @include primary-button;
        }
    }
}

// Element Query
@element .genericcallout and (max-width: 600px) {
    :self {
        padding: 30px 40px;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .text {
            margin-right: 0;

            h1 {
                font-size: 28px;
            }
        }
    }
}

@import "genericcallout-mobile";
