// BUTTONS =============================================== *
// Primary Button
@mixin primary-button {
    background: $dark-blue;
    background-size: 100% 0px;
    border-radius: 0;
    color: $white !important;
    display: inline-block;
    font-family: $primary-semibold;
    overflow: hidden;
    padding: 12px 24px;
    position: relative;
    border: none;

    &::after {
        background: linear-gradient(to left, $blue 0%, $aqua 100%);
        border-radius: 0;
        bottom: 0;
        content: '';
        height: 4px;
        left: 0;
        position: absolute;
        right: 0;
        transition: transform 300ms ease-in-out;
        transform: translateY(100%);
    }

    &:hover,
    &:focus {
        cursor: pointer;
        text-decoration: none;

        &::after {
            transform: translateY(0);
        }
    }
}

// Disclaimer Button
@mixin disclaimer-button {
    display: inline-block;
    font-size: 16px;
    font-family: $primary-semibold;
    border: 1px solid $green;
    background-color: $white;
    color: $green;
    border-radius: 0;
    padding: 12px 24px;
    transition: all 200ms ease-in-out;

    &:hover, &:focus {
        cursor: pointer;
        background-color: $green;
        color: $white;
    }
}

@mixin campaign-button {
    @include basic-button-styles;
    background-color: $yellow;
    border: none;
    box-shadow: none;
    font-size: 18px;
    letter-spacing: 0.3px;
    transition: color .3s, background-color .3s;
    line-height: 16px;
    padding: 19px 40px;

    &,
    &:hover,
    &:focus {
        color: $coal-grey;
    }

    &:hover,
    &:focus {
        background-color: rgba($yellow, .75);
    }
}

@mixin becomeapartner-button {
    @include basic-button-styles;
    background-color: $dark-blue;
    border: none;
    box-shadow: none;
    font-size: 18px;
    letter-spacing: 0.3px;
    transition: color .3s, background-color .3s;
    line-height: 16px;
    padding: 19px 40px;

    &,
    &:hover,
    &:focus {
        color: $white;
    }

    &:hover,
    &:focus {
        background-color: rgba($dark-blue, .75);
    }
}

@mixin basic-button-styles {
    cursor: pointer;
    font-family: $primary-semibold;
    font-size: 14px;
    letter-spacing: 0.23px;
    text-transform: none;
    padding: 16px 40px;
    -webkit-appearance: none;

    &,
    &:hover,
    &:visited,
    &:focus {
        text-decoration: none;
    }

    @include max-screen(sm) {
        padding: 16px;
    }
}

// LINKS =============================================== *
@mixin add-caret($color: $darkest-grey) {
    padding-right: 20px;

    &,
    &::after {
        display: inline-block;
    }

    &::after {
        content: '';
        height: 16px;
        margin-right: -16px;
        width: 16px;
        vertical-align: middle;

        @if $color == $white {
            background: transparent url('/Images/Base/dist/icon-caret-reversed.svg') no-repeat 100% 50%;
        }
        @else if $color == $dark-blue {
            background: transparent url(/Images/Base/dist/icon-caret-darkblue.svg) no-repeat 100% 50%;
        }
        @else {
            background: transparent url('/Images/Base/dist/icon-caret.svg') no-repeat 100% 50%;
        }
    }
}

@mixin link-common { // link properties commonly shared among link mixins
    @include add-caret;
    text-decoration: none;
    position: relative;
    padding-bottom: 2px;

    &:hover,
    &:focus {
        text-decoration: none;

        &::before {
            width: 100%;
        }
    }

    &:before {
        bottom: -3px;
        border-bottom: 1px solid;
        content: '';
        left: 0;
        transition: width .2s ease-in-out;
        width: 0;
        position: absolute;
    }
}

// Text Link 1
@mixin text-link-1 {
    @include link-common;
    display: inline-block;

    &:hover, &:focus {
        color: inherit;
    }

    &:before {
        border-bottom-color: $black;
    }
}

// Text Link 2
@mixin text-link-2 {
    @include link-common;
    @include add-caret($dark-blue);
    display: inline;
    font-size: 14px;
    font-family: $primary-bold;

    &,
    &:visited,
    &:hover,
    &:focus {
        color: $blue;
    }

    &:before {
        border-bottom-color: $blue;
    }
}

// Text Link 3 - (link 2 with blue caret)
@mixin text-link-3 {
    @include text-link-2;
    @include add-caret($dark-blue);
}

// Inline/List Link
@mixin link {
    display: inline-block;
    font-family: $primary-semibold;
    color: $black;
    text-decoration: none;
    background: linear-gradient(to left, $black 0%, $black 100%) left bottom transparent no-repeat;
    background-size: 100% 1px;
    padding-bottom: 2px;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $black;
        background: linear-gradient(to right, $blue 0%, $aqua 100%) left bottom transparent no-repeat;
        background-size: 100% 3px;
        padding-bottom: 3px;
    }
}

// Text Link 4 (with arrow)
@mixin text-link-4 {
    @include add-caret;
    font-family: $primary-semibold;
    font-size: 18px;
    line-height: 24px;
    padding-right: 24px;
    position: relative;

    &,
    &:hover,
    &:focus,
    &:visited {
        color: $white;
    }

    &:after {
        background: transparent url('../Images/Base/dist/link_arrow_white.svg') no-repeat 100% 50%;
        margin-right: -24px;
        top: 3px;
        width: 24px;
    }

    @include max-screen(md) {
        font-size: 16px;
    }
}
// Text Link 5 (blue with arrow)
@mixin text-link-5 {
    @include text-link-4;

    &,
    &:hover,
    &:focus,
    &:visited {
        color: $dark-blue;
    }

    &:hover {
        text-decoration: underline;
    }

    &:after {
        background-image: url('../Images/Base/dist/link_arrow_blue.svg');
        position: relative;
        top: -1px;
    }
}



// LISTS ============================================== *
// Reset
@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;
}


// HELPERS ============================================== *

// ADD-CARET-HELPER
// Different from add-caret, which is a full set of styles to implement carets in text links.
// 'add-caret-helper' should only be used in ::after or ::before pseudo elements.
// A helper; not a full set of styles. Mainly for use in header/footer/navigation components.
@mixin add-caret-helper {
    content: url('/Images/Base/dist/icon-caret.svg');
    display: inline-block;
}


// MEDIA QUERIES =============================================== *
// Min-width
@mixin min-screen($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
        // Prints a media query based on the value
        @media (min-width: $value) {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. " + "Make sure it is defined in `$breakpoints` map.";
    }
}

// Max-width
@mixin max-screen($breakpoint) {
    // Retrieves the value from the key
    $value: map-get($breakpoints, $breakpoint);

    // If the key exists in the map
    @if $value != null {
        // Prints a media query based on the value
        @media (max-width: $value) {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "No value could be retrieved from `#{$breakpoint}`. " + "Make sure it is defined in `$breakpoints` map.";
    }
}


// content-box-flex-basis(numOfItems: number - items in row; #px of left + right padding for one item, border width between items w/ a default of 1px) used in: Brand Grid Static ================== *
@mixin content-box-flex-basis($numOfItems, $totalLeftRightPadding, $borderWidth: 1px) {
    $itemWidth: 100% / $numOfItems; // Will use to make items exactly same width
    $itemBorderWidth: $borderWidth * (($numOfItems - 1)/$numOfItems);
    $paddingMinusBorders: $totalLeftRightPadding + $itemBorderWidth;

    // Using content box so need to calc padding + borders
    flex-basis: calc(#{$itemWidth} - #{$paddingMinusBorders});
}
