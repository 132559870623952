﻿.quotessection {
    background: linear-gradient(0.26deg, $aqua 0%, $dark-aqua 22.29%, $cerulean 100%);
    text-align: center;
    padding-top: 56px;
    padding-bottom: 72px;

    &-title,
    &-description,
    &-fineprint {
        color: $white;
        margin: 0 auto 16px;
        max-width: 750px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-description,
    &-fineprint {
        font-family: $primary-regular;
        line-height: 24px;
    }

    &-title {
        font-family: $primary-semibold;
        line-height: 64px;
    }

    &-fineprint {
        display: block;
        font-size: 12px;
        font-style: italic;
    }

    &-list {
        list-style: none;
        margin: 24px 0 0 0;
        padding: 0;

        &-item {
            background-color: $white;
            flex-grow: 1;
            padding: 32px 32px 42px;
            width: calc((100% / 3) - 24px);

            &:first-child {
                margin-right: 22px;
            }

            &:nth-child(2) {
                margin-left: 11px;
                margin-right: 11px;
            }

            &:last-child {
                margin-left: 22px;
            }

            &-title,
            &-quotetext {
                margin-bottom: 0;
            }

            &-title {
                color: $dark-grey;
                font-family: $primary-semibold;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 19px;
                text-transform: uppercase;

                &:not(:last-child) {
                    &::after {
                        background-color: $dark-aqua;
                        content: '';
                        display: block;
                        height: 1px;
                        margin: 9px auto 13px auto;
                        width: 24px;
                    }
                }
            }

            &-quotetext {
                color: $darkest-grey;
                font-family: $primary-regular;
                line-height: 24px;
            }
        }
    }
}

@import '_quotessection-mobile';
