﻿.infographics-sidenav {
    animation: loadLeft .5s .5s normal forwards ease-out;
    background: $white;
    height: 100%;
    margin-top: 0;
    margin-left: calc(((100vw - 1440px) / 2) + 24px - (17px / 2));
    min-width: 355px;
    padding: 78px 40px 40px 0;
    transform: translateX(-350%);
    opacity: 0;
    overflow-y: auto;
    transition: margin-top .3s;
    width: 355px;
    z-index: 1;

    &::-webkit-scrollbar {
        background-color: $white;
        box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
        width: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $dark-blue;
    }

    &-slider {
        background: #DFE0DE;
        border: 0;
        border-radius: 4px;
        height: 4px;
        top: 8px;
        transition: .3s;
        transform: translateX(-50%);
        width: 33px;

        &,
        &::after {
            left: 50%;
            position: absolute;
        }

        &::after {
            content: '';
            height: 16px;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background: darken(#DFE0DE, 5%);
            height: 6px;
            width: 40px;
        }
    }

    &-title {
        margin-bottom: 24px;
    }

    &-description {
        margin-right: 12px;
    }
}

.infographics-levelone {
    .infographics-sidenav {
        &-title {
            font-family: $segoe-bold;
            font-size: 28px;
            letter-spacing: 1.75px;
            line-height: 30px;
            text-transform: uppercase;
        }

        &-description {
            margin-bottom: 20px;
        }
    }
}

.infographics-leveltwo {
    .infographics-sidenav {
        padding-top: 40px;
        padding-right: 28px;

        &-title {
            font-family: $segoe-semibold;
            font-size: 16px;
        }

        &-description {
            margin-bottom: 32px;
        }
    }
}

@media (max-width: 1440px) {
    .infographics-sidenav {
        margin-left: calc(((100vw - 1200px) / 2) + 24px - (17px / 2));
    }
}

@media (max-width: 1190px) {
    .infographics-sidenav {
        margin-left: calc(((100vw - 1024px) / 2) + 24px - (17px / 2));
    }
}

// Mobile kicks in
@media (max-width: 1024px) {
    .infographics-sidenav {
        margin: 0;
        min-width: 100%;
    }

    .infographics-levelone,
    .infographics-leveltwo {
        .infographics-sidenav {
            padding: 30px;
        }
    }

    .infographics-leveltwo {
        .infographics-sidenav {
            &-title {
                + .infographics-sidenav-navitem {
                    margin-top: 20px;
                }
            }

            &-description {
                margin-bottom: 0;
            }
        }
    }
}

// Subcomponents
@import 'child-components/_navitem';
