﻿@include max-screen(md) {
    .quotessection {
        &-list {
            &-item {
                width: calc((100% / 3) - 18px);

                &:first-child {
                    margin-right: 16px;
                }

                &:nth-child(2) {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                &:last-child {
                    margin-left: 16px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .quotessection {
        padding-bottom: 56px;

        &-title {
            font-size: 32px;
            line-height: 46px;
        }

        &-description {
            font-size: 16px;
        }

        &-list {
            display: block;

            &-item {
                padding: 24px;
                width: 100%;

                & + & {
                    margin-top: 24px;
                }

                &:first-child,
                &:nth-child(2),
                &:last-child {
                    margin-left: 0;
                    margin-right: 0;
                }

                &-quotetext {
                    font-size: 16px;
                }
            }
        }
    }
}
