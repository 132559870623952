@include max-screen(lg) {
    .universal-headerlarge {
        .desc {
            max-width: 60%;
        }
    }
}

@include max-screen(md) {
    .universal-headerlarge {
        &-container {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        .desc {
            max-width: 100%;
        }
    }
}

@include max-screen(sm) {
    .universal-headerlarge {
        background-position: 65%;

        &-container {
            min-height: 248px;
        }

        &-heading {
            font-size: 32px;
            line-height: 40px;
            max-width: 232px;
        }

        .desc {
            font-size: 16px;
        }

        &-button {
            margin-top: 24px;
        }
    }
}
