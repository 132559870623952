﻿// Registration Form Component
.registrationform {
    padding: 100px 0;
    // Defaults (Module Globals)
    .form-group {
        margin-bottom: 25px;

        label {
            font-size: 14px;
        }
    }

    .form--grey {
        background-color: #F5F5F5;
        padding: 50px;

        .col:nth-child(odd) {
            margin-right: 10px;
        }

        .col:nth-child(even) {
            margin-left: 10px;
        }
    }

    .btn-form {
        @include primary-button;
        line-height: 30px;
        margin-top: 16px;

        &:visited,
        &:hover {
            color: $white;
        }
    }

    .register-title {
        font-family: $primary-bold;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 25px;
    }

    .form-title {
        font-family: $primary-light;
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 25px;
    }

    .text-small {
        font-size: 14px;
    }
    /* Forms - Radio Buttons */
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        cursor: pointer;
        display: inline-block;
        font-family: $primary-bold;
        line-height: 20px;
        padding-left: 28px;
        position: relative;

        span {
            clear: both;
            display: block;
        }
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        background: #fff;
        border: 1px solid $grey;
        border-radius: 100%;
        content: '';
        height: 20px;
        left: 0;
        position: absolute;
        top: 0;
        width: 20px;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 12px;
        height: 12px;
        background: $grey;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    /* Forms - Checkboxes */
    .form-check {
        float: left;
        position: relative;
        padding-left: 5px;

        label {
            font-family: "SegoeUIRegular", Arial, sans-serif;
            font-size: 16px;
            line-height: 16px;
            margin: 0;
            padding-left: 25px;
            text-transform: initial;
            letter-spacing: 0;
            position: relative;
            z-index: 100;
        }
    }

    input[type="checkbox"] ~ span:before,
    input[type="checkbox"] ~ span:after {
        content: "";
        display: inline-block;
        background-color: #00549E; // Checkmark
        width: 0;
        height: 0.2rem;
        position: absolute;
        transform-origin: 0% 0%;
    }

    input[type="checkbox"] {
        display: none;
    }

    input[type="checkbox"]:checked ~ span {
        border: 2px solid #00549E;
    }

    input[type="checkbox"]:checked ~ span:before {
        height: 0.15rem;
        transition: width 0.1s;
        transition-delay: 0.3s;
        width: 1rem;
    }

    input[type="checkbox"]:checked ~ span:after {
        height: 0.15rem;
        transition: width 0.1s;
        transition-delay: 0.2s;
        width: 0.4rem;
    }

    input[type="checkbox"] + label {
        cursor: pointer;
        margin-bottom: 0;
        padding-left: 28px;
        position: relative;
        z-index: 2;
    }

    input[type="checkbox"] ~ span {
        border: 1px solid #a9abac;
        display: inline-block;
        height: 18px;
        left: 0;
        position: absolute;
        top: 0;
        transition: all 0.2s;
        width: 18px;
        z-index: 1;
    }

    input[type="checkbox"] ~ span:before {
        left: 5px;
        top: 11px;
        transform: rotate(-55deg);
    }

    input[type="checkbox"] ~ span:after {
        bottom: 6px;
        left: 3px;
        transform: rotate(35deg);
    }
    /* Forms - Disabled Checkboxes & Radio Buttons */
    [type="radio"]:disabled + label:before,
    [type="checkbox"]:disabled ~ label {
        color: $grey;
        border-color: $grey;
    }

    [type="radio"]:disabled + label:before,
    [type="checkbox"]:disabled ~ span {
        background: $grey;
    }

    [type="radio"]:disabled + label:before,
    [type="checkbox"]:disabled ~ label:hover {
        cursor: not-allowed;
    }
    /* Validation Error */
    .has-error {

        input {
            border-color: $fireengine-red;
        }

        ul {
            margin: 0;
            padding: 0;
        }

        li {
            color: $fireengine-red;
            font-size: 12px;
            list-style: none;
            margin: 10px 0 0 0;
        }
    }
    // Form Element
    .registration-form {
        display: none;

        &.active {
            display: block;
        }

        .form-group {
            margin-bottom: 0px;

            @include min-screen(md) {
                & {
                    margin-bottom: 25px;
                }
            }

            .col-md-6 { //*TODO - move to Non-Bootstrap class
                margin-bottom: 25px;

                @include min-screen(md) { //*TODO - move to mobile stylesheet
                    & {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .register-body {
        .datasource-checkbox {
            display: none;
            margin-top: 25px;

            @include min-screen(md) { //*TODO - move to mobile stylesheet
                & {
                    margin-bottom: 0;
                }
            }

            &.active {
                display: block;
            }

            .form-row {
                margin-bottom: 8px;
            }

            .row { //*TODO - move to Non-Bootstrap class
                margin-left: 19px;
            }
        }
    }
}



@import "registrationform-mobile";
