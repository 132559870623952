@include max-screen(xl) {
    .universaldownload { 
        &-list {
            &-item {
                flex-basis: calc((100% / 3) - 2%);

                &:nth-child(3n + 1) {
                    // First item in each row
                    margin-right: 2%;
                }

                &:nth-child(3n + 2) {
                    // Middle item in each row
                    margin-left: 1%;
                    margin-right: 1%;
                }

                &:nth-child(3n + 3) {
                    // Last item in each row
                    margin-left: 2%;
                }
            }
        }
    }
}

@include max-screen(md) {
    .universaldownload {
        margin-bottom: 56px;

        &-list {
            &-item {
                flex-basis: calc((100% / 3) - 6%);

                &:nth-child(3n + 1) {
                    // First item in each row
                    margin-right: 6%;
                }

                &:nth-child(3n + 2) {
                    // Middle item in each row
                    margin-left: 3%;
                    margin-right: 3%;
                }

                &:nth-child(3n + 3) {
                    // Last item in each row
                    margin-left: 6%;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universaldownload {
        margin-top: 32px;
        margin-bottom: 32px;

        &-list {
            &-item {
                flex-basis: 100%;

                &:nth-child(3n + 1),
                &:nth-child(3n + 2),
                &:nth-child(3n + 3) {
                    margin-right: 0;
                    margin-left: 0;
                }

                &:not(:first-child) {
                    // All but first 1 (first row)
                    margin-top: 24px;
                }
            }
        }
    }
}