﻿.universalconvoui-contentitem-list {
    list-style: none;
    margin: 48px 0 0;
    padding: 0;
}

.universalconvoui-contentitem {
    box-shadow: 0 8px 30px 0 rgba(171,171,171,0.2);

    & + & {
        margin-top: 38px;
    }

    &-img {
        width: 100%;
    }

    &-text {
        padding: 18px 24px 46px;
    }

    &-tags-item,
    &-title {
        color: $darkest-grey;
        font-family: $primary-semibold;
    }

    &-tags {
        display: flex;
        margin-top: 6px;

        &-item {
            font-size: 10px;
            letter-spacing: 0.42px;
            line-height: 14px;
            text-transform: uppercase;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }

    &-subtitle {
        color: $dark-grey;
        font-family: $primary-regular;
        font-size: 14px;
        font-weight: 400; // override for JCI
        letter-spacing: 0.29px;
        line-height: 19px;
    }

    &-subtitle,
    &-title {
        margin-bottom: 0;
    }

    &-link {
        display: block;

        &:not(:only-child) {
            margin-top: 16px;
        }
    }

    &-title {
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 500px) {
    .universalconvoui-contentitem {
        &-tags {
            flex-wrap: wrap;
            margin-bottom: -8px;

            &-item {
                margin-bottom: 8px;
            }
        }
    }
}
