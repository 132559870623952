// BREAKPOINTS (Media Queries) =============================================== *
$breakpoints: (
// Matched to Bootstrap $grid-breakpoints variables -------- *
// Extra small screen / phone
xs: 0,
// Small screen / phone
sm: 767px,
// Medium screen / tablet
md: 1024px,
// Large screen / desktop
lg: 1190px,
// Extra large screen / wide desktop
xl: 1400px );


// COLORS =============================================== *

// Base -----------
$white: #FFFFFF;
$light-blue-cool: #FAFAFA;
$lightest-grey: #F0F0F0;
$lighter-grey: #C7C9C8;
$light-grey: #A9ABAC;
$grey: #757575;
$dark-grey: #5F6369;
$darkest-grey: #2E2925;
$black: #000000;
$coal-grey: #333740;

// Secondary -----------
$light-blue: #00BCE4;
$blue: #2D72B9;
$dark-blue: #00539E;
$cerulean: #2B93B2;
$aqua: #2CBCB1;
$dark-aqua: #05A194;
$yellow: #FEBD38;
$green: #4D8202;
$dark-green: #006888;
$seagreen: #3AB291;
$fireengine-red: #CB2439;


// TYPOGRAPHY =============================================== *

// Font Family ----------
$segoe-bold: 'SegoeUIBold', Arial, sans-serif;
$segoe-semibold: 'SegoeUISemiBold', Arial, sans-serif;
$segoe-regular: 'SegoeUIRegular', Arial, sans-serif;
$segoe-light: 'SegoeUILight', Arial, sans-serif;

// Mappings
$primary-bold: $segoe-bold;
$primary-semibold: $segoe-semibold;
$primary-regular: $segoe-regular;
$primary-light: $segoe-light;