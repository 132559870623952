.eventheader {
    padding: 70px 0;
    background-color: $dark-blue;

    .headline,
    .subheadline {
        color: $white;
    }

    .headline {
        font-size: 48px;
        line-height: 1.3;
        margin-bottom: 10px;
    }

    .subheadline {
        font-size: 28px;
        line-height: 1.3;
        margin-bottom: 15px;
    }
}

@import "eventheader-mobile";