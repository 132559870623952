@include max-screen(md) {
    .universalcookiedisclaimer {
        &-container {
            padding: 36px 60px;
        }

        &-col {
            & + & {
                margin-top: 20px;
            }
        }

        &-text {
            margin-right: 0;
        }

        &-btn {
            margin-left: 0;
        }
    }
}

@include max-screen(sm) {
    .universalcookiedisclaimer {
        &-container {
            padding: 24px;
        }
    }
}
