@include max-screen(md) {
    .universalinsighthighlight {
        margin-top: 32px;
        margin-bottom: 32px;

        &-title {
            font-size: 28px;
            line-height: 40px;
        }
    }
}

@include max-screen(sm) {
    .universalinsighthighlight {
        padding-left: 28px;

        &::before {
            width: 4px;
        }

        &-title {
            font-size: 22px;
            line-height: 34px;
        }
    }
}
