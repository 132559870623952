$numItemsPerRow: 4;

.universalfeatureddata2 {
    margin-top: 80px;
    margin-bottom: 100px;

    &-grey {
        background: $lightest-grey;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &-container-desktoptitles,
    &-container-items {
        display: flex;
        justify-content: space-between;
    }

    &-container-desktoptitles {
        margin-bottom: 55px;
    }

    &-container-items {
        .universalfeatureddata2-item-title,
        .universalfeatureddata2-item-list {
            display: none;
        }
    }

    &-container-desktoplists {
        display: none;
        flex-wrap: wrap;
        justify-content: space-between;

        .universalfeatureddata2-item {
            &-title,
            &-list {
                padding: 0 15px;
                width: calc(100% / #{$numItemsPerRow});
            }
        }

        @for $i from 1 through $numItemsPerRow {
            .universalfeatureddata2-item {
                &-title {
                    &:nth-of-type(#{$i}) {
                        order: $i;
                    }
                }

                &-list {
                    &:nth-of-type(#{$i}) {
                        order: calc(#{$numItemsPerRow} + #{$i});
                    }
                }
            }
        }
    }


    &-item-title-tiles,
    &-item {
        flex-basis: calc(25% - 48px);
    }

    &-item {
        &-title,
        &-title-first,
        &-title-tiles {
            color: $darkest-grey;
            font-size: 24px;
            font-family: $primary-regular;
            line-height: 28px;
            margin-bottom: 0;
        }

        &-header {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            margin-bottom: 5px;
        }

        &-image {
            height: 60px;
            margin-right: 5px;
            width: 60px;
            margin-bottom: 8px;
        }

        &-link,
        &-list-link {
            @include text-link-1;
            @include add-caret($darkest-grey);

            &,
            &:visited {
                color: $darkest-grey;
            }
        }

        &-link,
        &-linktext {
            font-size: 22px;
            font-family: $primary-semibold;
            line-height: 29px;
            margin-bottom: 5px;
        }

        &-linktext,
        &-list-linktext,
        &-desc {
            color: $darkest-grey;
            margin-bottom: 0;
        }

        &-desc {
            font-size: 14px;
        }

        &-list {
            margin-bottom: 0;
            padding-left: 0;

            &-item {
                list-style: none;
            }

            &-link,
            &-linktext {
                font-size: 16px;
                font-family: $primary-bold;
                line-height: 24px;
                margin-top: 24px;
            }
        }
    }

    &-toggle {
        color: $dark-blue;
        cursor: pointer;
        line-height: 14px;
        margin-top: 15px;
        text-align: center;

        &-show,
        &-hide {
            @include text-link-2;
            cursor: pointer;
            background: none;
            border-width: 0;

            &:focus {
                outline: 0;
            }

            &::after {
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                content: '';
                display: inline-block;
                height: 11px;
                margin-left: 5px;
                width: 12px;
            }
        }

        &-show {
            &::after {
                background-image: url('/Images/Base/dist/icon-plus.svg');
            }
        }

        &-hide {
            display: none;

            &::after {
                background-image: url('/Images/Base/dist/icon-minus.svg');
            }
        }
    }

    .universalfeatureddata2-link-section {
        padding-top: 70px;
        padding-bottom: 70px;
    }

    .universalfeatureddata2-link-section-grey {
        background: $lightest-grey;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &.showAll {
        .universalfeatureddata2-container-desktoplists {
            display: flex;
        }

        .universalfeatureddata2-toggle {
            &-show {
                display: none;
            }

            &-hide {
                display: inline;
            }
        }

        .universalfeatureddata2-link-section-grey {
            padding: 35px 20px;
        }

        .universalfeatureddata2-toggle {
            padding: 35px 0 0;
        }
    }

    .universalfeatureddata2-container-mobilelists {
        display: none;
        padding: 0px;

        .universalfeatureddata2-item-title {
            margin-top: 48px;

            &-first {
                margin-top: 0px;
            }
        }
    }
}

@import "featureddatavariant2-mobile";
