.universallandingheader {
    background-color: $dark-blue;
    margin-bottom: 48px;
    padding: 64px 0;

    &-title,
    &-subtitle {
        color: $white;
        font-family: $primary-semibold;
        margin-bottom: 0;
    }

    &-title {
        font-size: 56px;
        line-height: 70px;

        + .universallandingheader-subtitle {
            margin-top: 16px;
        }
    }

    &-subtitle {
        font-size: 32px;
        line-height: 42px;
    }
}

@import "_landingheader-mobile";
