@include max-screen(xl) {
    .productcardlist {
        .productcard {
            // Implement height/hover interaction ONLY if hovering is possible:
            @media (any-hover: hover) {
                .productcardcontent {
                    height: 300px;
                }
            }

            .productcarddetails {
                min-height: 160px;
            }
        }
    }
}

@include max-screen(md) {
    .productcardlist {
        .productcard {
            // Implement height/hover interaction ONLY if hovering is possible:
            @media (any-hover: hover) {
                .productcardcontent {
                    height: 265px;
                }
            }

            .productcarddetails {
                min-height: 140px;
            }
        }
    }
}

@include max-screen(sm) {
    .productcardlist {
        .productcard {
            .productcardcontent {
                padding: 20px 30px;
                border-bottom: none;
            }

            .productcarddetails {
                padding: 15px 30px;
                border-bottom: none;
            }
        }
    }
}
