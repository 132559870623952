@include max-screen(sm) {
    .subscriptionlistpanel {
        padding: 50px 0;

        .subscriptionlistpanel-header {
            &-title {
                font-family: $primary-light;
                font-size: 28px;
                line-height: 1.3;
                letter-spacing: 0.3px;
                margin-bottom: 30px;
                width: 100%;
            }
        }

        .subscriptionListPanelItem {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px 0 20px 0;

            .content {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .subscriptionlistpanel-footer {
            margin-top: 20px;
            p.small {
                width: 100%;
            }

            input[type="submit"] {
                width: 100%;
            }
        }
    }
}