@include max-screen(md) {
    .universaltexttable {
        margin-top: 64px;
        margin-bottom: 64px;

        &-item {
            padding-bottom: 0;

            &-col + &-col,
            & + & {
                margin-top: 24px;
            }
        }
    }
}