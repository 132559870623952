@include max-screen(sm) {
    .insightpanel {
        .grid-sizer {
            width: 100%
        }

        .grid-item {
            width: 100%;
            padding: 0;
        }

        .insightpanel-header {
            &-title {
                font-size: 32px;
                line-height: 42px;
            }

            &-subtitle {
                font-size: 18px;
                line-height: 26px;
                max-width: 100%;
            }
        }
    }
}