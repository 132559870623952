﻿.benefitsbar {
    &-container {
        background: $white;
        padding: 56px 0;
    }

    &-list {
        flex-wrap: nowrap;
        list-style: none;
        margin: 0;
        width: 100%;

        &,
        &-item {
            padding: 0;
        }

        &-item {
            flex-shrink: 1;
            margin-left: 42px;
            margin-right: 42px;

            &-icon {
                display: block;
                margin: 0 auto;

                &:not(:last-child) {
                    margin-bottom: 12px;
                }
            }

            &-title,
            &-subtitle {
                margin-bottom: 0;
                text-align: center;
            }

            &-title {
                color: $coal-grey;
                font-family: $primary-bold;
                font-size: 21px;
                line-height: 31px;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }

            &-subtitle {
                color: $dark-grey;
                font-family: $primary-regular;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &-error {
        color: $fireengine-red;
    }
}

@import'_benefitsbar-mobile';
