@include max-screen(md) {
    .universalfeatureddata {
        &-item-title,
        &-item,
        &-item-list {
            flex-basis: calc(25% - 20px);
        }
    }
}

@include max-screen(sm) {
    .universalfeatureddata {
        margin-top: 70px;
        margin-bottom: 70px;

        &-container-desktoptitles,
        &-container-desktoplists {
            display: none;
        }

        &-container-items {
            display: block;

            .universalfeatureddata-item-title {
                display: block;

                &:not(:empty) {
                    margin-top: 60px;
                    margin-bottom: 24px;
                }
            }
        }

        &-item {
            &-header {
                align-items: center;
                flex-direction: row;
                margin-bottom: 0;
            }

            &-image {
                display: inline-block;
            }

            &-link,
            &-linktext {
                font-size: 16px;
                line-height: 31px;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &-desc,
            &-list {
                margin-left: 35px;
            }

            &-desc {
                display: none;
            }

            &-list {
                &-item {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            & + & {
                margin-top: 35px;
            }
        }

        &.showAll {
            .universalfeatureddata-container-items {
                .universalfeatureddata-item-list {
                    display: block;
                }
            }

            .universalfeatureddata-container-desktoplists {
                display: none;
            }
        }
    }
}
