﻿@include max-screen(lg) {
    .registrationform {
    }
}

@include max-screen(md) {
    .registrationform {
    }
}

@include max-screen(sm) {
    .registrationform {
        padding: 0;
    }
}
