@include max-screen(lg) {
    .tabcontrol {
    
    }
}

@include max-screen(md) {
    .tabcontrol {
        .nav-tabs {
            border-bottom: 2px solid $lightest-grey;
            .nav-item {
                > a, a:visited {
                    top: 0;
                }
            }
            &.owl-carousel {
                .owl-stage {
                    display: flex;
                    align-items: flex-start;
                }
            }
        }
        .tab-content {
            > .tab-pane {
              min-height: 0;
              padding: 60px 0; 
            }
        } 
    }
}

@include max-screen(sm) {
    .tabcontrol {
    
    }
}