.universalcookiedisclaimer {
    background-color: $white;
    bottom: 0;
    width: 100%;
    position: fixed;
    z-index: 300;

    &::before {
        background: linear-gradient(147.61deg, #2D72B9 0%, #2CBCB1 100%);
        display: block;
        content: '';
        height: 4px;
        width: 100%;
    }

    &-container {
        padding-bottom: 36px;
        padding-top: 36px;
    }

    &-row {
        align-items: center;
    }

    &-text {
        margin-right: 24px;

        * {
            margin-bottom: 0;
        }

        span {
            display: block;
        }

        &,
        * {
            color: $darkest-grey;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.1px;
        }

        a {
            @include text-link-3;
            display: inline-block;
            line-height: initial;
        }

        strong {
            display: inline-block;
            color: $black;
            font-size: 16px;
            font-family: $primary-bold;
            letter-spacing: 2px;
            line-height: 30px;
            margin-bottom: 16px;
            text-transform: uppercase;
        }
    }

    &-btn {
        @include primary-button;
        margin-left: 24px;
    }
}

@import "_cookiedisclaimer-mobile";
