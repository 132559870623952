@include max-screen(xl) {
    .universalrichtext5x7 {
        &-textcol {
            &:not(:only-child) {
                &.col-sm-5 {
                    padding-right: 24px;
                }

                &.col-sm-7 {
                    padding-left: 24px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universalrichtext5x7 {
        &-textcol {
            &:not(:only-child) {
                &.col-sm-5 {
                    padding-right: 24px;
                }

                &.col-sm-7 {
                    padding-left: 24px;
                }
            }

            & + & {
                margin-top: 24px;
            }
        }
    }
}
