@include max-screen(xl) {
    .universaltechnicalsupportpanel {
        &-item {
            & + & {
                margin-left: 78px;
            }

            @for $i from 3 to 4 { // Only support 3 for now
                &.numberColumns-#{$i} {
                    flex-basis: calc((100% / #{$i}) - (78px * ((#{$i} - 1) / #{$i})));
                }
            }
        }
    }
}

@include max-screen(lg) {
    .universaltechnicalsupportpanel {
        &-item {
            &-title {
                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }
        }
    }
}

@include max-screen(md) {
    .universaltechnicalsupportpanel {
        &-item {
            &-title {
                &:not(:last-child) {
                    margin-bottom: 24px;
                }
            }
        }
    }
}

@include max-screen(sm) {
    .universaltechnicalsupportpanel {
        padding-top: 48px;

        &-container {
            padding-left: 25%;
            padding-right: 25%;
        }

        &-innerrow {
            flex-wrap: wrap;
            margin-left: auto;
            margin-right: auto;


            & + & {
                margin-top: 48px;
            }
        }

        &-item {
            & + & {
                margin-left: 0;
                margin-top: 48px;
            }

            @for $i from 3 to 4 { // Only support 3 for now
                &.numberColumns-#{$i} {
                    flex-basis: 100%;
                }
            }

            &-icon-container {
                height: auto;
                max-height: 60px;
            }

            .universallinklist {
                position: relative;
                transform: translateY(0);
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .universaltechnicalsupportpanel {
        &-container {
            padding-left: 20%;
            padding-right: 20%;
        }
    }
}

@media screen and (max-width: 460px) {
    .universaltechnicalsupportpanel {
        &-container {
            padding-left: 24px;
            padding-right: 24px;
        }
    }
}
