﻿.infographics-imagemap-contextpopup {
    background-color: $white;
    padding: 24px;
    position: absolute;
    width: 390px;
    z-index: 1;

    &-titlelink {
        @include add-caret;
    }

    &-title {
        margin: 0;
    }

    &-titlelink,
    &-title {
        font-family: $segoe-bold;
        font-size: 18px;
        line-height: 28px;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    &-description {
        color: $dark-grey;
        font-size: 14px;
        margin: 0;
    }

    &-link {
        @include text-link-2;
 
        &:not(:first-child) {
            margin-top: 24px;
        }

        &,
        &:visited {
            color: $dark-blue;
        }
    }
}
