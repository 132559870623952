.universalhostedby {
    margin-top: 42px;
    margin-bottom: 80px;

    &-title {
        color: $darkest-grey;
        font-family: $primary-regular;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;

        &:not(:only-child) {
            margin-bottom: 27px;
        }
    }

    &-hosts {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &-host {
        align-items: center;
        display: flex;
        flex-basis: 43%;

        &:nth-child(n + 3) {
            margin-top: 24px;
        }

        &-img {
            border-radius: 50%;
            height: 60px;
            margin-right: 16px;
            width: 60px;
        }

        &-name,
        &-title {
            color: $black;
            font-size: 14px;
            letter-spacing: 0.01px;
            line-height: 19px;
            margin: 0;
        }

        &-name {
            font-family: $primary-bold;
        }

        &-title {
            &:not(:only-child) {
                margin-top: 4px;
            }
        }
    }
}

@import "hostedby-mobile";
