.universalinsightsublayout {
    margin-top: 72px;
    margin-bottom: 72px;

    &-col {
        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }
}

@import '_insightsublayout-mobile';