@include max-screen(sm) {
    .universalinsightdatacalloutsmall {
        flex-wrap: wrap;
        padding-left: 12%;
        padding-right: 12%;

        &-text {
            &-small {
                width: 100%;

                &:not(:only-child) {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }
    }
}
