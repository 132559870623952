.brands-display {
    .brands-content {
        .container {
            padding: 0px;
        }
    }

    .utilitybrandbar-wrap {
    }

    .brand {
        text-align: left;
    }

    .utility {
        text-align: right;
    }

    .brand-item {
        .brand-itemHeading {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: $primary-semibold;
            font-size: 13px;
            background-color: $white;
            border: none;
            outline: none;
            transition: 200ms;
            opacity: 1;

            &.has-dropdown {
                position: relative;

                &:focus, &:hover {
                    outline: none;
                    cursor: pointer;
                }

                &:after {
                    @include add-caret-helper;
                    transform: rotate(90deg);
                    margin-left: 10px;
                }
            }

            &.utility {
                position: relative;
                z-index: 3;

                &:before {
                    content: url('/Images/Base/dist/icon-globe.svg');
                    display: inline-block;
                    margin-right: 5px;
                    position: relative;
                    top: 3px;
                }
            }
        }

        &:not(.close) {
            .brand-itemHeading {
                &.has-dropdown {
                    &:after {
                        transform: rotate(270deg);
                    }
                }
            }

            .brand-itemContent {
                .brands-content {
                    padding: 60px 0;

                    .title {
                        font-size: 18px;
                        line-height: 18px;
                        font-family: $primary-bold;
                        text-transform: uppercase;
                        letter-spacing: 1.69px;
                        display: block;
                        width: 100%;
                        padding: 10px 0;
                        border-bottom: 1px solid $light-grey;
                        position: relative;

                        &::after {
                            background: linear-gradient(90deg, $dark-green 0%, $light-blue 100%);
                            bottom: 0;
                            content: '';
                            display: block;
                            height: 4px;
                            position: absolute;
                            transform: translateY(50%);
                            width: 65px;
                        }
                    }

                    ul {
                        @include list-reset;
                        column-count: 2;
                        margin: 10px 0 60px 0;

                        li {
                            img {
                                padding: 15px 0;
                                width: 120px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.utility-brand-bar-logo-container {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 2;
    margin-top: -3px;
}

.utility-brand-bar-logo-wrap {
    display: inline-block;
    margin: 0 auto;
}

.utility-brand-bar-logo {
    height: 30px;
}

@import "brandsdisplay-mobile";
