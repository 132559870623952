.insightpanel {
    background-color: $lightest-grey;
    padding: 60px 0;

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 53px;
        margin-bottom: 8px;
    }

    &-subtitle {
        font-family: $primary-regular;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 0;
    }


    .insightpanel-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-left: 13px;
        padding-right: 13px;
        margin-bottom: 13px;

        .more {
            @include text-link-2;
            display: block;
            padding: 20px 0;
            height: 35px;
        }
    }

    .grid {
        max-width: 100%;
    }

    .grid-sizer {
        width: 33.333%
    }

    .grid-item {
        width: 33.333%;
        margin-bottom: 20px;
        padding: 0 1%;

        a, a:visited {
            display: block;

            &:hover, &:focus {
                text-decoration: none;
            }
        }
    }

    .insight-tile {
        box-shadow: 0 15px 32px 0 rgba(0,0,0,0.07);

        .tile-image {
            width: 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tile-content {
            padding: 40px 30px;

            .tags {
                @include list-reset;
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;

                li {
                    font-size: 12px;
                    line-height: 1.1;
                    color: darkest-grey;
                    text-transform: uppercase;
                    border: 1px solid $light-grey;
                    padding: 3px 5px;
                    margin: 0 15px 10px 0;
                }
            }

            .tile-title {
                @include add-caret;
                font-size: 22px;
                line-height: 1.4;
            }

            ul li, a, a:visited {
                color: $darkest-grey;
            }
        }

        &.white {
            background-color: $white;
        }

        &.black,
        &.blue {
            .tile-content {
                ul li, a, a:visited {
                    color: $white;
                }
            }
        }

        &.black {
            background-color: $black;
        }

        &.blue {
            background-color: $blue;
        }
    }
}

@import "insightpanel-mobile";
