@include max-screen(md) {
    .universalwebinarheader {
        padding-top: 88px;
        padding-bottom: 88px;

        &-title {
            font-size: 42px;
            line-height: 52px;
        }
    }
}

@include max-screen(sm) {
    .universalwebinarheader {
        padding-top: 64px;
        padding-bottom: 64px;
        margin-bottom: 32px;

        &-eyebrow {
            font-size: 14px;
            line-height: 28px;
        }

        &-title {
            font-size: 38px;
            line-height: 48px;
        }
    }
}