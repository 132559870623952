.universalcontentpanel {
    margin-top: 104px;
    margin-bottom: 104px;

    &-grey {
        background: $lightest-grey;
        padding-top: 70px;
        padding-bottom: 70px;
    }

    &-title,
    &-subtitle {
        color: $darkest-grey;
    }

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 53px;
        margin-bottom: 8px;
    }

    &-subtitle {
        font-family: $primary-regular;
        font-size: 24px;
        line-height: 40px;
        margin-bottom: 0;
        max-width: 60%;
    }

    &-placeholder {
        margin-top: 48px;

        > *:first-child {
            margin-top: 0;
        }

        > *:last-child {
            margin-bottom: 0;
        }

        &:empty {
            display: none;
        }

        .universalflarelist {
            &-item {
                &::before {
                    background: linear-gradient(47.74deg, #00549E 0%, #00B7A8 100%);
                    height: 4px;
                }
            }
        }
    }
}

@import "contentpanel-mobile";
