@include max-screen(md) {
    .universalinsightbyline {
        margin-bottom: 32px;

        &-tags {
            margin-top: 16px;
        }

        &-options {
            flex-wrap: wrap;
            justify-content: flex-start;
            margin-top: 8px;
        }

        &-options-title,
        &-social,
        &-download {
            margin-top: 16px;
        }
    }

    .universalinsightbottomshare {
        margin-bottom: unset;
    }
}
