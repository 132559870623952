@media (max-width: 1076px) {
    .universalrichtext {
        &-media-left {
            &-overflow {
                &,
                &-caption {
                    margin-left: 0;
                }
            }
        }

        &-media-right {
            &-overflow {
                &,
                &-caption {
                    margin-right: 0;
                }
            }
        }
    }
}

@include max-screen(md) {
    .universalrichtext {
        margin-top: 46px;
        margin-bottom: 46px;

        &-eyebrow,
        &-title {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &-text {
            * + * {
                margin-top: 24px;
            }

            ol, ul {
                margin-top: 16px;
            }

            ul {
                padding-left: 24px;
            }
        }
    }
}

@include max-screen(sm) {
    .universalrichtext {
        &-eyebrow {
            font-size: 14px;
            line-height: 22px;
        }

        &-text {
            * + * {
                margin-top: 16px;
            }

            ul {
                padding-left: 0;

                li {
                    padding-left: 16px;

                    &::before {
                        right: 16px;
                    }
                }
            }
        }

        &-media-left,
        &-media-right {
            &,
            &-overflow {
                margin: 0;

                &,
                &-caption {
                    clear: none;
                    float: none;
                    max-width: 100%;
                    min-width: 100%;
                    width: 100%;
                }

                &-caption {
                    display: block;
                    font-size: 13px;
                    line-height: 22px;
                    margin: -8px 0 0;
                }
            }
        }
    }
}
