@include max-screen(lg) {
    .universalheadersmall {
        &-title {
            font-size: 50px;
        }

        &-subtitle {
            font-size: 24px;
            line-height: 36px;
        }
    }
}

@include max-screen(md) {
    .universalheadersmall {
        padding: 72px 0;

        &-title {
            font-size: 40px;
            line-height: 48px;
        }

        &-subtitle {
            font-size: 18px;
            line-height: 34px;
        }
    }
}

@include max-screen(sm) {
    .universalheadersmall {
        margin-bottom: 24px;

        &-title {
            font-size: 32px;
            line-height: 40px;
        }

        &-subtitle {
            font-size: 16px;
            line-height: 22px;
        }
    }
}
