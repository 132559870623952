.productgallery {
    margin: 50px 0;

    .imagecard {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 50px;

        a, a:visited {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            color: $darkest-grey;

            img {
                max-width: 150px;
                width: 100%;
                height: auto;
                margin: auto 0;
            }

            h2 {
                font-size: 18px;
                line-height: 1.3;
                font-family: $primary-semibold;
                text-align: center;
                margin-top: auto;
                padding-top: 10px;
                color: $darkest-grey;
            }
        }
    }

    .nextlevelcard {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 260px;
        padding: 0;
        position: relative;

        div {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            padding: 35px;

            h2 {
                color: $white;
                font-size: 18px;
                line-height: 1.3;
                margin-bottom: 30px;
            }

            a, a:visited {
                margin-top: auto;

                &:hover, &:focus {
                    text-decoration: none;
                }

                .cta {
                    @include add-caret($white);
                    font-size: 18px;
                    color: $white;
                    font-family: $primary-semibold;
                }
            }
        }
    }
}

@import "productgallery-mobile";
