@include max-screen(md) {
    .universalvideoanchorbutton {
        margin-top: 46px;
        margin-bottom: 46px;
    }
}

@include max-screen(sm) {
    .universalvideoanchorbutton {
        padding: 22px 24px;

        &-anchor {
            align-items: stretch;
            flex-direction: column;
            text-align: center;

            &::before {
                align-self: center;
                margin-bottom: 8px;
                margin-right: 0;
            }
        }
    }
}
