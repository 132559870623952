.universalflarelist {
    @extend %itemlist;

    &-item {
        border-top: 2px solid $lighter-grey;
        padding-top: 15px;
        position: relative;

        &-link,
        &-linktext {
            font-size: 22px;
            font-family: $primary-semibold;
            line-height: 28px;
            margin-bottom: 10px;
        }

        &-link,
        &-link:visited,
        &-linktext,
        &-desc {
            color: $darkest-grey;
        }

        &-link {
            @include text-link-1;
        }

        &-desc {
            font-size: 14px;
            line-height: 21px;
        }

        &::before {
            background: $light-blue;
            content: '';
            display: block;
            height: 6px;
            top: 0;
            transform: translateY(-50%) translateY(-1px); // IE11 fallback
            transform: translateY(calc(-50% - 1px));
            position: absolute;
            width: calc(100% / 3);
        }
    }

    &-eyebrow {
        color: $darkest-grey;
        margin-bottom: 20px;
        font-size: 16px;
        font-family: $primary-bold;
        letter-spacing: 2px;
        line-height: 30px;
        text-transform: uppercase;
    }
}

@import "flarelist-mobile";