@include max-screen(sm) {
    .productgallery {
        .imagecard {
            padding: 40px;
        }

        .nextlevelcard {
            div {
                padding: 35px 20px;
            }
        }
    }
}
