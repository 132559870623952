$numItemsPerRow: 4;
$itemsPerRowTablet: 2;

@include max-screen(md) {
    .universalfeatureddata2 {
        &-item-title-tiles {
            flex-basis: calc(25% - 24px);
        }


        &-container-desktoplists {
            .universalfeatureddata2-item {
                &-title,
                &-list {
                    width: 50%;
                }
            }

            @for $i from 1 through $numItemsPerRow {
                @if $i <= $itemsPerRowTablet {
                    .universalfeatureddata2-item {
                        &-title:nth-of-type(#{$i}) {
                            order: #{$i};
                        }

                        &-list:nth-of-type(#{$i}) {
                            order: calc(#{$i} + #{$itemsPerRowTablet});
                        }
                    }
                }
                @else {
                    .universalfeatureddata2-item {
                        &-title:nth-of-type(#{$i}) {
                            margin-top: 48px;
                            order: calc(#{$i} + #{$itemsPerRowTablet});
                        }
                    }
                }
            }
        }
    }

    &-item {
        flex-basis: calc(25% - 20px);
    }
}

@include max-screen(sm) {
    .universalfeatureddata2 {
        margin-top: 70px;
        margin-bottom: 70px;

        &-container-desktoptitles,
        &-container-desktoplists {
            display: none;
        }

        &-container-items {
            display: block;

            .universalfeatureddata2-item-title {
                display: block;

                &:not(:empty) {
                    margin-top: 60px;
                    margin-bottom: 24px;
                }
            }
        }

        &-item {
            &-header {
                align-items: center;
                flex-direction: row;
                margin-bottom: 0;
            }

            &-image {
                display: inline-block;
            }

            &-link,
            &-linktext {
                font-size: 16px;
                line-height: 31px;
                margin-bottom: 0;
                padding-bottom: 0;
            }

            &-desc {
                display: none;
            }

            &-list {
                &-item {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            & + & {
                margin-top: 35px;
            }
        }

        &.showAll {
            .universalfeatureddata2-container-desktoplists {
                display: none;
            }

            .universalfeatureddata2-container-items {
                .universalfeatureddata2-item-list {
                    display: block;
                }
            }

            .universalfeatureddata2-container-mobilelists {
                display: flex;
            }

            .universalfeatureddata2-link-section-grey {
                padding: 36px;
            }

            .universalfeatureddata2-link-section {
                padding-top: 36px;
                padding-bottom: 36px;
            }
        }
    }
}
