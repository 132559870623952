@include max-screen(md) {
    .universalcontentpanel {
        margin-top: 64px;
        margin-bottom: 64px;

        &-grey {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &-title {
            font-size: 32px;
            line-height: 42px;
        }

        &-subtitle {
            font-size: 18px;
            line-height: 26px;
            max-width: 100%;
        }
    }
}

@include max-screen(sm) {
    .universalflarelist {
        &-placeholder {
            .universalflarelist-item::before {
                height: 3px;
            }
        }
    }
}
