.universaljumboheader {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 153px;
    padding-bottom: 80px;
    overflow: hidden;

    &::before {
        background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        bottom: 0;
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
    }

    &,
    &-container {
        position: relative;
    }

    &-video {
        bottom: 0;
        left: 0;
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    &-title,
    &-subtitle {
        color: $white;
        margin-bottom: 0;
    }

    &-title {
        font-family: $primary-light;
        font-size: 48px;
        line-height: 56px;
        max-width: 50%;

        &:not(:only-child) {
            margin-bottom: 22px;
        }
    }

    &-subtitle {
        font-family: $primary-regular;
        font-size: 20px;
        line-height: 28px;
        max-width: calc((100% / 12) * 5);
    }

    &-cta {
        @include disclaimer-button;

        &:not(:only-child) {
            margin-top: 37px;
        }
    }
}

@import "jumboheader-mobile";
