.universalinsightdatacallout2col {
    display: flex;
    margin-top: 56px;
    margin-bottom: 56px;

    &.showTopBorder {
        border-top: 1px solid $lighter-grey;

        .universalinsightdatacalloutsmall {
            padding-top: 64px;
        }
    }

    &.showBottomBorder {
        border-bottom: 1px solid $lighter-grey;

        .universalinsightdatacalloutsmall {
            padding-bottom: 64px;
        }
    }

    &-col {
        &:first-child {
            border-right: 1px solid $lighter-grey;
        }
    }

    .universalinsightdatacalloutsmall {
        border: 0;
        flex-wrap: wrap;
        margin-top: 0;
        margin-bottom: 0;
        padding: 24px 17%;

        &-text {
            &-callout {
                &:not(:only-child) {
                    margin-right: 12px;
                }
            }

            &-small {
                width: 100%;

                &:not(:only-child) {
                    margin-left: 0;
                    margin-top: 24px;
                }
            }
        }
    } 
}

@import "insightdatacallout2column-mobile";
