﻿.CoveoLoadMoreResults {
    display: flex!important;
    justify-content: center;
    width: 100%;
    background-color: $lightest-grey;
    input {
        @include primary-button;
        padding: 12px 60px;
        width: auto;
        margin: 20px auto 80px auto;
    }
}
