.homeimagegrid {
    background: linear-gradient(0deg, rgba(251,251,251,0) 0%, #F5F5F5 100%);
    margin: 100px 0;
    padding: 100px 0;

    &-title {
        font-family: $primary-semibold;
        font-size: 40px;
        line-height: 1.1;
        margin-bottom: 20px;
    }

    p {
        font-family: $primary-regular;
        font-size: 20px;
        line-height: 1.4;
        color: $dark-grey;
        margin-bottom: 40px;
    }

    .row {
        margin-bottom: 30px;
    }

    .homeimagegridcard {
        position: relative;
        height: 400px;
        overflow: hidden;
        display: block;

        .image {

            img {
                width: 100%;
                height: 250px;
            }
        }

        .text {
            position: absolute;
            bottom: 0;

            .text-wrap {
                background-color: $white;
                padding: 30px;

                &-title {
                    font-family: $primary-semibold;
                    font-size: 22px;
                    line-height: 1.2;
                    margin-bottom: 10px;
                }

                p {
                    font-family: $primary-semibold;
                    font-size: 14px;
                    line-height: 1.5;
                    margin: 0;
                    padding: 0 0 20px 0;
                }
            }

            &.light {
                .text-wrap .text-wrap-title, .text-wrap p {
                    color: $white;
                }
            }

            &.dark {
                .text-wrap .text-wrap-title, .text-wrap p {
                    color: $black;
                }
            }
        }

        &.overlay {
            .image {
                min-height: 400px;
                position: relative;

                img {
                    position: absolute;
                    width: auto;
                    min-width: 100%;
                    height: 100%;
                }
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                .text-wrap {
                    background-color: transparent;
                    padding: 30px;

                    &-title {
                        font-family: $primary-semibold;
                        font-size: 22px;
                        line-height: 1.2;
                        margin-bottom: 10px;
                    }

                    p {
                        font-family: $primary-semibold;
                        font-size: 14px;
                        line-height: 1.5;
                        margin: 0;
                        padding: 0 0 20px 0;
                    }
                }

                &.light {
                    .text-wrap .text-wrap-title, .text-wrap p {
                        color: $white;
                    }
                }

                &.dark {
                    .text-wrap .text-wrap-title, .text-wrap p {
                        color: $black;
                    }
                }
            }
        }
    }
}

@import "homeimagegrid-mobile";
