@include max-screen(lg) {
    .universalfeaturedproduct {
        &-img-container {
            height: 133px;
            width: 200px;
        }
    }
}

@include max-screen(md) {
    .universalfeaturedproduct {
        margin-top: 24px;

        &-img-container {
            height: 192px;
            width: 288px;
        }
    }
}

@include max-screen(sm) {
    .universalfeaturedproduct {
        &-img-container {
            height: 133px;
            width: 200px;
        }
    }
}

@media screen and (max-width: 640px) {
    .universalfeaturedproduct {
        display: block;

        &-img-container {
            height: 0;
            padding-bottom: 66%;
            margin-bottom: 8px;
            width: 100%;
        }

        &-img {
            width: 100%;
        }

        &-cta {
            display: block;
            text-align: center;
        }
    }
}
