@include max-screen(md) {
    .universalvideodisplay {
        margin-top: 48px;
        margin-bottom: 48px;

        .universalmedia-caption {
            margin-top: 8px;
        }
    }
}
