﻿.infographics-imagemap-actionindicator {
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;

    &:focus {
        outline: none;
    }

    &,
    &::after,
    &::before {
        position: absolute;
    }

    &::after,
    &::before {
        border-radius: 50%;
        content: '';
        transition: .2s ease-out;
    }

    &::before {
        height: 41px;
        opacity: 0;
        width: 41px;
    }

    &::after {
        background-color: #00BBE3;
        box-shadow: 2px 2px 4px 0 rgba(0,187,227,0.25);
        height: 30px;
        opacity: .5;
        width: 30px;
    }

    &:hover::after {
        opacity: .75;
        transform: scale(1.1);
    }

    &.actionindicator-active {
        pointer-events: none;

        &::before,
        &::after {
            opacity: 1;
        }

        &::before {
            background-color: rgba(0,83,158,0.6);
            box-shadow: 2px 2px 4px 0 rgba(0,187,227,0.25);
        }

        &::after {
            background-color: rgba(0,83,158,0.78);
            box-shadow: 2px 2px 4px 0 rgba(0,187,227,0.25);
        }
    }
}

// Custom indicator:

.infographics-imagemap-actionindicator[style*="background-image"] {
    background-repeat: no-repeat;
    background-size: contain;
    height: 30px;
    transition: .2s transform ease-out;
    width: 30px;

    &::after,
    &::before {
        content: none;
    }

    &:hover,
    &.actionindicator-active {
        transform: scale(1.1);
    }
}

//On mobile
@media (max-width: 1024px) {
    .infographics-imagemap-actionindicator[style*="background-image"] {
        background-repeat: no-repeat;
        background-size: contain;
        height: 15px;
        transition: .2s transform ease-out;
        width: 15px;
    }
}
