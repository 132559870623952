// GLOBAL MOBILE MARGINS OVERRIDE =============================================== *
.container {
    padding: 0 24px;
}

// PAGE DIVIDER =============================================== *
.divider {
    margin: 0 0 50px 0;

    border: {
        top: 1px solid $lighter-grey;
        bottom: none;
    }
}

// ITEM LIST SHARED STYLES (e.g. Icon List and Flare List) ======================= *
%itemlist {
    width: 100%;

    > div { // rows
        display: flex;
        flex-wrap: nowrap;

        + div {
            margin-top: 50px;
        }

        > div { // items
            @for $i from 1 to 6 { // Change 6 to a higher number if # of possible columns is higher
                &.numberColumns-#{$i} {
                    width: calc((100% / #{$i}) - (15% / #{$i}));
                    margin-right: calc(15% / (#{$i} - 1));

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@include max-screen(sm) {
    %itemlist {
        > div { // rows
            display: block;

            + div {
                margin-top: 40px;
            }

            > div { // items
                @for $i from 1 to 6 { // Change 6 to a higher number if # of possible columns is higher
                    &.numberColumns-#{$i} {
                        width: 100%;
                        margin-right: 0;
                    }
                }

                &:not(:last-of-type) {
                    margin-bottom: 40px;
                }
            }
        }
    }
}